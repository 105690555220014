import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import ModalBottomButtonsBlock from '../../../Components/ModalBottomButtonsBlock';
import { Context } from '../../../functions/context';
import ColorPicker from '../../../Components/ColorPicker';
import axi from '../../../functions/axiosf';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import validateTextInput from '../../../functions/validateTextInput';

import propsF from '../../../functions/propsF';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 320,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
};

export default function NomenclatureUpdateModal(props) {
  const context = React.useContext(Context);
  const [newName, setNewName] = React.useState('');
  const [nameError, setNameError] = React.useState('');
  const [newType, setNewType] = React.useState('');
  const [prodId, setProdId] = React.useState(null);
  const [isEdited, setIsEdited] = React.useState(false);
  const [isNameDisabled, setIsNameDisabled] = React.useState(true);

  React.useEffect(() => {
    if (typeof props.indexOf === 'number') {
      // console.log('indexOf', props.indexOf);
      // console.log('sortedNomenclature', props.sortedNomenclature);
      let current = props.sortedNomenclature[props.indexOf];
      if (current) {
        setNewName(current.name);
        setNewType(current.type);
        setProdId(current.id);
        setIsNameDisabled(+current.isManual === 1 ? false : true);
      }
    }
  }, [props.indexOf]);

  const handleChangeNewName = (event) => {
    let newName = event?.target?.value;
    let [textError, isSetState] = validateTextInput(
      newName,
      'наименование товара'
    );
    isSetState && setNewName(newName);
    setNameError(textError);
  };
  const handleChangeNewType = (event) => {
    setNewType(event?.target?.value);
  };

  const onSetIsWaiting = (boolean) => {
    if (typeof props.onSetIsWaiting === 'function') {
      props.onSetIsWaiting(boolean);
    }
  };

  const handleClose = () => {
    if (typeof props.onOpenModal === 'function') {
      props.onOpenModal(false);
    }
  };

  const onPressAccept = async () => {
    onSetIsWaiting(true);
    try {
      let result = await axi('admin/updateProduct.php', '', {
        token: context.token,
        newProductName: newName,
        newProductType: newType,
        productId: prodId,
      });
      if (typeof context.setAllBase === 'function') {
        context.setAllBase(result);
        propsF(props.setIsCategoriesSorted,false);
      }
    } catch (e) {
      console.log(e);
    }
    onSetIsWaiting(false);
    handleClose();
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={props.isOpenModal}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade 
          in={open}
          // in={open}
        >
          <Box sx={style}>
            <TextField
              label="Название"
              id="standard-size-small"
              value={newName}
              variant="outlined"
              sx={{ width: '200px', marginBottom: '16px' }}
              onChange={handleChangeNewName}
              error={nameError!==''}
              helperText={nameError}
              disabled={isNameDisabled}
            />

            <FormControl sx={{ width: '200px', marginBottom: '16px' }}>
              <InputLabel id="demo-simple-select-label">Категория</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={newType}
                label="Категория"
                onChange={handleChangeNewType}
              >
                {context.types.map((item, index) => (
                  <MenuItem
                    key={item.id}
                    value={item.id}
                    style={{
                      backgroundColor: item.color,
                    }}
                  >
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <ModalBottomButtonsBlock
              isWaiting={props.isWaiting}
              handleAccept={onPressAccept}
              textClose="Отклонить изменения"
              handleClose={handleClose}
              disabled={nameError!==''}
            />
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
