import React, { Fragment, useContext, useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Checkbox, CircularProgress, FormControlLabel } from '@mui/material';

import Paper from '@mui/material/Paper';
import CSVReader from 'react-csv-reader';

import axi from './../../functions/axiosf';

import style from './index.module.css';
import {stylesObj} from './../../stylesObj/stylesObj';

import TypesGroupsSendingProduct from './TypesGroupsSendingProduct';
import { Context } from '../../functions/context';
import SendingProductModal from './SendingProductModal';
import csv1cImportConfig from '../../resources/csv1cImportConfig';
import copyObj from '../../functions/copyObj';
import { clear } from '@testing-library/user-event/dist/clear';
import { Nomenclature } from '../Nomenclature';


import TextField from '@mui/material/TextField';
import moment from 'moment';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ruLocale from 'date-fns/locale/ru';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dateInMsToSeconds from '../../functions/dateInMsToSeconds';
import SettingsForStatistics from './SettingsForStatistics';
import kitsTypes from '../../resources/kitsTypes';

const SendingProduct = () => {
  const context = useContext(Context);

  const filterKitsForCurrentPoint = () =>{
    let newFilterKits = [];
    if (context.kits){
      if (context.invoicePoint){
        newFilterKits = context.kits.filter(kit=> +kit.point === +context.invoicePoint || +kit.point === 0);
      }else{
        newFilterKits = [...context.kits];
      }
    }
    return(newFilterKits);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isWaiting, setIsWaiting] = useState(false);
  const [isDownloadWaiting, setIsDownloadWaiting] = useState(false);

  // const [invoice, setInvoice] = useState([]);

  const [cleanInvoice, setCleanInvoice] = useState([]);
  const [openedTabIndex, setOpenedTabIndex] = useState(null);
  // const [point, setPoint] = useState(null);
  
  const [totalCount, setTotalCount] = useState(0);
  const [countByCategory, setCountByCategory] = useState({});
  

  const [date, setDate] = useState(+moment().startOf('day')); // тут по умолчанию поставить не текущее время а начало дня
  const [dateError, setDateError] = useState('');

  const [mode, setMode] = useState('manual');

  const [filterKits, setFilterKits] = useState(filterKitsForCurrentPoint);
  const [set, setSet] = useState(null);
  
  const [isCurrentKitManual, setIsCurrentKitManual] = useState(false);
  const [isStatisticsSuccess, setIsStatisticsSuccess] = useState(false);

  const [isHideEmpty, setHideEmpty] = useState(false);
  
  const handleChangeHideEmpty = (event) => {
    setHideEmpty(event.target.checked);
  };

  const modes = {
    manual: 'ручной',
    tomorrow: 'по вчерашним продажам',
    calcDay: 'по продажам за конкретный день',
    csv: 'импорт из 1с',
    set: 'набор',
  };

  const isDateError=()=> dateError !== '';

  const handleChangePoint = (event) => {
    let newPointId = event?.target?.value;
    context.setInvoicePoint(newPointId);
    setDate(+moment().startOf('day'));
    setDateError('');
    setMode('manual');
    setHideEmpty(false);
    setSet(null);
    setIsCurrentKitManual(false);
    setIsStatisticsSuccess(false);


    let newFilterKits = [];
    if (newPointId){
      newFilterKits = context.kits.filter( kit => +kit.point === +newPointId || +kit.point === 0 );
    }else{
      newFilterKits = [...context.kits];
    }
    setFilterKits(newFilterKits);
  };

  const handleChangeMode = async (event) => {
    setMode(event.target.value);
    setSet(null);
    if (event.target.value === 'manual'){ 
      setHideEmpty(false);
    }
    //запрос генерации заказа по остаткам вчерашнего дня
    axiPrediction(event.target.value);

  };

  const axiPrediction = async (eventMode) => {
    if (eventMode === 'tomorrow' || eventMode === 'calcDay'){ 
      setHideEmpty(true);
      let responseData = { 
        token: context.token, 
        pointOfSale: context.invoicePoint
      };

      if (eventMode === 'calcDay'){
        responseData = {...responseData, calcDay: dateInMsToSeconds(date)};
      }

      let result = await axi(
        'admin/prediction/getYesterdaysPrediction.php', 
        '', 
        responseData,
      );
      if (result.type === 'no_authorized') {
        if(typeof context.userExit === 'function'){
          context.userExit();
        }
      } else {
        if (result.status === 'ok'){
          let newCleanInvoice = [];
          result.cleanInvoice.map((kitItem)=>{
            let itemRow = context.nomenclature.filter(
              (nomenclatureItem)=> 
                +nomenclatureItem.id === +kitItem.product
            );
            let rowCleanInvoice = copyObj(itemRow[0]);
            if (kitItem.quantity>0){
              rowCleanInvoice.quantity = kitItem.quantity;
            }else{
              rowCleanInvoice.quantity = 0;
            }
            rowCleanInvoice.yesterdayQuantity = kitItem.yesterdayQuantity;
            rowCleanInvoice.coefficient = kitItem.coefficient;
            newCleanInvoice = [...newCleanInvoice, rowCleanInvoice];
          });
          context.setInvoice(newCleanInvoice);
          setCleanInvoice(newCleanInvoice);
          setIsModalOpen(false);
        }
      }
      setIsDownloadWaiting(false);
    }
  };

  const handleChangeSet = (event) => {
    let newKit = event.target.value;
    setSet(newKit);
    setHideEmpty(true);

    let isManual = filterKits.some((kit) => kit.id === newKit && kit.type === 'custom');
    setIsCurrentKitManual(isManual);
    isManual && onReadCurrentKit(newKit);
  };

  const onReadCurrentKit = async (currentKit) => {
    setHideEmpty(true);
    setIsDownloadWaiting(true);
    let result = await axi(
      'admin/readCustomKitInvoice.php', 
      '', 
      { 
        token: context.token, 
        kit: currentKit,
      });
    if (result.type === 'no_authorized') {
      if(typeof context.userExit === 'function'){
        context.userExit();
      }
    } else {
      if (result.status === 'ok'){
        let newCleanInvoice = [];
        result.cleanInvoice
          .map((kitItem)=>{
            let itemRow = context.nomenclature.filter(
              (nomenclatureItem)=> 
                +nomenclatureItem.id === +kitItem.product
            );
            let rowCleanInvoice = copyObj(itemRow[0]);
            if (kitItem.quantity>0){
              rowCleanInvoice.quantity = kitItem.quantity;
            }else{
              rowCleanInvoice.quantity = 0;
            }
            rowCleanInvoice.yesterdayQuantity = 0;
            rowCleanInvoice.coefficient = 0;
            newCleanInvoice = [...newCleanInvoice, rowCleanInvoice];
          });
        context.setInvoice(newCleanInvoice);
        setCleanInvoice(newCleanInvoice);
        setIsModalOpen(false);
      }
    }
    setIsDownloadWaiting(false);
  };

  useEffect(()=>{
    context.axiGetAllBase();

  },[]);

  useEffect(()=>{
    calcTotal();
  },[context.invoice]);

  const onChangeQuantity = (id, value) => {
    // console.log(`${id} ${value}`);
    value=+value;
    if (value >= 0){
      let newInvoice = [...context.invoice];
      // let isNew = true;

      let item=newInvoice.find((invoiceItem) => invoiceItem.id===id);
      if (item!==undefined){
        item.quantity = value;
        item.yesterdayQuantity = 0;
        item.coefficient = 0;
        //может стоило бы как то очевидно сохранять обновлённую строку в массив
        //if ( mode === 'manual'){
        //}
      } else {
        let nomenclatureItem=context.nomenclature.find((nomenclatureItem) => nomenclatureItem.id === id);
        if (nomenclatureItem!==undefined) {
          let newItem = copyObj(nomenclatureItem);
          newItem.quantity = value;
          newItem.yesterdayQuantity = 0;
          newItem.coefficient = 0;
          //if ( mode === 'manual'){
          //}
          newInvoice = [...newInvoice, newItem];
        }
      }
      context.setInvoice(newInvoice);
      // console.log(newInvoice);
      
    }
  };

  // calc total
  const calcTotal =()=>{
   
    try {
      let total=0;
      let newCountByCategory={};
      context.invoice.map((invoiceItem) => {
        let quantity = +(invoiceItem.quantity);
        let category = context.nomenclature.find(
          (product) => product.id === invoiceItem.id
        )?.type;
        if (category===undefined) {
          category =0;
        }
        // console.log(`${category}:${quantity}`);
        if (!newCountByCategory.hasOwnProperty(category)){
          newCountByCategory[category]=quantity;
        } else {
          newCountByCategory[category]+=quantity;
        }
        total+=quantity;
      });
      setTotalCount(total);
      setCountByCategory(newCountByCategory);
      // console.dir(newCountByCategory);
    } catch (error) {
      console.error(error);
      // console.log('error calculating total count in onChangeQuantity');
    }
  };

  const onChangeComment = (commentId, commentValue) => {
    let newInvoice = copyObj(context.invoice);
    newInvoice.map((item, index)=>{
      if (+item.id === +commentId){  
        // console.log(commentValue);
        newInvoice[index].comment = commentValue;
      }
    });
    context.setInvoice(newInvoice);
    // console.dir(newInvoice);
  };

  const handlePressSend = async () => {
    let postInvoice = [];
    context.invoice.map((item, index)=>{
      if(item.quantity>0){
        postInvoice=[...postInvoice, item];
      }
    });
    setCleanInvoice(postInvoice);
    setIsModalOpen(true);
  };

  const onPressConfirmSend = async (phpUnixTime) => {
    setIsWaiting(true);
    let result = await axi(
      'admin/setShipmentInvoice.php', 
      '', 
      { 
        token: context.token, 
        point: context.invoicePoint, 
        invoice: cleanInvoice, 
        phpUnixTime: phpUnixTime 
      });
    if (result.type === 'no_authorized') {
      if(typeof context.userExit === 'function'){
        context.userExit();
      }
    } else {
      if (result.status === 'ok'){
        let emptyArray = [];
        context.setInvoice(emptyArray);
        setCleanInvoice(emptyArray);
        setIsModalOpen(false);
        context.setInvoicePoint(null);
      }
    }
    setIsWaiting(false);
  };

  const handleReadCSV = (csvArr) => {
    setHideEmpty(true);
    let newInvoice = [];
    for (let iRow = 1; iRow < csvArr.length-1; iRow++) {
      let row = {};
      row.name = csvArr[iRow][0];
      context.nomenclature.map((item) => {
        if (item.name === row.name){
          row.createTime = item.createTime;
          row.id = item.id;
          row.type = item.type;
        }
      });
      row.quantity = +csvArr[iRow][3];
      newInvoice = [...newInvoice, row];
    }
    context.setInvoice(newInvoice);
  };

  const handleChangeDate = (value) => {
    setDateError('');
    let newDate = +value;
    setDate(newDate);
  };

  useEffect(()=>{
    axiPrediction(mode);
  }, [date]);

  return(
    <div className={style.verticalPage}>
      {isModalOpen &&
        <SendingProductModal
          isOpen = {isModalOpen}
          onSetIsModalOpen={setIsModalOpen}
          cleanInvoice={cleanInvoice}
          onPressConfirmSend={onPressConfirmSend}
          isWaiting={isWaiting}
          point={context.invoicePoint}
        />
      }
      <div className='rowWrapWrapper'>
        <FormControl className={style.headerSelect}>
          <InputLabel id="demo-simple-select-label">
            Адрес назначения
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={context.invoicePoint || ''}
            label="Адрес назначения"
            onChange={handleChangePoint}
          >
            {context.salePoints.map((item, index)=>
              <MenuItem 
                key={index} 
                value={item.id}>
                {item.name}
              </MenuItem>)}
          </Select>
        </FormControl>
                
        <FormControl className={style.headerSelect}>
          <InputLabel id="demo-simple-select-label">
            Режим заполнения
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={mode}
            label="Режим заполнения"
            onChange={handleChangeMode}
          >
            {Object.keys(modes).map((item, index)=>
              <MenuItem 
                key={index} 
                value={item}>
                {modes[item]}
              </MenuItem>)}
          </Select>
        </FormControl>
      
        {mode === 'calcDay' ?
          <LocalizationProvider
            dateAdapter={AdapterDateFns}
            adapterLocale={ruLocale}
          >
            <DatePicker
              label='Дата'
              value={date}
              onChange={(newValue) => {
                handleChangeDate(newValue);
              } }
              renderInput={(params) => (
                <TextField
                  style={{width: 200 }}
                  {...params}
                  error={isDateError()}
                  helperText={dateError}
                  onFocus={(event) => {
                    event.target.select();
                  } }
                  autoFocus 
                />
              )} 
            />
          </LocalizationProvider>
          : null
        }
        {mode === 'set' ?
          <FormControl className={style.headerSelect}>
            <InputLabel id="demo-simple-select-label">Набор</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={set}
              label="Набор"
              onChange={handleChangeSet}
            >
              {filterKits.map((item, index)=>
                <MenuItem
                  key={index}
                  value={item.id}
                  divider>
                  <div
                    style={{
                      width: 228,
                      display: 'flex',
                      flexDirection: 'column',
                      alignSelf: 'end',
                    }}
                  >
                    <span>{item.name}</span>
                    <span 
                      style={{
                        color: '#aaa',
                        alignSelf: 'end',
                      }}
                    >{kitsTypes[item.type]}</span>
                  </div>
                </MenuItem>
              )}
            </Select>
          </FormControl>
          : null
        }
        {mode === 'csv' ?

          <CSVReader
            cssClass = "csv-reader-input"
            label = "Выберете CSV файл, экспортированый из 1с"
            onFileLoaded = {handleReadCSV}
            //onError={errorReadCSV}
            fileEncoding = "cp1251"
            inputId = "upload1c"
            inputStyle = {{color: 'red', margin: 16}}
          />

          : null
        }
      </div>
      {(context.invoicePoint && mode === 'set' && !isCurrentKitManual) ?
        <SettingsForStatistics
          isWaiting = {isWaiting}
          onSetIsWaiting = {setIsWaiting}
          set = {set}
          onSetIsStatisticsSuccess = {setIsStatisticsSuccess}
          //накладную он напишет сразу в контекст
        />
        : null
      }

      {(context.invoicePoint && (mode !== 'set' || isStatisticsSuccess || isCurrentKitManual)) ?
        <div className={style.MenuTwoTable} style={{margin: 0, ...stylesObj.TableContainer}}>
          <FormControlLabel 
            control={
              <Checkbox
                checked = {isHideEmpty}
                onChange = {handleChangeHideEmpty}
              />
            } 
            label = "Скрывать нулевые позиции"
            sx = {{margin: 3}}
          />
          <TableContainer className={style.Paper} component={Paper}>
            <Table aria-label="collapsible table" className={style.Paperbody} sx={stylesObj.TableContainer}>
              <TableHead>
                <TableRow className={style.Paperhead} sx={stylesObj.TableContainer}>
                  
                  <TableCell align="right">
                    {isDownloadWaiting ?
                      <CircularProgress
                        size={16}
                      />
                      : null
                    }
                  </TableCell> 
                    
                  <TableCell>Категории товаров</TableCell>
                  <TableCell>Всего: {totalCount} шт.</TableCell>
                  
                </TableRow>
              </TableHead>
              <TableBody sx={stylesObj.TableContainer}>
                {(typeof context.types === 'object') 
                  ? context.types.map((item, index) => {
                  
                    return (
                      <TypesGroupsSendingProduct 
                        key={index} 
                        index={index}

                        invoice={context.invoice}

                        isHideEmpty={isHideEmpty}
                        typeTab={item}
                        onChangeQuantity={onChangeQuantity} 
                        onChangeComment={onChangeComment}
                        isOpen={index===openedTabIndex}
                        onSetOpenedTabIndex={setOpenedTabIndex}
                        isDownloadWaiting={isDownloadWaiting}
                        countByCategory={countByCategory}
                      />
                    );
                  })
                  : 
                  <></>
                
                }
                <TypesGroupsSendingProduct 
                  key={-1} 
                  index={-1}

                  invoice={context.invoice}

                  typeTab={{
                    id: 0,
                    name: 'без категории',
                    color: '#bbb',
                  }}
                  
                  isHideEmpty={isHideEmpty}
                  onChangeQuantity={onChangeQuantity} 
                  onChangeComment={onChangeComment}
                  isOpen={openedTabIndex === -1}
                  onSetOpenedTabIndex={setOpenedTabIndex}
                  isDownloadWaiting={isDownloadWaiting}
                  countByCategory={countByCategory}

                />
              </TableBody>
            </Table>
          </TableContainer>
        
          {(context.invoice.length 
            && context.invoicePoint) ?
            <Button variant="contained" 
              endIcon={<SendIcon/>}  
              onClick={handlePressSend}
              sx={stylesObj.SendRemainsButton}
            > 
            отправить товар
            </Button>
            : <></>
          }
        </div>
        :<></>
      }
    </div>
  );
};


export { SendingProduct };