
export default function generatePaletteColor (toCounter = 8, selected) {
  let paletteColor = [];
  if (selected) { 
    paletteColor = [...paletteColor, selected];
    toCounter--;
  }
  let newColor = '#b5f';
  for (let i=0; i<toCounter; i++){
    switch (i%3) { 
      case 0 : 
        newColor = '#f' + Math.random().toString(16).substr(-5) + 'dd';
        break;
      case 1 : 
        newColor = '#' + Math.random().toString(16).substr(-2) + 'f' + Math.random().toString(16).substr(-3) + 'dd';
        break;
      case 2 : 
        newColor = '#' + Math.random().toString(16).substr(-4) + 'f0dd';
        break;
    }
    paletteColor = [...paletteColor, newColor];
  }
  return paletteColor;
};