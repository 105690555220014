import React, { useContext, useEffect, useReducer } from 'react';
import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';

import { Typography, TextField, Paper, FormControlLabel, Checkbox, FormHelperText} from '@mui/material';
import OneSignalReact from 'react-onesignal';

import { Context } from '../../../../functions/context';

import addressesReducer from '../addressesReducer';
import defaultArrayStateForReducer from '../../../../resources/defaultArrayStateForReducer';
import getSubscribes from '../fetch/getSubscribes';
import addSubscribe from '../fetch/addSubscribe';
import deleteSubscribe from '../fetch/deleteSubscribe';

import ItemWithDeleteButton from '../../../../Components/ItemWithDeleteButton';
import CheckBoxList from '../CheckBoxList';

const DELIVERY_TYPE = 'pushWeb';

const PushSettings = (props) => {
  const context = useContext(Context);
  
  const [pushes, pushesDispatch] = useReducer(addressesReducer , {...defaultArrayStateForReducer, newValue:  false });
  
  const getValues = async () => {
    pushesDispatch({
      type: 'START_WAITING'
    });
    let resultSubscribes = await getSubscribes({
      token: context.token,
      deliveryType: DELIVERY_TYPE
    });
    let subscribeArr = [];
    if (context.isInitOpenSignal){ 
      try {
        let playerID = await OneSignalReact.getUserId();
        subscribeArr = resultSubscribes.data.filter(element => element.playerID === playerID);
        let isActive = await OneSignalReact.getSubscription();
        console.log('isActive did mount', isActive, subscribeArr);
        if ( isActive && (typeof subscribeArr === 'object') && (subscribeArr.length > 0)){
          resultSubscribes = { ...resultSubscribes, newValue: true };
        }
      } catch (e) {
        console.log (e);
      }
    }
       
    pushesDispatch({
      type: 'GET_STATE', 
      payload: resultSubscribes
    });

  };

  useEffect(()=>{
    getValues();
  },[context.isInitOpenSignal]);

  const onClickDeleteButton = async (id) => {
    pushesDispatch({
      type: 'START_WAITING'
    });
    const result = await deleteSubscribe ({
      token: context.token,
      deliveryType: DELIVERY_TYPE,
      id:id
    });
    pushesDispatch({
      type: 'DELETE',
      payload: {...result}
    });
  };

  const handleChangeAllPushNotification = async(e) => {
    let newErrTxt = '';
    let newDisableSaveButton = false;
    const newChecked = e.target.checked;
  
    if (newChecked){
      if (context.isInitOpenSignal){
        let isGetPermission = await OneSignalReact.getNotificationPermission();
        if (isGetPermission === 'granted'){
          pushesDispatch({
            type: 'START_WAITING'
          });
          //registerForPushNotifications
          let playerID = await OneSignalReact.getUserId();
          if (playerID === null) {
            newErrTxt = 'не получен id подписки';
            newDisableSaveButton = true;
            pushesDispatch({
              type: 'ERROR', 
              payload: {   
                error: newErrTxt,
                isDisableSaveButton: newDisableSaveButton,
              }
            });
          }else{
            let subscribeArr = [];
            subscribeArr = pushes.data.filter(element => element.playerID === playerID);
            if (typeof subscribeArr !== 'object'){
              subscribeArr = [];
            }

            if (subscribeArr.length === 0){
              const result = await addSubscribe({
                token: context.token,
                deliveryType: DELIVERY_TYPE,
                address: {
                  endpoint: navigator.userAgent,
                  p256dh: '',///заполнить
                  auth: '',
                  playerID: playerID
                },
              });
              
              pushesDispatch({
                type: 'ADD_NEW_VALUE', 
                payload: {
                  newValue: newChecked,
                  error: newErrTxt,
                  isDisableSaveButton: newDisableSaveButton,
                  ...result
                }
              });
            }else{
              
              pushesDispatch({
                type: 'ADD_NEW_VALUE', 
                payload: {
                  newValue: newChecked,
                  error: newErrTxt,
                  isDisableSaveButton: newDisableSaveButton,
                }
              });
            }
            OneSignalReact.setSubscription(newChecked);
          }
        }else{
          newErrTxt = 'для получение подписки требуется получение разрешения на push сообщения';
          newDisableSaveButton = true;
          pushesDispatch({
            type: 'ERROR', 
            payload: { 
              error: newErrTxt,
              isDisableSaveButton: newDisableSaveButton,
            }
          });
        }
      }
    }else{
      OneSignalReact.setSubscription(newChecked);
      
      pushesDispatch({
        type: 'ADD_NEW_VALUE', 
        payload: {   
          newValue: newChecked,
          error: newErrTxt,
          isDisableSaveButton: newDisableSaveButton,
        }
      });
    }
  };

  return (
    <Paper 
      elevation={4} 
      sx={{
        display: 'flex',
        flexDirection: 'column',
        padding: 4,
        margin: 1,
        minWidth: 300,
        maxWidth: 550,
      }}>
      <h3>push уведомления</h3>
      <FormControlLabel 
        control={<Checkbox onChange={handleChangeAllPushNotification} checked = {pushes.newValue}/>} 
        label={'подписать этот браузер на push уведомления для текущего пользователя. ' + pushes.error}
        disabled = {!!pushes.isWaiting || !context.isInitOpenSignal}  
        error = {!!pushes.error}
      />

      <FormHelperText>{pushes.error}</FormHelperText>

      <Typography sx={{ marginY: 2, }}>
      настройки уведомлений сохранены для браузеров:
      </Typography>

      {pushes?.data?.map((item)=>
        <ItemWithDeleteButton
          key = {item.id}
          text = {item.endpoint}
          id = {item.id}
          isWaiting = {pushes.isWaiting}
          onClickDeleteButton = {onClickDeleteButton}
        />
      )}

      <Typography>
      Настройки уведомлений работают одинаково на все браузеры.
      </Typography>
      
      <CheckBoxList deliveryType = {DELIVERY_TYPE} />
      
    </Paper>
  );
  

};

export default PushSettings;