import * as React from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';

import Button from '@mui/material/Button';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { Context } from '../../../functions/context';
import axi from '../../../functions/axiosf';
import LoadingButton from '@mui/lab/LoadingButton';
import moment from 'moment/moment';

const MOBILE_TABLE = 860;

export default function UsersRow(props) {
  const context = React.useContext(Context);
  
  const [isWaiting, setIsWaiting] = React.useState(false);

  const handlePressUpdate = () => {
    if (typeof props.onOpenModal === 'function'){
      props.onOpenModal(+props.item.id);
    }
  };

  const handleRemoveCategory = async() => {
    setIsWaiting(true);
    let postParams = { 
      token: context.token, 
      role: props.role,
      userId: props.item.id,
      isFired: Math.round(+ new Date()/1000),
    };
    try {
      let result = await axi(
        'admin/updateUser.php', 
        '', 
        postParams); 
      if (typeof context.setAllBase === 'function'){ 
        context.setAllBase(result);
      }
    } catch (e) {
      console.log(e);
    }
    setIsWaiting(false);
  };

  return(
    <TableRow 
      disabled={isWaiting}
      hover>
      <TableCell component="th" scope="row">
        {props.item.name}
      </TableCell>
      {props.point?
        <TableCell component="th" scope="row">
          {props.point}
        </TableCell>
        :null
      }
      {props.role === 'cashiers' && typeof props.item.isFired !== 'undefined' && +props.item.isFired > 0 ?
        <TableCell component="th" scope="row">
          {
            moment(+props.item.isFired * 1000).format('lll')
          }
        </TableCell>
        :null
      }
      <TableCell align="right">
        <div style={{display:'flex', justifyContent:'flex-end'}}>
          {props.role === 'cashiers' && +props.item.isFired === 0?
          
            <LoadingButton 
              variant="contained"
              color="error"
              disabled={isWaiting}
              loading={isWaiting}
              loadingPosition="end"
              endIcon={<ClearIcon />}
              onClick={handleRemoveCategory}
            >
              {document.body.clientWidth > MOBILE_TABLE ? 'Уволить' : ''}
            </LoadingButton>
                     
            :null
          }
          
          <LoadingButton 
            variant="contained"
            //color="success"
            disabled={isWaiting}
            loading={isWaiting}
            loadingPosition="end"
            endIcon={<BorderColorIcon/>}
            onClick={handlePressUpdate}
            sx={{marginLeft: 1}}
          >
            {document.body.clientWidth > MOBILE_TABLE ? 'Изменить' : ''}
          </LoadingButton>
        </div>
      </TableCell>
    </TableRow>     
  );     
};
