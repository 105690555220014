import React, {useState, useEffect, useContext} from 'react';
import TextField from '@mui/material/TextField';
import PointsTable from './PointsTable/index';
import IconButton from '@mui/material/IconButton';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import axi from './../../functions/axiosf';
import { Context } from '../../functions/context';
import { CircularProgress } from '@mui/material';
import validateTextInput from '../../functions/validateTextInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { NewPointForm } from './NewPointForm';

const Points = () => {
  const context = useContext(Context);

  const [pointsArray, setPointsArray] = useState([]);
  const [isWaiting, setIsWaiting] = useState(false);
  const [availableKKTs, setAvailableKKTs] = useState([]);  

  useEffect(()=>{
    context.axiGetAllBase();
  },[]);

  useEffect(()=>{
    let freeKKTs = [];
    context.kkts.map((kkt)=>{
      let flag = 0;
      context.salePoints.map((point)=>{
        if(point.KktRegNumber === kkt.KktRegId){
          flag++;
        }
      });
      if(!flag){
        freeKKTs = [...freeKKTs, kkt];
      }
    });
    setAvailableKKTs(freeKKTs);
  },[context]);


  //функция удаления точки из списка
  const handleRemovePoint = (id) => {
    const newPoints = pointsArray.filter(point => point.id !== id);
    setPointsArray(newPoints);
  };

  //функция изменения названия и адреса точки в модалке
  const handleChangePointsArr = async(id, newName, newAddress, kktRegId = [''], timeOpen = 0, timeClose =0) => {
    setIsWaiting(true);
    try {
      let result = await axi(
        'admin/salePoints/updateSalePoint.php', 
        '', 
        { 
          token: context.token, 
          newPointName: newName,
          newPointAddress: newAddress,
          KktRegId: kktRegId,
          pointId: id,
          timeOpen: timeOpen,
          timeClose: timeClose,
        }); 
      if (typeof context.setAllBase === 'function'){ 
        context.setAllBase(result);
      }
    } catch (e) {
      console.log(e);
    }
    setIsWaiting(false);
    return('finished');
  };

  return(
    <div>
      <NewPointForm
        isWaiting = {isWaiting}
        availableKKTs = {availableKKTs}
      />
      <PointsTable 
        pointsArray = {pointsArray} 
        handleRemovePoint = {handleRemovePoint}
        handleChangePointsArr = {handleChangePointsArr} 
        availableKKTs = {availableKKTs}
        isWaiting={isWaiting}
      />
    </div>
  );
};


export { Points };