import React, { Fragment, useContext, useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ruLocale from 'date-fns/locale/ru';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import Paper from '@mui/material/Paper';

import axi from '../../functions/axiosf';

import style from './index.module.css';
import {stylesObj} from '../../stylesObj/stylesObj';

import ApprovalModal from './ApprovalModal';

import { Context } from '../../functions/context';
import kitsTypes from '../../resources/kitsTypes';
import daysOfWeek from '../../resources/daysOfWeek';
import moment from 'moment';
import { DatePicker, MobileDatePicker } from '@mui/x-date-pickers';
import TextField from '@mui/material/TextField';
import { PickersCalendarHeader } from '@mui/x-date-pickers/internals';
import TradePeriodsAndRulesAccordion from './tradePeriodsAndRulesAccordion';
import RootSettings from './RootSettings';
import SubscribeSettings from './SubscribeSettings';

const Settings = () => {
  const context = useContext(Context);
  const [point, setPoint] = useState(0);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isWaiting, setIsWaiting] = useState(false);

  useEffect(()=>{
    context.axiGetAllBase();
  },[]);

  return(
    <div className={style.verticalPage}>
      <ApprovalModal
        isOpen = {isModalOpen}
        onSetIsModalOpen={setIsModalOpen}
        onPressConfirmSend={()=>{}}
        isWaiting={isWaiting}
      />

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Подписки на события</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <SubscribeSettings/>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>настройки по умолчанию периодов скорости продаж</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TradePeriodsAndRulesAccordion/>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography>Аккаунты, ключи, администрирование. 
            <span style={{color: '#600'}}> Внимание, критическая зона!</span>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <RootSettings />
        </AccordionDetails>
      </Accordion>

      {/* 
      
      <div 
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          width: '100%'
        }}
      >
        <FormControl style={{flex: 1 , minWidth: '200px', margin: 16}}>
          <InputLabel id="demo-simple-select-label">Набор</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={''}
            label="Набор"
            onChange={()=>{}}
          >
            {[{id: 0, name: ''}].map((item, index)=>
              <MenuItem 
                key={index} 
                value={item.id}>
                {item.name}
              </MenuItem>)}
          </Select>
        </FormControl>
      </div>

      */}


    </div>
  );
};


export default Settings;