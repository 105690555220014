import * as React from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';

import Button from '@mui/material/Button';
import BorderColorIcon from '@mui/icons-material/BorderColor';


export default function CategoryRow(props) {
  let {category} = props;
  const handlePressUpdate = () => {
    if (typeof props.onOpenModal === 'function'){
      props.onOpenModal(props.index);
    }
  };

  return(
   
    <TableRow sx={{backgroundColor: `${category.color}`}} hover>
      <TableCell component="th" scope="row">
        {category.name}
      </TableCell>
      <TableCell align="right">
        <div style={{display:'flex', justifyContent:'flex-end'}}>
          {/* я думаю, что кнопка для удаления не нужна совсем
                    <IconButton onClick={() => handleRemoveCategory(category.id)} style={{color: 'black'}}>
                      <ClearIcon />
                    </IconButton>
                     */}
          <IconButton 
            variant="contained" 
            onClick={handlePressUpdate}>
            <BorderColorIcon/>
          </IconButton>
        </div>
      </TableCell>
    </TableRow>     
  );     
};
