import React, { Fragment, useContext, useEffect, useState } from 'react';

import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PasswordIcon from '@mui/icons-material/Password';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import Typography from '@mui/material/Typography';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ruLocale from 'date-fns/locale/ru';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

import Paper from '@mui/material/Paper';

import axi from '../../../functions/axiosf';

import {stylesObj} from '../../../stylesObj/stylesObj';

import { Context } from '../../../functions/context';


import moment from 'moment';

import TextField from '@mui/material/TextField';
import { Divider } from '@mui/material';

const SettingsItemInput = (props) => {
  const context = useContext(Context);

  const [isWaiting, setIsWaiting] = useState(false);
  const [error, setError] = useState('');
  const [value, setValue] = useState(props.value ? props.value : '');
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isPassword, setIsPassword] = useState(props.isPassword);

  const handleSaveValue = async() => {
    if (!isWaiting){
      setIsWaiting(true);
      try {
        let result = await axi(
          'rootSettings/setRootSetting.php',
          '', 
          { 
            rootPassword: props.licensePassword,
            token: context.token, 
            field: props.name,
            value: value,
          });  
        if ( typeof props.onGetAllSettings === 'function'){
          props.onGetAllSettings();
        }       
      } catch (e) {
        alert(e);
      } finally {     
        setIsWaiting(false);
        setIsButtonDisabled(true);
      }
    }
  };

  const handleChangeValue = (e) => {
    if (e.target.value.length < 4){
      setError('длинна должна быть больше трёх символов');
      setIsButtonDisabled(true);
    }else{
      setError('');
      setIsButtonDisabled(false);
    }
    setValue(e.target.value);
  };

  const handleToggleTypePassword = () => {
    setIsPassword(!isPassword);
  };

  return(
    <Fragment>
      <Divider variant="fullWidth"/>
      <div
        style={stylesObj.simpleRowWrap}
      >
        <TextField
          label={props.label}
          id="standard-size-normal"
          defaultValue=""
          variant="outlined"
          sx={{flex:3, minWidth: '280px', margin: 1}}
          disabled = {props.isWaiting || isWaiting || !props.name}
          type = {isPassword ? 'password' : 'text'}
          onChange={handleChangeValue}
          value={value}
          error = {!!error}
          helperText = {error}
        />
        {props.isPassword &&
        <LoadingButton 
          //color="success"
          variant="contained"
          sx={{flex: 1, margin: 1, padding: 2, height: 56}}
          disabled={props.isWaiting || !props.name}
          loading={props.isWaiting || isWaiting}
          loadingPosition="end"
          endIcon={isPassword ? <VisibilityIcon/> : <VisibilityOffIcon/>}
          onClick={handleToggleTypePassword}
        >
          {isPassword ? 'показать' : 'скрыть'}
        </LoadingButton>
        }
        {!isButtonDisabled &&
        <LoadingButton 
          color="success"
          variant="contained"
          sx={{flex: 1, margin: 1, padding: 2, height: 56}}
          disabled={props.isWaiting || !props.name}
          loading={props.isWaiting || isWaiting}
          loadingPosition="end"
          endIcon={<SaveIcon/>}
          onClick={handleSaveValue}
        >
          сохранить
        </LoadingButton>
        }
      </div>
    </Fragment>
  );
};

export default SettingsItemInput;