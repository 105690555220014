/* eslint-disable eqeqeq */
import React, { useContext, useEffect, useState, useRef, Fragment } from 'react';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import {stylesObj} from '../../../stylesObj/stylesObj';

import { Context } from '../../../functions/context';
import getSumWithFixed2 from '../../../functions/getSumWithFixed2';
import propsF from '../../../functions/propsF';
import styles from '../index.module.css';
import checksColors from '../checksColors';
import { Card } from '@mui/material';
import { MOBILE_SCREEN_WIDTH } from '../../../stylesObj/layoutConsts';

const ChecksHeader=({
  allChecks,
  sortOrderTypes,
  sortOrder,setSortOrder,
  sortButtonsEnabled,setSortButtonsEnabled,
  showZeroChecks,setShowZeroChecks,
  headTotalSum,headTotalCount,
})=>{

  const context = useContext(Context);

  const avgCheckSum=()=> 
    headTotalCount !== 0 ? getSumWithFixed2(headTotalSum / headTotalCount) : ' - ';

  const sortChecks = (newSortOrder) => {
    propsF(setSortButtonsEnabled,false);
    switch (newSortOrder) {
      case sortOrderTypes.SumAsc:
        allChecks.sort((a, b) => {
          return a.total - b.total;
        });
        break;

      case sortOrderTypes.SumDesc:
        allChecks.sort((a, b) => {
          return b.total - a.total;
        });
        break;

      case sortOrderTypes.TimeAsc:
        allChecks.sort((a, b) => {
          return a.time - b.time;
        });
        break;

      case sortOrderTypes.TimeDesc:
        allChecks.sort((a, b) => {
          return b.time - a.time;
        });
        break;
    }
    propsF(setSortOrder,newSortOrder);
    propsF(setSortButtonsEnabled,true);
  };

  const getSortIcon = (button) => {
    const getIcon = (sortAsc, sortDesc) => {
      if (sortOrder === sortAsc) {
        return <ArrowDropUpIcon />;
      } else if (sortOrder === sortDesc) {
        return <ArrowDropDownIcon />;
      } else {
        return null;
      }
    };

    if (button === 'sum') {
      return getIcon(sortOrderTypes.SumAsc, sortOrderTypes.SumDesc);
    } else {
      return getIcon(sortOrderTypes.TimeAsc, sortOrderTypes.TimeDesc);
    }
  };

  const changeSortOrder = (sortAsc, sortDesc) => {
    if (sortButtonsEnabled) {
      if (sortOrder !== sortAsc) {
        sortChecks(sortAsc);
      } else if (sortOrder === sortAsc) {
        sortChecks(sortDesc);
      }
    }
  };


  const handleTimeButton = (event) =>
    changeSortOrder(sortOrderTypes.TimeAsc, sortOrderTypes.TimeDesc);

  const handleSumButton = (event) =>
    changeSortOrder(sortOrderTypes.SumAsc, sortOrderTypes.SumDesc);

  const handleChangeShowZeroChecks=(event)=> propsF(setShowZeroChecks,event?.target?.checked);

  return (
    <Card
      sx={{ backgroundColor: checksColors.checksHeaderBackground, width: '100%', maxWidth: '480px' }}
      className={styles.accordion}
    >
      <table className={styles.tableChecks}>
        <tbody>
          <tr
            className={styles.tableRow}
          >
            <td>
              Всего: <b>{getSumWithFixed2(headTotalSum)}</b> р.
            </td>
            <td>
              Чеков: <b>{headTotalCount}</b> шт.
            </td>
            <td>
              Средний чек: <b>{avgCheckSum()}</b> р.
            </td>
          </tr>

          <tr 
            className={styles.tableRow}
            style={{ borderBottomColor: `${checksColors.tableUnderlineColor}`,
              flexWrap: 'nowrap' }}
          >
            <td>
              <Button
                variant='contained'
                onClick={handleTimeButton}
                endIcon={getSortIcon('time')}
              >
                Время импорта
              </Button>
            </td>
            <td
              style={{textAlign: 'center',}}
            >
              <Checkbox 
                defaultChecked
                value={showZeroChecks} 
                onChange={handleChangeShowZeroChecks}
              />
              <>0&nbsp;</>
              <>чеки</>
            </td>
            <td>
              <Button
                variant='contained'
                onClick={handleSumButton}
                endIcon={getSortIcon('sum')}
              >
                Сумма чека
              </Button>
            </td>
          </tr>
        </tbody>
      </table>
    </Card>
  );

};

export { ChecksHeader};
