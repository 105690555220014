import React, { Fragment, useContext, useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';

import Paper from '@mui/material/Paper';

import axi from '../../../../functions/axiosf';

import style from './../../index.module.css';
import {stylesObj} from '../../../../stylesObj/stylesObj';

import { Context } from '../../../../functions/context';
import Row from './Row';
import NewRow from './NewRow';

const OneCheckMotivations = (props) => {
  const context = useContext(Context);
  const {point} = props;
  const [isWaiting, setIsWaiting] = useState(false);
  const [inOneCheckMotivations, setOneCheckMotivations] = useState([]);



  useEffect(()=>{
    if (point !== null){
      onReadOneCheckMotivations(point);
    }
  },[point]);
  
  const onReadOneCheckMotivations = async (point) => {
    setIsWaiting(true);
    let result = await axi(
      'admin/motivations/readOneCheckMotivations.php', 
      '', 
      { 
        token: context.token, 
        point: point,
      });
    if (result.type === 'no_authorized') {
      if(typeof context.userExit === 'function'){
        context.userExit();
      }
    } else {
      setOneCheckMotivations(result.oneCheckMotivations);
    }
    setIsWaiting(false);
  };

  const onPressAddNewOneCheckMotivation = async ( quantityInCheck, quantity, remuneration ) => {
    setIsWaiting(true);
    let result = await axi(
      'admin/motivations/addOneCheckMotivation.php', 
      '', 
      { 
        token: context.token, 
        point: point, 
        quantityInCheck: quantityInCheck,
        quantity: quantity,
        remuneration: remuneration,
      });
    if (result.type === 'no_authorized') {
      if(typeof context.userExit === 'function'){
        context.userExit();
      }
    } else {
      setOneCheckMotivations(result.oneCheckMotivations);
    }
    setIsWaiting(false);
  };

  const onPressUpdateOneCheckMotivation = async (motivationId, quantity, remuneration ) => {
    setIsWaiting(true);
    let result = await axi(
      'admin/motivations/updateOneCheckMotivation.php', 
      '', 
      { 
        token: context.token, 
        point: point,
        motivationId: motivationId,
        quantity: quantity,
        remuneration: remuneration,
      });
    if (result.type === 'no_authorized') {
      if(typeof context.userExit === 'function'){
        context.userExit();
      }
    } else {
      setOneCheckMotivations(result.oneCheckMotivations);
    }
    setIsWaiting(false);
  };

  return(
    <div className={style.verticalPage}>
      <h4>Мотивация продавцов по количеству продаж в чеке</h4>
      <TableContainer className={style.Paper} component={Paper}>
        <Table aria-label="collapsible table" className={style.Paperbody} >
          <TableBody>
            <TableRow className={style.Paperhead} >             
              <TableCell component="th" scope="row" align="left" >кол-во в чеке</TableCell>               
              <TableCell component="th" scope="row" align="right">план таких чеков</TableCell>             
              <TableCell component="th" scope="row" align="left">вознаграждение</TableCell>             
              <TableCell component="th" scope="row"></TableCell>   
            </TableRow>
            <NewRow
              isWaiting={isWaiting}
              onPressAddNewOneCheckMotivation={onPressAddNewOneCheckMotivation}
            />
            {inOneCheckMotivations && inOneCheckMotivations.map((item, index)=>
              <Row
                key={index}
                item={item}
                isWaiting={isWaiting}
                onPressUpdateOneCheckMotivation={onPressUpdateOneCheckMotivation}
              />
            )
            }
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};


export { OneCheckMotivations };