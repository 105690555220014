/* eslint-disable eqeqeq */
import React, { useContext, useEffect, useState, useRef, Fragment } from 'react';

import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';


import { Context } from '../../../functions/context';
import moment from 'moment';
import hourMinFromMinutes from '../../../functions/hourMinFromMinutes';
import { MovementOfGoods } from './MovementOfGoods';

const ControlNomenclatureItem = ({
  item
}) => {
  const context = useContext(Context);
  
  const renderCashierName = (id) => {
    let cashier = context.cashiers.find((cashier) => cashier.id === id);
    return cashier===undefined?'':cashier.name;
  };

  const renderProductName = (id) => {
    let product = context.nomenclature.find((product) => product.id === id);
    return product===undefined?'':product.name;
  };
  
  const renderPointName = (id) => {
    let point = context.salePoints.find((point) => point.id === id);
    return point===undefined?'':point.name;
  };

  const renderTradePeriod = (tradePeriod) => {
    return renderTime(tradePeriod?.from) + ' - ' + renderTime(tradePeriod?.to);
  };

  const renderTime = (value) => {
    if (value === '0' || value === undefined || value === null) {
      return '';
    }
    return hourMinFromMinutes(+value);
  };
 
   
  return(
    <TableRow>     
      
      <TableCell component="th" scope="row" align="left" sx={{maxWidth: 120}}>{renderPointName(item?.salePointId)}</TableCell>              
      
      <TableCell component="th" scope="row" align="left" sx={{maxWidth: 120}}>{renderCashierName(item?.cashierId)}</TableCell>     
      
      <TableCell component="th" scope="row" align="left" sx={{maxWidth: 120}}>{renderProductName(item?.productId)}</TableCell> 

      <TableCell component="th" scope="row" align="left" sx={{maxWidth: 80}}>{item?.saleSum}</TableCell>     
      
      <TableCell component="th" scope="row" align="left">
        {item?.movementOfGoods ?
          <MovementOfGoods
            invoices = {item?.movementOfGoods}
          />
          :''
        }
      </TableCell>   

      <TableCell component="th" scope="row" align="left" sx={{maxWidth: 120}}>{renderTradePeriod(item?.tradePeriod)}</TableCell>     
      
      <TableCell component="th" scope="row" align="right">
        {renderTime(item?.firstSaleMinute)}
      </TableCell> 
      
      {item?.lastSaleMinute==='0'
        ? <TableCell component="th" scope="row" align="left" sx={{backgroundColor: '#99d599' , maxWidth: 80 }} >
        </TableCell> 
        : <TableCell component="th" scope="row" align="left" sx={{backgroundColor: '#f99' , maxWidth: 80}} >
          {renderTime(item?.lastSaleMinute)}
        </TableCell> 
      }
                 
    </TableRow>
  );
  
};

export { ControlNomenclatureItem };