import * as React from 'react';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ruLocale from 'date-fns/locale/ru';
import moment from 'moment';
import { DatePicker, MobileDatePicker } from '@mui/x-date-pickers';
import TextField from '@mui/material/TextField';

import { Context } from './../../functions/context';
import kitsTypes from './../../resources/kitsTypes';
import daysOfWeek from './../../resources/daysOfWeek';
import propsF from './../../functions/propsF';


export default function DayOfMonthPicker(props) {
  const context = React.useContext(Context);

  const handleChangeDayOfMonth = (e) => {
    propsF(props.onChangeDayOfMonth, e);
  };
  
  return(    
    <LocalizationProvider 
      dateAdapter={AdapterDateFns}
      adapterLocale={ruLocale}
    >
      <DatePicker
        label="день месяца"
        value={props.dayOfMonth}
        onChange={handleChangeDayOfMonth}
        renderInput={(params) => 
          <TextField 
            {...params} 
            sx={{m:1, width: '100px'}}
          />}
        disabled = {props.isWaiting}
        views={['day']}
        inputFormat="dd"
        dayOfWeekFormatter={(day) => ''}
        components={{
          LeftArrowButton: null,
          RightArrowButton: null,
        }}
        componentsProps={{
          leftArrowButton: {sx: {display: 'none', height: 0}},
          rightArrowButton: {sx: {display: 'none', height: 0}}
        }}
        PaperProps={{ sx: {marginTop: '-80px',}}}
        PopperProps = {{ 
          sx: {
            height: '220px', 
            overflowY: 'hidden',
            boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)'
          } }}
        //minDate = "Mon Aug 01 2022 23:03:23 GMT+0300"
        minDate = {1659384203000}
        //maxDate = "Wed Aug 31 2022 23:03:23 GMT+0300"
        maxDate={1661976203000}
      />
    </LocalizationProvider>
  );     
};
