/* eslint-disable eqeqeq */
import React, { useContext, useEffect, useState, useRef, Fragment } from 'react';

import { Context } from '../../../functions/context';
import moment from 'moment';
import hourMinFromMinutes from '../../../functions/hourMinFromMinutes';
import { stylesObj } from '../../../stylesObj/stylesObj';
import invoiceTypesColors from '../../../stylesObj/invoiceTypesColors';
import invoiceTypes from '../../../resources/invoiceTypes';
import { InvoiceTile } from './InvoiceTile';

const MovementOfGoods = ({invoices}) => {
  const context = useContext(Context);
  
  let calcCrossInvoices = 0;
  let flagFirstRemains = false;

  return(
    <div style={{...stylesObj.simpleRowWrap}}>    
      {typeof invoices === 'object' && invoices.length>0 && invoices.map((item, index)=>{
        let quantity = item?.product?.quantity;
        let endRemainsHeader = '';

        if (item?.invoice?.type === 'returnsGoods'){
          calcCrossInvoices = +calcCrossInvoices - +quantity;
          quantity = '- ' + quantity;
        }else{
          if (item?.invoice?.type === 'endRemains'){
            endRemainsHeader = 'остаток: ';
          }else{
            if (item?.invoice?.type !== 'shipmentGoods'){ 
              if (item?.invoice?.type === 'startRemains'){
                if (!flagFirstRemains){
                  calcCrossInvoices = +calcCrossInvoices + +quantity;
                  quantity = '+ ' + quantity;
                  flagFirstRemains = true;
                }
                endRemainsHeader = 'старт: ';
              }else{
                calcCrossInvoices = +calcCrossInvoices + +quantity;
                quantity = '+ ' + quantity;
              }
            }   
          }
        }

        if (item?.invoice?.type !== 'shipmentGoods'){  
          return (
            <InvoiceTile
              {...{item, index, endRemainsHeader, quantity}}
            />
          );               
        }else{
          return '';
        }
      })}
      {typeof invoices === 'object' && invoices.length>0 &&
        <div
          style = {{
            padding: 4,
            backgroundColor: '#aaa'
          }}
        > 
          <div>
              всего было
          </div>
          <div>
              в продаже
          </div>
          <div>
            {calcCrossInvoices + 'шт.'}
          </div>
        </div>
      }
    </div>
  );
};

export { MovementOfGoods };