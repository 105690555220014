import React, { Fragment, useContext, useEffect, useState } from 'react';

import LoadingButton from '@mui/lab/LoadingButton';
import { Divider, Typography } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import { stylesObj } from '../../stylesObj/stylesObj';
import { Context } from '../../functions/context';

const ItemWithDeleteButton = ({
  text,
  id,
  isWaiting,
  onClickDeleteButton,
}) => {
  const context = useContext(Context);

  const handleClickDeleteButton = () => {
    if ( typeof onClickDeleteButton === 'function'){
      onClickDeleteButton(id);
    }
  };

  return(
    <Fragment>
      <div
        style={{
          ...stylesObj.simpleRowWrap,
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography>
          {text}
        </Typography>
        <LoadingButton 
          variant = "contained"
          color = "error"
          disabled = {!!isWaiting}
          loading = {isWaiting}
          loadingPosition = "end"
          endIcon = {<DeleteForeverIcon/>}
          onClick = {handleClickDeleteButton}
          sx = {stylesObj.margin8}
        >
          Удалить
        </LoadingButton>
      </div>
      <Divider variant="fullWidth" sx = {stylesObj.margin8}/>
    </Fragment>
  );
};

export default ItemWithDeleteButton;