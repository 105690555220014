import React, {useState, useEffect, useContext, Fragment} from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import ruLocale from 'date-fns/locale/ru';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

import ModalBottomButtonsBlock from '../../../../Components/ModalBottomButtonsBlock';
import IconButton from '@mui/material/IconButton';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import validateTextInput from '../../../../functions/validateTextInput';
import { Context } from '../../../../functions/context';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 320,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',

};

export default function PointsChangeModal(props) {
  const context = useContext(Context);
  const [newName, setNewName] = useState(props.PointName);
  const [newAddress, setNewAddress] = useState(props.PointAddress);
  const [nameError, setNameError] = useState('');

  
  const [timeOpen, setTimeOpen] = useState(+props.point?.open);
  const [timeClose, setTimeClose] = useState(+props.point?.close);

  const [pointKKT, setPointKKT] = useState([]);
  const [pointKKTobj, setPointKKTobj] = useState([]);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleChangeNewName = (event) => {
    let newName = event?.target?.value;
    let [textError, isSetState] = validateTextInput(newName, 'наименование точки');
    isSetState && setNewName(newName);
    setNameError(textError);
  };

  const handleChangeNewAdress = (event) => {
    setNewAddress(event.target.value);
  };

  
  const handleSetNewTimeOpen =(event)=>{
    //setTimeOpen((+event.$d.getUTCHours()*60)+ (+event.$d.getUTCMinutes()));
    setTimeOpen((event.$H*60)+event.$m);
  };

  useEffect(()=>{
    setTimeOpen(+props.point.open);
  },[props.point.open]);
  
  const handleSetNewTimeClose =(event)=>{
    //setTimeClose((+event.$d.getUTCHours()*60)+ (+event.$d.getUTCMinutes()));
    setTimeClose((event.$H*60)+event.$m);
  };
  
  useEffect(()=>{
    setTimeClose(+props.point.close);
  },[props.point.close]);
  
  const handleChangeKKt = (event) => {
    let newKKT = event.target.value;
    setPointKKT(typeof newKKT === 'string' ? newKKT.split(',') : newKKT,);
  };

  

  const handleChangeAccept = async() => {
    if (typeof props.handleChangePointsArr === 'function'){
      await props.handleChangePointsArr(props.PointId, newName, newAddress, pointKKT, timeOpen, timeClose);
    }
    handleClose();
  };

  useEffect(()=>{
    let currentKKT = [];
    let currentKktRegIds = [];
    context.kkts.map((kkt)=>{
      if(kkt.salePoint===props.PointId){
        currentKKT=[...currentKKT, kkt];
        currentKktRegIds=[...currentKktRegIds, kkt.KktRegId];
      }
    });
    setPointKKTobj(currentKKT);
    setPointKKT(currentKktRegIds);
  },[]);

  return(
    <div>
      <IconButton 
        onClick={handleOpen}>
        <BorderColorIcon/>
      </IconButton>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <TextField
              fullWidth
              label="Название"
              id="standard-size-small"
              value={newName}
              variant="outlined"
              sx={{marginTop: '16px'}}
              onChange={handleChangeNewName}
              error = {nameError}
              helperText = {nameError}
            />
            
            <TextField
              fullWidth
              label="Адрес"
              id="standard-size-normal"
              value={newAddress}
              variant="outlined"
              sx={{marginTop: '16px',}}
              onChange={handleChangeNewAdress}

            />
            
            <LocalizationProvider 
              dateAdapter={AdapterDayjs}
              adapterLocale={ruLocale}
            >
              <TimePicker
                label="начало"
                value={(timeOpen + new Date().getTimezoneOffset()) *60*1000}
                onChange={handleSetNewTimeOpen}
                renderInput={(params) => <TextField sx={{marginTop: '16px',}} {...params} />}
                
              />
            </LocalizationProvider>
            <LocalizationProvider 
              dateAdapter={AdapterDayjs}
              adapterLocale={ruLocale}
            >
              <TimePicker
                label="окончание"
                value={(timeClose + new Date().getTimezoneOffset()) *60*1000}
                onChange={handleSetNewTimeClose}
                renderInput={(params) => <TextField sx={{marginTop: '16px',}} {...params} />}
                
              />
            </LocalizationProvider>

            <FormControl 
              style={{
                flex: 1 , 
                minWidth: '200px', 
                marginTop: 16,
                marginBottom: 16,
              }}>
              <InputLabel id="demo-simple-select-label">Касса</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={pointKKT}
                renderValue={(pointKKT)=> pointKKT.join(', ')}
                label="Касса"
                onChange={handleChangeKKt}
                multiple
              >
                {context.kkts.map((item, index)=>
                  <MenuItem 
                    key={index} 
                    value={item.KktRegId}
                    disabled={ (item.salePoint > 0) && (+item.salePoint !== +props.PointId) }
                  >
                    <Checkbox 
                      checked={pointKKT.indexOf(item.KktRegId) > -1} 
                      disabled={ (item.salePoint > 0) && (+item.salePoint !== +props.PointId) }
                    />
                    <ListItemText>
                      <div 
                    
                      >
                        №: {item.KktRegId}
                        {item.FiscalPlace || item.KktName ?
                          <Fragment>
                            <br />
                            <b>{item.FiscalPlace}</b>{'  ' + item.KktName} <br/> 
                          </Fragment>
                          : ''
                        }
                        {item.FiscalAddress ?
                          <div 
                            style = {{
                              display: 'contents',
                              width: '236px',
                              height: 'auto',
                              overflowWrap: 'normal',
                              whiteSpace: 'normal',
                              color: '#333',
                              fontSize: 13
                            }}>
                            {item.FiscalAddress}
                          </div>
                          : ''
                        }
                      </div>
                    </ListItemText>
                  </MenuItem>
                )}
              </Select>
            </FormControl>
            {/* 
            <div style={{display:'flex', marginTop:'10px'}}>
              <button onClick={() => handleChangeAccept(props.PointId)}>Подтвердить изменения</button>
              <button style={{marginLeft:'200px'}} onClick={handleClose}>Отклонить изменения</button>
            </div> */}
            <ModalBottomButtonsBlock
              isWaiting={props.isWaiting}
              handleAccept={handleChangeAccept}
              textClose="Отклонить изменения"
              handleClose={handleClose}
              disabled={nameError || !newName}
            />
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}