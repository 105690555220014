import React, { Fragment, useContext, useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import Paper from '@mui/material/Paper';

import axi from '../../../../functions/axiosf';

import style from './../../index.module.css';

import { Context } from '../../../../functions/context';

import {stylesObj} from '../../../../stylesObj/stylesObj';
import NewRow from './NewRow';
import Row from './Row';

const ShelfCategoryMotivations = (props) => {
  const context = useContext(Context);
  const {point, type} = props;
  const [isWaiting, setIsWaiting] = useState(false);
  const [shelfPositionMotivations, setShelfPositionMotivations] = useState([]);
  
  useEffect(()=>{
    if (point !== null && type !== null){
      onReadShelfPositionMotivations(point, type);
    }
  },[point, type]);


  const onReadShelfPositionMotivations = async (point, type) => {
    setIsWaiting(true);
    let result = await axi(
      'admin/motivations/readShelfCategoryMotivations.php', 
      '', 
      { 
        token: context.token, 
        point: point,
        type: type,
      });
    if (result.type === 'no_authorized') {
      if(typeof context.userExit === 'function'){
        context.userExit();
      }
    } else {
      setShelfPositionMotivations(result.shelfCategoryMotivations);
    }
    setIsWaiting(false);
  };

  const onPressAddNewShelfPosition = async (quantity, remuneration ) => {
    setIsWaiting(true);
    let result = await axi(
      'admin/motivations/addShelfCategoryMotivations.php', 
      '', 
      { 
        token: context.token, 
        point: point, 
        type: type,
        quantity: quantity,
        remuneration: remuneration,
      });
    if (result.type === 'no_authorized') {
      if(typeof context.userExit === 'function'){
        context.userExit();
      }
    } else {
      setShelfPositionMotivations(result.shelfCategoryMotivations);
    }
    setIsWaiting(false);
  };

  const onPressUpdateShelfPositionMotivation = async (motivationId, quantity, remuneration ) => {
    setIsWaiting(true);
    let result = await axi(
      'admin/motivations/updateShelfCategoryMotivation.php', 
      '', 
      { 
        token: context.token, 
        point: point,
        product: type,
        motivationId: motivationId,
        quantity: quantity,
        remuneration: remuneration,
      });
    if (result.type === 'no_authorized') {
      if(typeof context.userExit === 'function'){
        context.userExit();
      }
    } else {
      setShelfPositionMotivations(result.shelfCategoryMotivations);
    }
    setIsWaiting(false);
  };

  return(
    <div className={style.verticalPage}>
      <h4>Мотивация продавцов по количеству продаж в категории за день</h4>
      <TableContainer className={style.Paper} component={Paper}>
        <Table aria-label="collapsible table" className={style.Paperbody}>
          <TableBody>
            <TableRow className={style.Paperhead} >                      
              <TableCell component="th" scope="row" align="left">категория</TableCell>
              <TableCell component="th" scope="row" align="right">дневной план</TableCell>   
              <TableCell component="th" scope="row" align="left">вознаграждение</TableCell>     
              <TableCell component="th" scope="row"></TableCell>   
            </TableRow>


            <NewRow
              type={type}
              isWaiting={isWaiting}
              onPressAddNewShelfPosition={onPressAddNewShelfPosition}
            />
            {shelfPositionMotivations && shelfPositionMotivations.map((item, index)=>
              <Row
                key={index}
                type={type}
                item={item}
                isWaiting={isWaiting}
                onPressUpdateShelfPositionMotivation={onPressUpdateShelfPositionMotivation}
              />
            )
            }
          </TableBody>
        </Table>
      </TableContainer>

    </div>
  );
};


export { ShelfCategoryMotivations };