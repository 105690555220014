import * as React from 'react';

import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';

import Button from '@mui/material/Button';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';


import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { stylesObj } from '../../../stylesObj/stylesObj';
import { Context } from '../../../functions/context';
import Row from './Row';
import copyObj from '../../../functions/copyObj';




const TypesGroupsSendingProduct = (props) => {
  const context = React.useContext(Context);

  const [positionsInsideType, setPositionsInsideType] = React.useState([]);
  const [sortOrder,setSortOrder] = React.useState('');
  //quantity
  const [sortQuantityOrder,setQuantitySortOrder] = React.useState('');
 

  React.useEffect(()=>{
    let newPositionsInsideType = [];
    context.nomenclature.map((item, index) => {
      if (props.typeTab && props.invoice){
        if (+item.type === +props.typeTab.id){
          let fullItem = {...item};
          fullItem.quantity = 0;
          fullItem.yesterdayQuantity = 0;
          fullItem.coefficient = 0;
          
          props.invoice.map((invoiceItem) => {
            if(invoiceItem.id===item.id){
              //fullItem.quantity = invoiceItem.quantity;
              fullItem = {...invoiceItem};
            }
          });
          newPositionsInsideType = [...newPositionsInsideType, fullItem];
        }
      }else{
        
      }

    });
    if (sortOrder==='asc' || sortOrder === 'desc' ){   
      newPositionsInsideType = doSort(newPositionsInsideType, sortOrder, 'name');
    }
    if (sortQuantityOrder==='asc' || sortQuantityOrder === 'desc' ){   
      newPositionsInsideType = doSort(newPositionsInsideType, sortQuantityOrder, 'quantity');
    }
    setPositionsInsideType(newPositionsInsideType);
  },[context.nomenclature, 
    // props.typeTab, 
    sortOrder,
    sortQuantityOrder,
    props.invoice]);

 
  const onChangeQuantity = (id, value) => {
    if(typeof props.onChangeQuantity === 'function'){
      props.onChangeQuantity(id, value);
    }
    
  };
  
  const onChangeComment = (commentIndex,commentValue ) => {
    if(typeof props.onChangeComment === 'function'){

      props.onChangeComment(commentIndex,commentValue );
    };
  };

  const setOpen = () =>{
    if(typeof props.onSetOpenedTabIndex === 'function'){
      props.onSetOpenedTabIndex(props.index);
    }
  };

  const setClose = () => {
    if(typeof props.onSetOpenedTabIndex === 'function'){
      props.isOpen ?
        props.onSetOpenedTabIndex('')
        :
        props.onSetOpenedTabIndex(props.index);
    }
  };

  const getCategoryName=(typeTab)=>{
    return typeTab ? typeTab.name : 'без категории';  
  };
  
  const getCategoryCount=(typeTab)=>{
    let count=0;
    try{
      let category=+(typeTab.id);
      if (! props.countByCategory?.hasOwnProperty(category)) {
        return '';
      }
      count=props.countByCategory[category];
      if (count===0) {
        return '';
      }
    } catch (e) {
      console.log(e);
    }
    return `Всего: ${count} шт.`;  
  };


  const doSort=(arrayToSort, sortOrderParam, keySort = 'name')=>{
    if (keySort === 'name'){
      if (sortOrderParam==='asc') {
        arrayToSort.sort((a,b)=>a[keySort].toLowerCase().localeCompare(b[keySort].toLowerCase()));
      } else if (sortOrderParam === 'desc') {
        arrayToSort.sort((a,b)=>b[keySort].toLowerCase().localeCompare(a[keySort].toLowerCase()));
      }
    }
    if (keySort === 'quantity'){
      if (sortOrderParam==='asc') {
        arrayToSort.sort((a,b)=>+a[keySort] - (+b[keySort]));
      } else if (sortOrderParam === 'desc') {
        arrayToSort.sort((a,b)=>+b[keySort] - (+a[keySort]));
      }
    }
    return(arrayToSort);
  };

  const handleSortButton = (event) =>{
    setQuantitySortOrder('');
    if (sortOrder==='asc') {
      setSortOrder('desc');
    } else {
      setSortOrder('asc');
    }
  };

  const handleQuantitySortButton = (event) =>{
    setSortOrder('');
    if (sortQuantityOrder==='asc') {
      setQuantitySortOrder('desc');
    } else {
      setQuantitySortOrder('asc');
    }
  };

  const getSortIcon = (button) => {
    if (button === 'sortOrder'){
      if (sortOrder === 'asc') {
        return <ArrowDropUpIcon />;
      } else if (sortOrder === 'desc') {
        return <ArrowDropDownIcon />;
      }
    }
    if (button === 'sortQuantityOrder'){
      if (sortQuantityOrder === 'asc') {
        return <ArrowDropUpIcon />;
      } else if (sortQuantityOrder === 'desc') {
        return <ArrowDropDownIcon />;
      }
    }
    return (
      <React.Fragment> 
        <ArrowDropUpIcon /> 
        <ArrowDropDownIcon /> 
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <TableRow 
        sx={{ '& > *': { borderBottom: 'unset', cursor: 'pointer' , backgroundColor: `${props.typeTab ? props.typeTab.color : '#eee'}`} }}
      >
        <TableCell sx={stylesObj.TableCellMinPadding}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={setClose}
          >
            {props.isOpen ? <KeyboardArrowRightIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell 
          sx={stylesObj.TableCellMinPadding} 
          component="th" scope="row"
          onClick={setOpen}>
          <Typography variant="h6" gutterBottom component="div">
            {getCategoryName(props.typeTab)}
          </Typography>
        </TableCell>
        <TableCell>
          {getCategoryCount(props.typeTab)}
        </TableCell>

      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0, backgroundColor: props.typeTab?.color }} colSpan={6}>
          <Collapse in={props.isOpen} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Button
                        variant='contained'
                        onClick={handleSortButton}
                        endIcon={getSortIcon('sortOrder')}
                      >
                        товар
                      </Button>
                    </TableCell>
                    <TableCell align="right">
                      <Button
                        variant='contained'
                        onClick={handleQuantitySortButton}
                        endIcon={getSortIcon('sortQuantityOrder')}
                      >
                        количество
                      </Button>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {positionsInsideType.map((item,index)=>{
                    if (props.isHideEmpty && +item.quantity === 0){
                      return('');
                    }else{
                      return(
                        <Row
                          key={index}
                          item={item}
                          onChangeQuantity={onChangeQuantity}
                          onChangeComment={onChangeComment}
                          backgroundColor={props.typeTab?.color}
                          isDownloadWaiting={props.isDownloadWaiting}
                          invoice={props.invoice}
                          // sortOrder={sortOrder}
                        />);
                    }
                  }
                  )}
                </TableBody>
            
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default TypesGroupsSendingProduct;