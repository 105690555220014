import React, {useState, useContext, useEffect} from 'react';
import { Context } from '../../functions/context';
import NewKitForm from './NewKitForm';
import KitTable from './KitTable';
 
const Kits = () => {
  const context = useContext(Context);

  useEffect(()=>{
    context.axiGetAllBase();
  },[]);
 

  return(
    <div>
    
      <div>
        <h4 style={{marginTop: '-10px'}}>
          Введите наименование, тип и точку, чтобы добавить новый набор быстрого создания накладных на отпрвку
        </h4>
        
        <NewKitForm/>

        <KitTable/>
      </div>
      
    </div>
  );
};


export { Kits };