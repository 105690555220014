import React, { Fragment, useContext, useEffect, useReducer, useState } from 'react';
import {stylesObj} from '../../../stylesObj/stylesObj';

import { Context } from '../../../functions/context';
import { Checkbox, FormControlLabel } from '@mui/material';
import OneSignalReact from 'react-onesignal';
import subscribeSettingsTogglesFields from './subscribeSettingsTogglesFields';
import PushSettings from './PushSettings';
import EmailSettings from './EmailSettings';


const SubscribeSettings = () => {

  const context = useContext(Context);
  
  return(
    <div style={stylesObj.simpleRowWrap}>
      <PushSettings
      />
      <EmailSettings
      />
    </div>
  );
};

export default SubscribeSettings;