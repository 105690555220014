import React from 'react';

import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';

import IconButton from '@mui/material/IconButton';

import ListItemIcon from '@mui/material/ListItemIcon';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import ReceiptIcon from '@mui/icons-material/Receipt';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import CategoryIcon from '@mui/icons-material/Category';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import PeopleIcon from '@mui/icons-material/People';
import DeveloperBoardIcon from '@mui/icons-material/DeveloperBoard';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import SettingsIcon from '@mui/icons-material/Settings';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import LineAxisIcon from '@mui/icons-material/LineAxis';
import WidgetsIcon from '@mui/icons-material/Widgets';
import AlarmOnIcon from '@mui/icons-material/AlarmOn';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import Battery0BarIcon from '@mui/icons-material/Battery0Bar';

import styles from './index.module.css';

import {
  Routes,
  Route,
  Link,
} from 'react-router-dom';

import { SendingProduct } from '../../Pages/SendingProduct/index';
import { Nomenclature } from '../../Pages/Nomenclature/index';
import { Categories } from '../../Pages/Categories/index';
import { Points } from '../../Pages/Points/index';
import { Icon, ListItemText } from '@mui/material';


import { ExitUserModal } from './../authorization/ExitUserModal';
import { Users } from '../../Pages/Users';
import { Kits } from '../../Pages/Kits';
import { EditKit } from '../../Pages/EditKit';
import Settings from '../../Pages/Settings';
import { Invoices } from '../../Pages/Invoices';
import { Checks } from '../../Pages/Checks';
import { Motivation } from '../../Pages/Motivation';
import { MotivationDashboard } from '../../Pages/MotivationDashboard';
import { WorkSchedules } from '../../Pages/WorkSchedules';
import { ControlStartSelling } from '../../Pages/ControlStartSelling';
import { ControlNomenclatureEnded } from '../../Pages/ControlNomenclatureEnded';

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

const DrawerPage = () => {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const [headerText, setHeaderText] = React.useState('Панель администратора пироговых точек');

  let pageResource = [ 
    {
      name: 'Отправка товара', 
      link: 'sending', 
      iconComponent: 
        <LocalShippingIcon/>
    },{
      name: 'История накладных', 
      link: 'invoices', 
      iconComponent: 
        <ReceiptIcon/>
    },{
      name: 'Номенклатура', 
      link: 'nomenclature', 
      iconComponent: 
        <FormatListBulletedIcon/>
    },{name: 'Категории', 
      link: 'categories', 
      iconComponent: 
        <CategoryIcon/> 
    },{
      name: 'Точки', 
      link: 'points', 
      iconComponent: 
        <PointOfSaleIcon/>
    },{
      name: 'Чеки', 
      link: 'checks', 
      iconComponent: 
        <ReceiptLongIcon/>
    },{
      name: 'Пользователи', 
      link: 'users', 
      iconComponent: 
        <PeopleIcon/>
    },{
      name: 'Быстрые наборы', 
      link: 'kits', 
      iconComponent: 
        <WidgetsIcon/>
    },{
      name: 'Конфигурации быстрых наборов', 
      link: 'edit-kit', 
      iconComponent: 
        <DeveloperBoardIcon/>
    },{
      name: 'Мотивациионные выплаты', 
      link: 'motivationDashboard', 
      iconComponent: 
        <LineAxisIcon/>
    },{
      name: 'Настройка мотивации продаж', 
      link: 'motivation', 
      iconComponent: 
        <AddShoppingCartIcon/>
    },{
      name: 'Режим работы на точках', 
      link: 'workSchedules', 
      iconComponent: 
        <CalendarMonthIcon/>
    },{
      name: 'Контроль своевременности приёмки', 
      link: 'controlStartSelling', 
      iconComponent: 
        <AlarmOnIcon/>
    },{
      name: 'Контроль наличия товара', 
      link: 'ControlNomenclatureEnded', 
      iconComponent: 
        <Battery0BarIcon/>
    },{
      name: 'Настройки', 
      link: 'settings', 
      iconComponent: 
        <SettingsIcon/>
    }];

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  return(
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            {headerText}
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader sx={{justifyContent: 'space-around'}}>
          { open ? <ExitUserModal />
            : '' }
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {pageResource.map((elem, index) => (

            <Link 
              to={elem.link} 
              key={index} 
              style={{textDecoration: 'none'}} 
              onClick={() => {
                setHeaderText(elem.name);
                handleChange(index);
              }}>

              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    <Icon
                      color = {value===index ? 'secondary' : 'primary'}
                    >
                      {elem.iconComponent}
                    </Icon>
                  </ListItemIcon>
                  {open ?
                    <span 
                      className={index === value ? styles.changed : styles.notChanged}>
                      {elem.name}
                    </span>
                    : null
                  }
                </ListItemButton>
              </ListItem>
            </Link>
                    
          ))}
        </List>
      </Drawer>
      <Box
        component="main"
        sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}
      >
        <Toolbar />
        <Routes>
          <Route path={'sending'} element={<SendingProduct />} />
          <Route path={'invoices'} element={<Invoices />} />
          <Route path={'nomenclature'} element={<Nomenclature />} />
          <Route path={'categories'} element={<Categories />} />
          <Route path={'points'} element={<Points />} />
          <Route path={'checks'} element={<Checks />} />
          <Route path={'users'} element={<Users />} /> 
          <Route path={'kits'} element={<Kits />} />
          <Route path={'edit-kit'} element={<EditKit />} />
          <Route path={'motivationDashboard'} element={<MotivationDashboard />} />
          <Route path={'motivation'} element={<Motivation />} />
          <Route path={'workSchedules'} element={<WorkSchedules />} />
          <Route path={'controlStartSelling'} element={<ControlStartSelling />} />
          <Route path={'ControlNomenclatureEnded'} element={<ControlNomenclatureEnded />} />
          <Route path={'settings'} element={<Settings />} />
        </Routes>
      </Box>
    </Box>
          
  );
};


export { DrawerPage };