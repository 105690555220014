import React, { Fragment, useContext, useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ruLocale from 'date-fns/locale/ru';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import Paper from '@mui/material/Paper';

import axi from './../../functions/axiosf';

import style from './index.module.css';
import {stylesObj} from './../../stylesObj/stylesObj';

import TypesGroupsEditKit from './TypesGroupsEditKit';
import ApprovalKitModal from './ApprovalKitModal';

import { Context } from '../../functions/context';
import kitsTypes from '../../resources/kitsTypes';
import daysOfWeek from '../../resources/daysOfWeek';
import moment from 'moment';
import { DatePicker, MobileDatePicker } from '@mui/x-date-pickers';
import TextField from '@mui/material/TextField';
import { PickersCalendarHeader } from '@mui/x-date-pickers/internals';
import DayOfMonthPicker from '../../Components/DatePickers/DayOfMonthPicker';
import DayOfWeekPicker from '../../Components/DatePickers/DayOfWeekPicker';
import DayOfYearPicker from '../../Components/DatePickers/DayOfYearPicker';

const EditKit = () => {
  const context = useContext(Context);
  const [point, setPoint] = useState(0);
  const [filterKits, setFilterKits] = useState([...context.kits]);
  const [kit, setKit] = useState(0);
  const [kitObj, setKitObj] = useState({});

  const [invoice, setInvoice] = useState([]);
  const [cleanInvoice, setCleanInvoice] = useState([]);
  const [openedTabIndex, setOpenedTabIndex] = useState(null);

  const [dayOfWeek, setDayOfWeek] = useState(moment().format('E'));
  const handleChangeDayOfWeek = (event) => {
    setDayOfWeek(event?.target?.value);
  };

  const [dayOfMonth, setDayOfMonth] = useState(moment());
  const handleChangeDayOfMonth = (event) => {
    console.log(+event);
    setDayOfMonth(+event);
  };

  const [dayOfYear, setDayOfYear] = useState(moment());
  const handleChangeDayOfYear = (event) => {
    console.log(+event);
    setDayOfYear(+event);
  };


  const [startDay, setStartDay] = useState(moment());
  const handleChangeStartDay = (event) => {
    setStartDay(+event);
  };


  const [arbitraryPeriod, setArbitraryPeriod] = useState(14);
  const handleChangeArbitraryPeriod = (event) => {
    setArbitraryPeriod(event?.target?.value);
  };


  useEffect(()=>{
    let newKitsObj = {};
    context.kits.map((kit)=>{
      newKitsObj[kit.id] = kit;
    });
    setKitObj(newKitsObj);
  },[context.kits]);

  const handleChangePoint = (event) => {
    let newPointId = event?.target?.value;
    setPoint(newPointId);
    let newFilterKits = [];
    if (newPointId){
      newFilterKits = context.kits.filter(kit=>kit.point === newPointId);
    }else{
      newFilterKits = [...context.kits];
    }
    setFilterKits(newFilterKits);
    setKit(0);
  };

  const handleChangeKit = (event) => {
    let newKit = event?.target?.value;
    setKit(newKit);
    onReadCurrentKit(newKit);

    setDayOfWeek(kitObj[+newKit]?.dayOfWeek);

    let varToSetDayOfMonth = +moment('08-'+kitObj[+newKit]?.dayOfMonth+'-2022', 'MM-DD-YYYY');
    setDayOfMonth(varToSetDayOfMonth);

    const dayOfYearBase = +kitObj[+newKit]?.dayOfYear;
    let varToSetDayOfYear = (+dayOfYearBase>0 && +dayOfYearBase<367) ? +moment(dayOfYearBase+'-2022', 'DDD-YYYY') : +moment();
    setDayOfYear(varToSetDayOfYear);

    setStartDay(+kitObj[+newKit]?.startDay*1000); // мы использовали один стейт
    setArbitraryPeriod(+kitObj[+newKit]?.arbitraryPeriod);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isWaiting, setIsWaiting] = useState(false);

  useEffect(()=>{
    context.axiGetAllBase();
  },[]);

  const onChangeQuantity = (id, value) => {
    if (value >= 0){
      let newInvoice = [...invoice];
      let isNew = true;
      newInvoice.map((invoiceItem, index) => {
        if(invoiceItem.id===id){
          isNew = false;
          newInvoice[index].quantity = value;
        }
      });
      if (isNew) {
        context.nomenclature.map((item, index) => {
          if (item.id === id){
            let newItem = {...item};
            newItem.quantity = value;
            newInvoice = [...newInvoice, newItem];
          }
        });
      }
      setInvoice(newInvoice);
    }
  };

  const handlePressSend = async () => {
    let postInvoice = [];
    invoice.map((item, index)=>{
      if(item.quantity>0){
        postInvoice=[...postInvoice, item];
      }
    });
    setCleanInvoice(postInvoice);
    setIsModalOpen(true);
  };

  const onReadCurrentKit = async (currentKit) => {
    setIsWaiting(true);
    let result = await axi(
      'admin/readCustomKitInvoice.php', 
      '', 
      { 
        token: context.token, 
        kit: currentKit,
      });
    if (result.type === 'no_authorized') {
      if(typeof context.userExit === 'function'){
        context.userExit();
      }
    } else {
      if (result.status === 'ok'){
        let newCleanInvoice = [];
        result.cleanInvoice.map((kitItem)=>{
          let itemRow = context.nomenclature.filter(
            (nomenclatureItem)=> +nomenclatureItem.id === +kitItem.product
          );
          let rowCleanInvoice = itemRow[0];
          rowCleanInvoice.quantity = kitItem.quantity;
          newCleanInvoice = [...newCleanInvoice, rowCleanInvoice];
        });
        setInvoice(newCleanInvoice);
        setCleanInvoice(newCleanInvoice);
        setIsModalOpen(false);
      }
    }
    setIsWaiting(false);
  };

  const onPressConfirmSend = async ( ) => {
    setIsWaiting(true);
    let result = await axi(
      'admin/setCustomKitInvoice.php', 
      '', 
      { 
        token: context.token, 
        point: point, 
        kit: kit,
        invoice: cleanInvoice,
      });
    if (result.type === 'no_authorized') {
      if(typeof context.userExit === 'function'){
        context.userExit();
      }
    } else {
      if (result.status === 'ok'){
        let emptyArray = [];
        setInvoice(emptyArray);
        setCleanInvoice(emptyArray);
        setIsModalOpen(false);
        setPoint(0);
        setKit(0);
        if (typeof context.setAllBase === 'function'){ 
          context.setAllBase(result);
        }
      }
    }
    setIsWaiting(false);
  };

  const onPressSaveParamForAutoKit = async () => {
    let kitType = kitObj[+kit]?.type;
    setIsWaiting(true);
    let result = await axi(
      'admin/updateAutoKit.php', 
      '', 
      { 
        token: context.token, 
        type: kitType,
        id: kit,
        dayOfWeek: dayOfWeek,
        dayOfMonth: moment(dayOfMonth).format('DD'),
        dayOfYear: moment(dayOfYear).format('DDD'),
        startDay: Math.round( +startDay/1000 ),
        arbitraryPeriod: arbitraryPeriod,
      });
    if (result.type === 'no_authorized') {
      if(typeof context.userExit === 'function'){
        context.userExit();
      }
    } else {
      if (result.status === 'ok'){
        let emptyArray = [];
        setInvoice(emptyArray);
        setCleanInvoice(emptyArray);
        setIsModalOpen(false);
        setPoint(0);
        setKit(0);
        if (typeof context.setAllBase === 'function'){ 
          context.setAllBase(result);
        }
      }
    }
    setIsWaiting(false);
  };

  return(
    <div className={style.verticalPage}>
      <ApprovalKitModal
        isOpen = {isModalOpen}
        onSetIsModalOpen={setIsModalOpen}
        cleanInvoice={cleanInvoice}
        onPressConfirmSend={onPressConfirmSend}
        isWaiting={isWaiting}
      />
      <div 
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          width: '100%'
        }}
      >
        <FormControl style={{flex: 1, minWidth: '200px', margin: 16}}>
          <InputLabel id="demo-simple-select-label">Адрес назначения</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={point}
            label="Адрес назначения"
            onChange={handleChangePoint}
          >
            <MenuItem 
              key={0} 
              value={0}>
              Все
            </MenuItem>
            {context.salePoints.map((item, index)=>
              <MenuItem 
                key={index} 
                value={item.id}>
                {item.name}
              </MenuItem>)}
          </Select>
        </FormControl>

        <FormControl style={{flex: 1 , minWidth: '200px', margin: 16}}>
          <InputLabel id="demo-simple-select-label">Набор</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={kit}
            label="Набор"
            onChange={handleChangeKit}
          >
            {filterKits.map((item, index)=>
              <MenuItem 
                key={index} 
                value={item.id}
                divider>
                <div
                  style={{
                    width: '100%',
                    //maxWidth: 400,
                    display: 'flex',
                    flexDirection: 'column',
                    alignSelf: 'end',
                  }}
                >
                  <span>{item.name}</span>
                  <span 
                    style={{
                      color: '#aaa',
                      alignSelf: 'end',
                    }}
                  >{kitsTypes[item.type]}</span>
                </div>
                
              </MenuItem>)}
          </Select>
        </FormControl>
      </div>





      {(kitObj[+kit]?.type === 'custom')?
        <Fragment>
          <div className={style.MenuTwoTable} style={{margin: 0, ...stylesObj.TableContainer}}>
            <TableContainer className={style.Paper} component={Paper} >
              <Table aria-label="collapsible table" className={style.Paperbody} style={stylesObj.TableContainer}>
                <TableHead>
                  <TableRow className={style.Paperhead}>
                    <TableCell />
                    <TableCell>Категории товаров</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody style={stylesObj.TableContainer}>
                  {(typeof context.types === 'object') ? context.types.map((item, index) => {
                  
                    return (
                      <TypesGroupsEditKit 
                        key={index} 
                        index={index}
                        invoice={invoice}
                        typeTab={item}
                        onChangeQuantity={onChangeQuantity} 
                        isOpen={index===openedTabIndex}
                        onSetOpenedTabIndex={setOpenedTabIndex}
                      />
                    );
                  })
                    : 
                    null
                
                  }
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          {invoice.length ?
            <LoadingButton 
              variant="contained" 
              endIcon={<SendIcon/>}  
              onClick={handlePressSend}
              disabled={isWaiting}
              loading={isWaiting}
              loadingPosition="end"
              sx={stylesObj.SendRemainsButton}
            > 
              сохранить набор
            </LoadingButton>
            : <></>
          }
        </Fragment>
        :
       
        <div style={{display: 'flex'}}>
          {kit ? 
            <LocalizationProvider 
              dateAdapter={AdapterDateFns}
              adapterLocale={ruLocale}
            >
              <DatePicker
                label="день отсчёта"
                value={startDay}
                onChange={handleChangeStartDay}
                renderInput={(params) => 
                  <TextField 
                    {...params} 
                    sx={{m: 1, width: '160px'}}
                  />}
                disabled = {isWaiting}
              />
            </LocalizationProvider> :
            null
          }
          {(kitObj[+kit]?.type === 'week')?
            
              
            <DayOfWeekPicker
              dayOfWeek = {dayOfWeek}
              onChangeDayOfWeek = {handleChangeDayOfWeek}
              isWaiting = {isWaiting}
            />
            :
            null
          }
          {(kitObj[+kit]?.type === 'month')?
            
            <DayOfMonthPicker
              dayOfMonth = {dayOfMonth}
              onChangeDayOfMonth = {handleChangeDayOfMonth}
              isWaiting = {isWaiting}
            />
            :null
          }
          {(kitObj[+kit]?.type === 'year')?
            <DayOfYearPicker
              dayOfYear = {dayOfYear}
              onChangeDayOfYear = {handleChangeDayOfYear}
              isWaiting = {isWaiting}
            />
            :null
          }
          {(kitObj[+kit]?.type === 'arbitrary')? 
          

            <FormControl sx={{
              m: 1, 
              width:'120px', 
              transition: '1s',
            }} 
            variant="outlined">
              <TextField
                label="период в днях"
                type="number"
                variant="outlined"
                id="standard-size-small"
                defaultValue={arbitraryPeriod}
                onChange={handleChangeArbitraryPeriod}
                value={arbitraryPeriod}
                disabled = {isWaiting}
                //error={nameError}
                //helperText={nameError}
              />
            </FormControl>
            
            :null
          }
          {kit ? 
            <LoadingButton 
              variant="contained"
              color="success"
              disabled={isWaiting}
              loading={isWaiting}
              loadingPosition="end"
              endIcon={<SaveIcon/>}
              onClick={onPressSaveParamForAutoKit}
              sx={{m:2}}
            >
                сохранить
            </LoadingButton>

            :null
          }
        </div>
      }
    </div>
  );
};


export { EditKit };