/* eslint-disable eqeqeq */
import * as React from 'react';
import TextField from '@mui/material/TextField';
import { Context } from '../../../functions/context';
import LoadingButton from '@mui/lab/LoadingButton';
import AddIcon from '@mui/icons-material/Add';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import ruLocale from 'date-fns/locale/ru';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import TradePeriodItem from '../../Categories/CategoriesTable/CategoryUpdateModal/TradePeriodItem/index'; //'./TradePeriodItem';
import axi from '../../../functions/axiosf';
import { Card, CircularProgress } from '@mui/material';
import cardStyle from './cardStyle';
import dayjs from 'dayjs';

export default function TradePeriodsAndRulesAccordion(props) {
  const context = React.useContext(Context);
  const [isEdited, setIsEdited] = React.useState(false);
  const [newTimeStart, setNewTimeStart] = React.useState(480);
  const [newTimeEnd, setNewTimeEnd] = React.useState(1200);
  const [tradePeriods, setTradePeriods] = React.useState([]);
  const [speedRules, setSpeedRules] = React.useState([]);
  const [isWaiting, setIsWaiting] = React.useState(false);

  const handleSetNewTimeStart =(event)=>{
    setNewTimeStart((event.$H*60)+event.$m);
  };
  
  const handleSetNewTimeEnd =(event)=>{
    //setNewTimeEnd((+event.$d.getUTCHours()*60)+ (+event.$d.getUTCMinutes())); //с этим дейтпикером перематывает время.
    setNewTimeEnd((event.$H*60)+event.$m);
  };

  React.useEffect(()=>{
    axiReadTradePeriods(0);
  }, []);
  
  const axiReadTradePeriods = async (productsTypeId) => {
    setIsWaiting(true);
    let data = { 
      token: context?.token, 
      productsTypeId: productsTypeId,
    };
    let result = await axi('admin/getTradePeriods.php', '', data);
    if (result.status === 'ok'){
      setTradePeriods(result.tradePeriods);
      setSpeedRules(result.speedRules);
    }
    setIsWaiting(false);
  };

  const axiAddNewTradePeriod = async() =>{
    setIsWaiting(true);
    let data = { 
      token: context?.token, 
      productsTypeId: 0,
      newTimeStart: newTimeStart,
      newTimeEnd: newTimeEnd,

    };
    let result = await axi('admin/addNewTradePeriod.php', '', data);
    if (result.status === 'ok'){
      setTradePeriods(result.tradePeriods);
      setSpeedRules(result.speedRules);
    }
    setIsWaiting(false);
  };

  const axiUpdateTradePeriod = async(tradePeriodId, updStart, updEnd) =>{
    setIsWaiting(true);
    let data = { 
      token: context?.token, 
      productsTypeId: 0,
      tradePeriodId: tradePeriodId,
      updStart: updStart,
      updEnd: updEnd,

    };
    let result = await axi('admin/updateTradePeriod.php', '', data);
    if (result.status === 'ok'){
      setTradePeriods(result.tradePeriods);
      setSpeedRules(result.speedRules);
    }
    setIsWaiting(false);
    return true;
  };

  const axiAddNewIncreaseRule = async(tradePeriodId, newRuleType, newDuration, newIncrease)=>{
    setIsWaiting(true);
    let data = { 
      token: context?.token, 
      productsTypeId: 0,
      tradePeriodId: tradePeriodId,
      newRuleType: newRuleType,
      newDuration: newDuration,
      newIncrease: newIncrease,
    };
    let result = await axi('admin/addNewIncreaseRule.php', '', data);
    if (result.status === 'ok'){
      setTradePeriods(result.tradePeriods);
      setSpeedRules(result.speedRules);
    }
    setIsWaiting(false);
    return true;
  };

  const axiUpdateIncreaseRule = async(speedRuleId, newDuration, newIncrease)=>{
    setIsWaiting(true);
    let data = { 
      token: context?.token, 
      productsTypeId: 0,
      speedRuleId: speedRuleId,
      newDuration: newDuration,
      newIncrease: newIncrease,
    };
    let result = await axi('admin/updateIncreaseRule.php', '', data);
    if (result.status === 'ok'){
      setTradePeriods(result.tradePeriods);
      setSpeedRules(result.speedRules);
    }
    setIsWaiting(false);
    return true;
  };


  return(
           
    <Card sx={cardStyle}>
      <h4>настройки периодов скорости продаж</h4>
      {!!tradePeriods ? 
        <React.Fragment>
          {tradePeriods.map(
            (period, periodIndex)=>{
                    
              let minAvailableMinutes = 0;
              let maxAvailableMinutes = 1439;
              let i = 0;
              while(!!tradePeriods[i]){
                if(+tradePeriods[i].id !== +period.id){
                  if(+tradePeriods[i].to < +period.from && +tradePeriods[i].to > +minAvailableMinutes){
                    minAvailableMinutes = +tradePeriods[i].to;
                  }
                  if(+tradePeriods[i].from > +period.to && +tradePeriods[i].from < +maxAvailableMinutes){
                    maxAvailableMinutes = +tradePeriods[i].from;
                  }
                }
                i++;
              }
                      
              return (
                <TradePeriodItem
                  period={period}
                  key={periodIndex}
                  speedRules={speedRules.filter(rule => rule.tradePeriodId == period.id)}
                  minAvailableMinutes = {minAvailableMinutes}
                  maxAvailableMinutes = {maxAvailableMinutes}
                  onAddNewIncreaseRule = {axiAddNewIncreaseRule}
                  axiUpdateTradePeriod = {axiUpdateTradePeriod}
                  axiUpdateIncreaseRule = {axiUpdateIncreaseRule}
                />
              ); 
                      
            }
          )}
          <p>новый период</p>
          <div
            style={{display: 'flex'}}
          >
            <LocalizationProvider 
              dateAdapter={AdapterDayjs}
              adapterLocale={ruLocale}
            >
              <TimePicker
                label="начало"
                value={(newTimeStart + new Date().getTimezoneOffset()) *60*1000}
                onChange={handleSetNewTimeStart}
                renderInput={(params) => <TextField {...params} sx={{m:1, width: '120px'}}/>}
                disableIgnoringDatePartForTimeValidation
                maxTime = {dayjs((newTimeEnd + new Date().getTimezoneOffset()) *60*1000)}
              />
            </LocalizationProvider>
            <LocalizationProvider 
              dateAdapter={AdapterDayjs}
              adapterLocale={ruLocale}
            >
              <TimePicker
                label="окончание"
                value={(newTimeEnd + new Date().getTimezoneOffset()) *60*1000}
                onChange={handleSetNewTimeEnd}
                renderInput={(params) => <TextField {...params} sx={{m:1, width: '120px'}}/>}
                disableIgnoringDatePartForTimeValidation
                minTime = {dayjs((newTimeStart + +new Date().getTimezoneOffset()) *60*1000)}
              />
            </LocalizationProvider>
          </div>
          {
            newTimeStart && newTimeEnd ?
              <LoadingButton 
                variant="contained"
                color="success"
                disabled={isWaiting}
                loading={isWaiting}
                loadingPosition="end"
                endIcon={<AddIcon/>}
                onClick={axiAddNewTradePeriod}
              >
                  добавить период
              </LoadingButton>
              : 
              null
          }

        </React.Fragment>
        : 
        <CircularProgress
          color="success" 
          style={{
            margin: '8px', 
            width: '100px',
            height: '100px'
          }} 
        />
      }
    </Card>

  );
}