/* eslint-disable eqeqeq */
import React, { useContext, useEffect, useState, useRef, Fragment } from 'react';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import PrintIcon from '@mui/icons-material/Print';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import Paper from '@mui/material/Paper';

import axi from '../../../functions/axiosf';

import {stylesObj} from '../../../stylesObj/stylesObj';

import { Context } from '../../../functions/context';
import { ButtonBase, CircularProgress } from '@mui/material';
import moment from 'moment';
import checksColors from '../checksColors';
import { Check } from '../Check';
import getSumWithFixed2 from '../../../functions/getSumWithFixed2';
import getFullTimeString from '../../../functions/getFullTimeString';
import isResultObjectOk from '../../../functions/isResultObjectOk';
import styles from '../index.module.css';
import propsF from '../../../functions/propsF';


const ChecksList = ({
  allChecks,
  isStartWaiting,
  
  setHeadTotalCount,
  setHeadTotalSum,

  showZeroChecks,
  point,
  kkt,
  category,
  timeFromTo,
  nomenclature,

  onSetIsScroll

}) => {
  let totalSum = 0;
  let totalCount = 0;

  useEffect(() => {
    // console.log(totalCount,totalSum);
    propsF(setHeadTotalCount,totalCount);
    propsF(setHeadTotalSum,totalSum);
  });

  const isCheckPassedFilter=(checkItem)=>{
    // console.log(timeFromTo[0],checkItem.timeH,timeFromTo[1]);
    return (
      (checkItem.total!==0
        || showZeroChecks===true)
      && (point === 0 
        || checkItem.salePointId == point) 
      && (kkt === 0 
        || checkItem.kktRegNumber == kkt) 
      && (category === 0 
        || checkItem.products.find((product) => 
          product.category === category) !== undefined) 
      && timeFromTo[0] <= checkItem.timeH 
      && checkItem.timeH < timeFromTo[1]
      && ((nomenclature===null) 
        || (nomenclature.id === 0) 
        || checkItem.products.find((product) => 
          product.id === nomenclature.id) !== undefined)
    );
  };

 
  if (isStartWaiting) {
    return <CircularProgress size={56} />;
  } 

  if (typeof allChecks !== 'object' || allChecks.length === 0) {
    return <></>;
  }



  return(
    <div style={{ flex: 1, overflowY: 'auto' }}>
      {allChecks.map((checkItem, checkIndex) => {
        if (isCheckPassedFilter(checkItem)) {
          totalSum += checkItem.total;
          totalCount += 1;
          return (
            <Check
              key={checkIndex}
              checkN={checkIndex+1}
              check={checkItem}
              nomenclature={nomenclature}
              category={category}
              onSetIsScroll={onSetIsScroll}
            />
          );
        } else {
          return null;
        }
      })}
    </div>
  );
};


export { ChecksList };
