const hourMinFromMinutes = (sec) => {
  //let string = parseInt(+sec/60)+':'+(+sec%60);

  let h = parseInt(+sec/60);
  let strH = h + '';
  if (strH.length === 1){
    strH = '0'+strH;
  }

  let m = +sec%60;
  let strM = m + '';
  if (strM.length === 1){
    strM = '0'+strM;
  }

  let string = strH+':'+strM;
  return (string);
};

export default hourMinFromMinutes;