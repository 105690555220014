import * as React from 'react';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ruLocale from 'date-fns/locale/ru';
import moment from 'moment';
import { DatePicker, MobileDatePicker } from '@mui/x-date-pickers';
import TextField from '@mui/material/TextField';

import { Context } from '../../functions/context';
import kitsTypes from '../../resources/kitsTypes';
import daysOfWeek from '../../resources/daysOfWeek';
import propsF from '../../functions/propsF';


export default function DayOfYearPicker(props) {
  const context = React.useContext(Context);

  const handleChangeDayOfYear = (e) => {
    propsF(props.onChangeDayOfYear, e);
  };
  
  return(    
    <LocalizationProvider 
      dateAdapter={AdapterDateFns}
      adapterLocale={ruLocale}
    >
      <DatePicker
        label="день года"
        value={props.dayOfYear}
        onChange={handleChangeDayOfYear}
        renderInput={(params) => 
          <TextField 
            {...params} 
            sx={{m:1, width: '100px'}}
          />}
        disabled = {props.isWaiting}
        views={['day']}
        inputFormat="DDD"
      />
    </LocalizationProvider>
  );     
};
