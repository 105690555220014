import React, {useState, useContext} from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import CategoryRow from './CategoryRow';
import CategoryUpdateModal from './CategoryUpdateModal';
import { Context } from '../../../functions/context';
import axi from '../../../functions/axiosf';
import { stylesObj } from '../../../stylesObj/stylesObj';



export default function CategoriesTable(props) {
  const context = useContext(Context);
  
  const [isOpenModal, setIsOpenModal] = useState(false);
  
  const [isWaiting, setIsWaiting] = useState(false);

  
  const onUpdateCategory = async(id, newName, newColor) => {
    setIsWaiting(true);
    try {
      let result = await axi(
        'admin/updateProductsType.php', 
        '', 
        { 
          token: context.token, 
          newTypeName: newName,
          newTypeColor: newColor,
          typeId: id
        }); 
      if (typeof context.setAllBase === 'function'){ 
        context.setAllBase(result);
      }
    } catch (e) {
      console.log(e);
    }
    setIsWaiting(false);
    setIsOpenModal(false);
  };


  return(
    <div style={{display:'flex'}}>
      {typeof isOpenModal === 'number' ?
        <CategoryUpdateModal
          indexOfType={isOpenModal}
          onUpdateCategory={onUpdateCategory}
          isOpenModal={typeof isOpenModal === 'number'}
          onOpenModal={setIsOpenModal}
        /> 
        :
        null
      }
      <TableContainer 
        component={Paper} 
        sx={{margin: 0}}>
        <Table aria-label="caption table"
          sx={stylesObj.TableContainer}>
          <TableBody>
            {context.types.length ?
              context.types.map((category, index) => (
                <CategoryRow
                  key = {category.id}
                  index = {index}
                  category = {category}
                  isWaiting = {isWaiting}  
                  onOpenModal={setIsOpenModal}
                />
              ))
              :
              null
            }
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
