import React, { useContext, Fragment } from 'react';
import { Checkbox, FormControlLabel, TextField, Divider, Paper, CardContent, Card } from '@mui/material';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { stylesObj } from '../../../stylesObj/stylesObj';
import { Context } from '../../../functions/context';

import moment from 'moment';
import ruLocale from 'date-fns/locale/ru';

import propsF from '../../../functions/propsF';


const Row = (props) => {
  const context = useContext(Context);

  const handleToggle = () => {
    propsF(props.onToggleDate, +props.item.date);
  };

  return (                
    <Card
      sx={{margin: 1}}
    >
      <FormControlLabel 
        control={
          <Checkbox 
            checked = {!!(+props.item.isActive)}
            onChange={handleToggle}
            disabled = {!!props.isWaiting} 
          />
        } 
        label = {<>
          <span>{`${moment(+props.item.date*1000).format('ll')}`}</span><br/>
          <span>{`продано ${+props.item.saleSum} единиц на ${+props.item.rubSum} руб.`}</span><br/>
          <span>{`${+props.item.currentProducts} единиц использовано номенклатуры`}</span>
        </>}
        sx={{
          minWidth: 280,
          padding: 1
        }}
      />
    </Card> 
  );
};

export default Row;