import * as React from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';

import Button from '@mui/material/Button';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { Context } from '../../../functions/context';
import kitsTypes from '../../../resources/kitsTypes';


export default function KitRow(props) {
  const context = React.useContext(Context);
  
  const handlePressUpdate = () => {
    if (typeof props.onOpenModal === 'function'){
      props.onOpenModal(props.index);
    }
  };

  return(
    <TableRow hover >
      <TableCell component="th" scope="row">
        {props.item.name}
      </TableCell>
      <TableCell component="th" scope="row">
        {kitsTypes[props.item.type]}
      </TableCell>
      <TableCell component="th" scope="row">
        {props.point}
      </TableCell>
        
      <TableCell align="right">
        <div style={{display:'flex', justifyContent:'flex-end'}}>
          {/* я думаю, что кнопка для удаления не нужна совсем
                    <IconButton onClick={() => handleRemoveCategory(item.id)} style={{color: 'black'}}>
                      <ClearIcon />
                    </IconButton>
                     */}
          <IconButton 
            variant="contained" 
            onClick={handlePressUpdate}>
            <BorderColorIcon/>
          </IconButton>
        </div>
      </TableCell>
    </TableRow>     
  );     
};
