import React, {useState, useContext, useEffect} from 'react';
import Table from '@mui/material/Table';
import {TableBody, TableHead, TableRow, TableCell} from '@mui/material';
import TableContainer from '@mui/material/TableContainer';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KitRow from './KitRow';
import KitUpdateModal from './KitUpdateModal';
import { Context } from '../../../functions/context';
import { stylesObj } from '../../../stylesObj/stylesObj';


export default function KitsTable(props) {
  const context = useContext(Context);
  
  const [isOpenModal, setIsOpenModal] = useState(false);
  
  const [isWaiting, setIsWaiting] = useState(false);

  const [pointsObj, setPointObj] = useState({});
  useEffect(()=>{
    let newPintObj = {};
    context.salePoints.map((point)=>{
      newPintObj[point.id] = {...point};
    });
    setPointObj(newPintObj);
  },[context.salePoints]);

  return(
    <div style={{display:'flex'}}>
      {typeof isOpenModal === 'number' ?
        <KitUpdateModal
          indexOf={isOpenModal}
          isOpenModal={typeof isOpenModal === 'number'}
          onOpenModal={setIsOpenModal}
          onSetIsWaiting={setIsWaiting}
          isWaiting={isWaiting}
          role={props.role}
          pointsObj={pointsObj}
          item={context.kits[isOpenModal]}
        /> 
        :
        null
      }
      <TableContainer component={Paper} sx={{ marginBottom: '40px' }}>
        <Table aria-label="caption table">
          <TableHead>
            <TableRow >  
              <TableCell>имя</TableCell>
              <TableCell>тип</TableCell>
              <TableCell>точка</TableCell>
                
              <TableCell align="right">действия</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              context.kits.map((item, index) => (
                <KitRow
                  key = {item.id}
                  index = {index}
                  item = {item}
                  isWaiting = {isWaiting}  
                  onOpenModal={setIsOpenModal}
                  role={props.role}
                  point={(item.point) ? pointsObj[item.point]?.name :'' }
                />
              ))
            }
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
