import React, { Fragment, useContext, useEffect, useReducer } from 'react';
import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';

import { Context } from '../../../../functions/context';

import subscribeSettingsTogglesFields from '../subscribeSettingsTogglesFields';
import subscribesSettingsReducer from '../subscribesSettingsReducer';
import defaultObjectStateForReducer from '../../../../resources/defaultObjectStateForReducer';
import getSettings from '../fetch/getSettings';

import {stylesObj} from '../../../../stylesObj/stylesObj';
import CheckBoxWithInput from '../../../../Components/CheckBoxWithInput';
import setSettings from '../fetch/setSettings';

const CheckBoxList = ({deliveryType}) => {
  const context = useContext(Context);
  
  const [subscribesSettingsObj, subscribesSettingsDispatch] = useReducer(subscribesSettingsReducer , {...defaultObjectStateForReducer});

  const getValues = async () => {
    subscribesSettingsDispatch({
      type: 'START_WAITING'
    });
    const resultSubscribesSettings = await getSettings({
      token: context.token,
      deliveryType: deliveryType
    });
    subscribesSettingsDispatch({
      type: 'GET_STATE', 
      payload: {...resultSubscribesSettings}
    });
  };

  useEffect(()=>{
    getValues();
  },[]);

  

  const togglePushSubscribeSettings = (e) => {
    console.log('before subscribesSettingsDispatch', subscribesSettingsObj);
    subscribesSettingsDispatch({
      type: 'TOGGLE', 
      payload: {
        name: e.target.name,
        deliveryType: deliveryType
      }
    });
  };

  const handleChangePushSubscribeSettingsInput = (e) => {
    subscribesSettingsDispatch({
      type: 'HANDLE_CHANGE_VALUE', 
      payload: {
        name: e.target.name,
        value: e.target.value,
      }
    });
  };
  
  const handleClickSaveSettingsButton = async () => {
    subscribesSettingsDispatch({
      type: 'START_WAITING'
    });
    const result = await setSettings ({
      token: context.token,
      deliveryType: deliveryType,
      data: subscribesSettingsObj.data
    });
    subscribesSettingsDispatch({
      type: 'HANDLE_CLICK_SAVE_BUTTON', 
      payload: {...result}
    });
  };

  return(
    <Fragment>
      {subscribeSettingsTogglesFields.map((settingsField, indexSettingsField)=>
        <CheckBoxWithInput 
          {...settingsField}
          key = {indexSettingsField}
          isActive = {subscribesSettingsObj.data?.[settingsField.name]?.isActive}
          value = {subscribesSettingsObj.data?.[settingsField.name]?.momentParam}
          isWaiting = {subscribesSettingsObj.isWaiting}
          onToggle = {togglePushSubscribeSettings}
          onChangeValue = {handleChangePushSubscribeSettingsInput}
        />
      )
      }

      {/* суда добавить отображение ошибки. можно её добавить как раз  в общую строчку с кнопкой сохранить */}
      
      <LoadingButton 
        variant="contained"
        color="primary"
        disabled = {!!subscribesSettingsObj.isDisableSaveButton}
        loading = {subscribesSettingsObj.isWaiting}
        loadingPosition = "end"
        endIcon = {<SaveIcon/>}
        onClick = {handleClickSaveSettingsButton}
        sx = {{marginTop: 4, alignSelf: 'flex-end'}}
      >
          Сохранить
      </LoadingButton>
    </Fragment>
  );
};

export default CheckBoxList;