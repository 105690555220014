/* eslint-disable eqeqeq */
import React, { useContext, useEffect, useState, useRef, Fragment } from 'react';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import IconButton from '@mui/material/IconButton';

import axi from '../../../functions/axiosf';

import { Context } from '../../../functions/context';
import { AccordionDetails, ButtonBase, Card, CircularProgress } from '@mui/material';
import moment from 'moment';
import checksColors from '../checksColors';
import { CheckInfo } from './CheckInfo';
import getSumWithFixed2 from '../../../functions/getSumWithFixed2';
import getFullTimeString from '../../../functions/getFullTimeString';
import isResultObjectOk from '../../../functions/isResultObjectOk';
import styles from '../index.module.css';


const Check = (props) => {
  const context = useContext(Context);
  const [isWaiting, setIsWaiting] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [checkInfo, setCheckInfo] = useState(null);



  const axiGetCheckDetails = (checkId) => {
    setIsWaiting(true);
    axi('admin/getKktCheck.php', '', { token: context.token, checkId: checkId})
      .then((result) => { 
        // console.log(result);
        if (isResultObjectOk(result,['kktChecks',0],'ошибка в получении данных чека')) {
          setCheckInfo(result.kktChecks[0]);
        } else {
          setCheckInfo(null);
        }
        setIsWaiting(false);
      }, 
      (e) => {
        console.log(e);
        setIsWaiting(false);
        setCheckInfo(null);
      }
      );
  };

  const handleExpand = (id, expanded) => {
    setIsExpanded(expanded);
    let checkId = id;
    if (checkId!==undefined) {
      if (expanded) {
        axiGetCheckDetails(checkId);
      } else {
        setCheckInfo(null);
      }
    }
  };

  const getProductColor=(product)=>{
    if (props.category!==0) {
      let productCategory=product.category;
      if (productCategory!==0) {
        return context.types.find(item=>+item.id===product.category).color;
      }
    } else if (props.nomenclature!==null && props.nomenclature?.id!==0) {
      if (product.id===props.nomenclature.id) {
        return checksColors.selectedProductBackground;
      }
    }
    return checksColors.productBackground;
  };

  return(
    <Card
      className = {styles.accordion}
      sx = {{ backgroundColor: checksColors.topCheckColorBackground, padding: 1}}
      TransitionProps = {{ unmountOnExit: true }}
      key = {props.check?.id}>
      <table className={styles.tableChecks}>
        <tbody>
          <tr >
            <td colSpan="2" >
              <IconButton
                aria-label="expand row"
                size="big"
                onClick={() => handleExpand(props.check?.id, !isExpanded)}
              >
                {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
              {/* {getFullTimeString(props.check?.timeH, props.check?.timeM, props.check?.timeS)} ({props.checkN}) {moment((+props.check?.time)*1000).format('YYYY-MM-DD')}  */}
              {/* {moment((+props.check?.time)*1000).format('HH:mm:ss DD.MM.YYYY')} ({props.checkN}) {props.check?.time} */}
              {moment((+props.check?.time)*1000).format('HH:mm:ss')}
            </td>
            <td colSpan="2" style={{ textAlign: 'right'}}>
              <b>
                {getSumWithFixed2(props.check?.total)}
              </b> р. 
            </td>
          </tr>
            
          <tr 
            className={styles.tableRowUnderlined}
            style={{ borderBottomColor: `${checksColors.tableUnderlineColor}`, cursor: 'text'}}
          >
            <th style={{cursor: 'text'}}>
                Наименование
            </th>
            <th className={styles.productRightAlign}>
                Кол.
            </th>
            <th className={styles.productRightAlign}>
                Цена
            </th>
            <th className={styles.productRightAlign}>
                Сумма
            </th>
          </tr>
          {props.check.products.map((product, index) => 
            <Fragment
              key = {index}>
              <tr
                className={styles.tableRowUnderlined}
                style={{
                  backgroundColor: `${getProductColor(product)}`,
                  borderBottomColor: `${checksColors.tableUnderlineColor}`
                }}
              >
                <td >
                  {product.name}
                </td>
                <td className={styles.productRightAlign}>
                  {product.quantity}
                </td>
                <td className={styles.productRightAlign}>
                  {getSumWithFixed2(product.price)}
                </td>
                <td className={styles.productRightAlign}>
                  {getSumWithFixed2(product.total)}
                </td>
              </tr>
            </Fragment>
          )}
        </tbody>
      </table>
      <CheckInfo 
        isWaiting={isWaiting}
        check={checkInfo}
      /> 
    </Card>
  );
};


export { Check };
