import * as React from 'react';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ruLocale from 'date-fns/locale/ru';
import moment from 'moment';
import { DatePicker, MobileDatePicker } from '@mui/x-date-pickers';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import { Context } from '../../functions/context';
import kitsTypes from '../../resources/kitsTypes';
import daysOfWeek from '../../resources/daysOfWeek';
import propsF from '../../functions/propsF';


export default function DayOfWeekPicker(props) {
  const context = React.useContext(Context);

  const handleChangeDayOfWeek = (e) => {
    propsF(props.onChangeDayOfWeek, e);
  };
  
  return(    
    <FormControl style={{display: 'flex',flex: 1, margin: 8}}>
      <InputLabel id="demo-simple-select-label">День недели</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={props.dayOfWeek}
        disabled={props.isWaiting}
        label="День недели"
        onChange={handleChangeDayOfWeek}
      >
        {Object.keys(daysOfWeek).map((key, index) => {
          return(
            <MenuItem 
              key={key} 
              value={key}>
              {daysOfWeek[key]}
            </MenuItem>
          );
        })                  
        }
      </Select>
    </FormControl>
  );     
};
