/* eslint-disable eqeqeq */
import React, { Fragment } from 'react';
import checksColors from '../checksColors';
import styles from '../index.module.css';
import checkInfoFields from '../../../resources/checkInfoFields';
import getSumWithFixed2 from '../../../functions/getSumWithFixed2';
import { CircularProgress } from '@mui/material';
import AccordionDetails from '@mui/material/AccordionDetails';



const CheckInfo = ({check,isWaiting}) => {

  function getProcessedCheckInfoField(value,fieldType) {
    if (fieldType!==null) {
      switch (fieldType) {
        case 'sum': return getSumWithFixed2(value);
          break;
        case 'date': return value[8]
          .concat(value[9])
          .concat('.')
          .concat(value[5])
          .concat(value[6])
          .concat('.')
          .concat(value.substr(0,4))
          .concat(' ')
          .concat(value.substr(11,8));
          break;
      }
    }
    return value;
  }
  
  if (check === null || typeof check !== 'object') {
    return <></>;
  }

  if (isWaiting) {
    return <CircularProgress size={56} />;
  }

  return(
    <AccordionDetails 
      sx={{
        marginBottom: 1,
        display: 'flex',
        flexDirection: 'column',
      }}>
      <table 
        className={styles.table}
      >
        <tbody>
          {checkInfoFields.map((item,index)=>
            <Fragment key={index}>
              <tr className={`${styles.tableRow} ${styles.tableRowUnderlined}`}>
                <td style={{ flex:1, color:checksColors.expandedFontColor}}>
                  {item[0]} :
                </td>
                <td style={{textAlign: 'right'}}>
                  <b>
                    {getProcessedCheckInfoField(check[item[1]],item[2])}
                  </b>
                </td>
              </tr>
            </Fragment>
          )
          }
        </tbody>
      </table>
    </AccordionDetails>
  );
};


export { CheckInfo };
