/* eslint-disable eqeqeq */
import React, { useContext, useEffect, useState, useRef, Fragment } from 'react';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import Paper from '@mui/material/Paper';

import axi from '../../../functions/axiosf';

import {stylesObj} from '../../../stylesObj/stylesObj';

import { Context } from '../../../functions/context';
import { CircularProgress } from '@mui/material';
import moment from 'moment';

const MotivationOneCheckItem = (props) => {
  const context = useContext(Context);
  const [isWaiting, setIsWaiting] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [cashierData, setCashierData] = useState({});
  const [cashierArray, setCashierArray] = useState(context.cashiers.filter((sp) => sp.id == props.invoice?.cashierId));
  const exRef = useRef();

  
  useEffect(()=>{
    let newCashierArray = context.cashiers.filter((sp) => sp.id == props.invoice?.cashierId);
    setCashierArray(newCashierArray);
  },[props.invoice?.cashierId]);


  const handleGetSummaryRemuneration = async () => {
    
    setIsWaiting(true);
    //тут считаем весь заработок текущего кассира за месяц 
    //нужно этот запрос отправить родительскому компоненту
    if( typeof props.onGetSummaryRemuneration === 'function'){
      if(cashierArray.length>0){
        let cashierSummaryRemuneration = await props.onGetSummaryRemuneration(cashierArray[0]);
        setCashierData(cashierSummaryRemuneration);
      }else{
        setCashierData({});
        setIsExpanded(false);
      }
    }
    setIsWaiting(false);
  };

  const handleExpand = (event, expanded) => {
    let currentIsExpanded = isExpanded;
    setIsExpanded(!currentIsExpanded);
    if(!currentIsExpanded){
      handleGetSummaryRemuneration();
    }
  };

  useEffect(()=>{
    setIsExpanded(false);
  },[props.invoice]);


  //const findRemuneration = ( cashierSales) => {
  let cashierSales = props.invoice;
  let remuneration = 0;
  let plan = 0;
  let motivationsSettingsArray = props.oneCheckMotivationsSettings?.filter((item)=>{
    return(cashierArray[0]?.point == item.point
        && cashierSales?.quantityInCheck == item.quantityInCheck
        //&& cashierSales?.accumulatedResult >= item.quantity
    );
  });
  if (typeof motivationsSettingsArray === 'object' && motivationsSettingsArray.length > 0){

    motivationsSettingsArray.sort((a, b) => {
      if (+a.quantity > +b.quantity) {
        return -1;
      }
      if (+a.quantity == +b.quantity) {
        return 0;
      }
      if (+a.quantity < +b.quantity) {
        return 1;
      }
      return 0;
    });

    let im = 0;
    while(typeof motivationsSettingsArray[im] === 'object'){
      plan = motivationsSettingsArray[im].quantity;
      if (+cashierSales?.accumulatedResult >= +motivationsSettingsArray[im].quantity) {
        remuneration = motivationsSettingsArray[im].remuneration;
        plan = motivationsSettingsArray[im].quantity;
        break;
      }
      im ++ ;
    }
    
  }
  //  return(remuneration);
  //};

  const handleRenderMonthName = () => {
    if (typeof props.renderMonthName === 'function'){
      return props.renderMonthName();
    }
    return '';
  };
 
  return(
    <TableRow 
      //className={style.Paperhead} 
    >             
      <TableCell component="th" scope="row" align="left" >{moment(props.invoice?.date*1000).format('LL')}</TableCell>               
      <TableCell component="th" scope="row" align="right">             
        <Accordion
          onChange = {handleExpand}
          ref = {exRef}
          expanded = {isExpanded}
          disabled = {cashierArray.length === 0}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={'panel'+props.invoice?.id+'a-content'}
            id={'panel'+props.invoice?.id+'a-header'}
          >
            <Typography
              sx={{display: 'flex', justifyContent: 'space-between', width: '95%'}}
            >
              {cashierArray[0]?.name}
            </Typography>
          </AccordionSummary>
          <AccordionDetails 
            sx={{
              marginBottom: 1,
            }}>
            {isWaiting ? 
              <CircularProgress
                size={56}
              />
              :
              <p>{
                cashierData.onlyOneCheckFilter ? 
                  'По мотивации по количеству товаров в чеке' 
                  : 'Всего '} 
              <br/> премия за {handleRenderMonthName()}: <br/>
              {cashierData.remunerationSum} руб.
              </p>
            }
          </AccordionDetails>
        </Accordion>
      </TableCell>
      <TableCell component="th" scope="row" align="right">{props.invoice?.quantityInCheck}</TableCell>          
      <TableCell component="th" scope="row" align="right">{plan}</TableCell>                           
      <TableCell component="th" scope="row" align="left">{props.invoice?.accumulatedResult}</TableCell>    
      <TableCell component="th" scope="row">{remuneration}</TableCell>   {/* findRemuneration(props.invoice) */}
    </TableRow>
  );
  
};


export { MotivationOneCheckItem };