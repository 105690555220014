/* eslint-disable eqeqeq */
import React, { useContext, useEffect, useState, useRef, Fragment } from 'react';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import Paper from '@mui/material/Paper';

import axi from '../../../functions/axiosf';

import {stylesObj} from '../../../stylesObj/stylesObj';

import { Context } from '../../../functions/context';
import { CircularProgress } from '@mui/material';
import moment from 'moment';
import hourMinFromMinutes from '../../../functions/hourMinFromMinutes';

const WorkShiftItem = (props) => {
  const context = useContext(Context);
  const [isWaiting, setIsWaiting] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [cashierData, setCashierData] = useState({});
  const [cashierArray, setCashierArray] = useState(context.cashiers.filter((sp) => sp.id == props.invoice?.cashierId));

  
  useEffect(()=>{
    let newCashierArray = context.cashiers.filter((sp) => sp.id == props.invoice?.cashierId);
    setCashierArray(newCashierArray);
  },[props.invoice?.cashierId]);



  useEffect(()=>{
    setIsExpanded(false);
  },[props.invoice]);

  const renderCashierName = (id) => {
    let cashiersArray = context.cashiers.filter((sp) => sp.id == id);
    return(cashiersArray[0]?.name);
  };

  let startTimeInDayMin = (+moment(props.invoice?.time*1000).format('HH')*60) + (+moment(props.invoice?.time*1000).format('mm'));
  let endTimeInDayMin = (+moment(props.closeInvoice?.time*1000).format('HH')*60) + (+moment(props.closeInvoice?.time*1000).format('mm'));
 
   
  return(
    <TableRow>             
      <TableCell component="th" scope="row" align="left" >{moment(props.invoice?.time*1000).format('LL')}</TableCell>               
      <TableCell component="th" scope="row" align="left">{props.schedulePoint?.name}</TableCell>              
      <TableCell component="th" scope="row" align="left">{renderCashierName(props.invoice?.sended)}</TableCell>     
      <TableCell component="th" scope="row" align="right"
        sx={{backgroundColor: startTimeInDayMin > props.schedulePoint?.open ? '#f99' : '#0000'}}
      >
        {moment(props.invoice?.time*1000).format('LT')}
      </TableCell> 
      <TableCell component="th" scope="row" align="left">{hourMinFromMinutes(props.schedulePoint?.open)}</TableCell>            
      <TableCell component="th" scope="row" align="right"
        sx={{backgroundColor: endTimeInDayMin < props.schedulePoint?.close ? '#f99' : '#0000'}}
      >{
          !props.closeInvoice ?
            'смена не закрыта' :
            moment(props.closeInvoice?.time*1000).format('LT')
        }</TableCell>             
      <TableCell component="th" scope="row" align="left">{hourMinFromMinutes(props.schedulePoint?.close)}</TableCell>   
    </TableRow>
  );
  
};

export { WorkShiftItem };