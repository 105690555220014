import * as React from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import { stylesObj } from '../../../../stylesObj/stylesObj';
import { Context } from '../../../../functions/context';
import { Card, CircularProgress } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import LoadingButton from '@mui/lab/LoadingButton';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';


const NewRow = (props) => {
  const context = React.useContext(Context);
  const [quantity, setQuantity] = React.useState();
  const [remuneration, setRemuneration] = React.useState();

  const handleChangeQuantity = (e) => {
    setQuantity(e.target.value);
  };
  
  const handleChangeRemuneration = (e) => {
    setRemuneration(e.target.value);
  };

  const handlePressUpdate = () => {
    if(typeof props.onPressAddNewShelfPosition === 'function'){
      props.onPressAddNewShelfPosition(quantity, remuneration);
    }
  };
  
  const renderProductName = ()=>{
    let currentProductObj = context.nomenclature.filter((produkt) => produkt.id === props.product);
    return(currentProductObj[0].name);
  }; 

  const renderCategoryName = ()=>{
    let currentProductObj = context.types.filter((type) => type.id === props.type);
    return(currentProductObj[0].name);
  };

  return (                    
    <TableRow sx={{backgroundColor: '#eee', ...stylesObj.TableContainer}}>
      <TableCell component="th" scope="row" align="left">
        {renderCategoryName()}
      </TableCell>
      <TableCell sx={stylesObj.TableCellMinPadding} align="right">
        <TextField
          id="outlined-number"
          value={quantity}
          type="number"
          onChange={handleChangeQuantity}
          InputLabelProps={{
            shrink: true,
          }}
          size="small"
          sx={{width: '100px'}}
          disabled={props.isWaiting}
        />
      </TableCell>
      <TableCell sx={stylesObj.TableCellMinPadding} align="left">
        <TextField
          id="outlined-number"
          value={remuneration}
          type="number"
          
          onChange={handleChangeRemuneration}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps = {{step: 10}}
          size="small"
          sx={{width: '100px'}}
          disabled={props.isWaiting}
        />
      </TableCell>
      <TableCell>
        <LoadingButton 
          variant="contained"
          color="success"
          disabled={props.isWaiting || !quantity || !remuneration}
          loading={props.isWaiting}
          loadingPosition="end"
          endIcon={<PlaylistAddIcon/>}
          onClick={handlePressUpdate}
          //sx={margin}
        >
          добавить
        </LoadingButton>
        {/* 
        <IconButton 
          variant="contained" 
          onClick={handlePressUpdate}>
          <span style={{fontSize: 16, marginRight: 4}}>добавить</span>
          <PlaylistAddIcon/>
        </IconButton> 
         */}
      </TableCell>
    </TableRow>
  );
};

export default NewRow;