import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import OneSignalReact from 'react-onesignal';
import { DrawerPage } from './Components/DrawerPage/index';
import axi from './functions/axiosf';
import { Context } from './functions/context';
import LoginModal from './Components/authorization/LoginModal';


class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      token: '',
      nomenclature: [],
      setNomenclature: this.setNomenclature,
      types: [],
      setTypes: this.setTypes,
      salePoints: [],
      setSalePoints: this.setSalePoints,
      axiLogIn: this.axiLogIn,
      userExit: this.userExit,
      axiGetAllBase: this.axiGetAllBase,
      setAllBase: this.setAllBase,
      admins: [],
      cashiers: [],
      kits: [],
      kkts: [],
      isInitOpenSignal: false,

      invoice:[],
      setInvoice: this.setInvoice,
      invoicePoint:null,
      setInvoicePoint: this.setInvoicePoint,

    };
  }

  componentDidMount(){
  
    OneSignalReact.init({
      appId: '191f1fe4-6be1-4eef-9d32-d2fd5a4d8426',
      allowLocalhostAsSecureOrigin: true
    }).then(() => {
      this.setState({isInitOpenSignal: true});
      OneSignalReact.showSlidedownPrompt().then(() => {
        // do other stuff
      });
    });
  
  }


  setInvoice = (itemsArray) => {
    this.setState({invoice: itemsArray});
  };
  setInvoicePoint = (point) => {
    this.setState({invoicePoint: point});
  };

  setNomenclature = (itemsArray) => {
    this.setState({nomenclature: itemsArray});
  };

  setTypes = (itemsArray) => {
    this.setState({types: itemsArray});
  };

  setSalePoints = (itemsArray) => {
    this.setState({salePoints: itemsArray});
  };

  setAllBase = (result) => {
    if (result.type === 'no_authorized') {
      this.setState({token: ''});
    } else {
      result.products && this.setNomenclature(result.products);
      result.productsTypes && this.setTypes(result.productsTypes);
      result.salePoints && this.setSalePoints(result.salePoints);
      result.admins && this.setState({admins: result.admins});
      result.cashiers && this.setState({cashiers: result.cashiers});
      result.kits && this.setState({kits: result.kits});
      result.kkts && this.setState({kkts: result.kkts});
    }
  };
  
  axiLogIn = async (login, pass) => {
    try {
      let result = await axi('admin/logInAdmin.php', '', {name: login, password:  pass});
      if (result.type === 'approved') {
        this.setState({token: result.token});
        this.axiGetAllBase(result.token);
        return ('авторизация прошла успешно');
      } else if (result.error === 'no_authorized') {
        return('Неверное имя пользователя');
      } else if (result.error === 'invalid_password'){
        return('Неверный пароль');
      } else if (result.error === 'no_name') {
        return('введите имя пользователя');
      } 
      return (result.type);
    } catch (e) {
      return (e);
    }
  };

  userExit = () => {
    this.setState({token: ''});
  };

  axiGetAllBase = (authToken) => {
    axi('admin/getActualBase.php', '', { token: authToken ? authToken : this.state.token })
      .then((result) => { 
        this.setAllBase(result);
      }, 
      (e) => {
        console.log(e);
      }
      );
  };

  render() {
    return ( 
      <BrowserRouter>
        <Context.Provider value={this.state}>
          {this.state.token? <DrawerPage /> : <LoginModal/> }
        </Context.Provider>
      </BrowserRouter>
    );
  }
}

export default App;
