/* eslint-disable eqeqeq */
import React, { Fragment, useContext, useEffect, useState } from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ruLocale from 'date-fns/locale/ru';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import Paper from '@mui/material/Paper';

import axi from '../../functions/axiosf';

import {stylesObj} from '../../stylesObj/stylesObj';
import styles from './index.module.css';

import { Context } from '../../functions/context';
import { CircularProgress } from '@mui/material';
import moment from 'moment';
import { WorkShiftItem } from './WorkShiftItem';
import isResultObjectOk from '../../functions/isResultObjectOk';
import dateInMsToSeconds from '../../functions/dateInMsToSeconds';

import axios from 'axios';


const WorkSchedules = () => {
  const context = useContext(Context);
  const [isStartWaiting, setIsStartWaiting] = useState(false);
  
  const [point, setPoint] = useState(0);
  const [cashier, setCasher] = useState(0);
  // const [date, setDate] = useState(null);

  const [pointsObj, setPointsObj] = useState(0);
  const [allInvoices, setAllInvoices] = useState([]);

  const [openInvoice, setOpenInvoices] = useState([]);
  const [closeInvoices, setCloseInvoices] = useState([]);

  const minDate = +moment([2015]).startOf('year').startOf('day');
  const maxDate = +moment().endOf('year').endOf('day');

  const [date, setDate] = useState(+moment().startOf('day')); // тут по умолчанию поставить не ткущее время а начало дня
  const [dateError, setDateError] = useState('');

  const axiGetAllInvoices = (axiCancelToken) => {
    // console.log(date);
    if (minDate <= date && date <= maxDate) {
      setIsStartWaiting(true);
      axi(
        'admin/getAllInvoices.php', 
        '', 
        { token: context.token, date: dateInMsToSeconds(date) },
        axiCancelToken
      ).then(
        (result) => { 
          console.log(result);
          
          let newOpenInvoices = [];
          let newCloseInvoices = [];

          if (isResultObjectOk(result,['invoices'],'ошибка в получении списка',setDateError)) {
          
            result.invoices.map((inv)=>{
              
              if (inv.type === 'startRemains'){
                newOpenInvoices = [...newOpenInvoices, inv];
              } else if (inv.type === 'endRemains'){
                newCloseInvoices = [...newCloseInvoices, inv];
              }
            });
            
          } 
          setOpenInvoices(newOpenInvoices);
          setCloseInvoices(newCloseInvoices);
          console.log(newOpenInvoices);
          console.log(newCloseInvoices);

          setIsStartWaiting(false);
        }, 
        (e) => {
          console.log(e);
          setIsStartWaiting(false);
        }
      );
    } else {
      setDateError('Неактуальная дата');
    }
  };

  useEffect(()=>{
    const axiCancelToken = axios.CancelToken.source();
    axiGetAllInvoices(axiCancelToken);
    return () => {
      axiCancelToken.cancel();
    };
  },[date]);

  useEffect(()=>{
    let newPointsObj = {};
    context.salePoints.map((point)=>{
      newPointsObj[point.id] = point;
    });
    setPointsObj(newPointsObj);
  }, [context.salePoints]);
  
  const handleChangeCasher = (event) => {
    let newCasherId = event?.target?.value;
    setCasher(newCasherId);
  };

  const handleChangePoint = (event) => {
    let newPointId = event?.target?.value;
    setPoint(newPointId);
  };

  const handleChangeDate = (value) => {
    setDateError('');
    let newDate = +value;
    setDate(newDate); 
  };

  const isDateError=()=> dateError !== '';

  return(
    <div style={stylesObj.simpleRowWrap}>
      <FormControl style={stylesObj.selectFilter}>
        <InputLabel id="demo-simple-select-label">Точка</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={point}
          label="Точка"
          onChange={handleChangePoint}
        >
          <MenuItem 
            key={0} 
            value={0}>
              Все
          </MenuItem>
          {context.salePoints.map((item, index)=>
            <MenuItem 
              key={index} 
              value={item.id}>
              {item.name}
            </MenuItem>)}
        </Select>
      </FormControl>

      <FormControl style={stylesObj.selectFilter}>
        <InputLabel id="demo-simple-select-label">Кассир</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={cashier}
          label="Кассир"
          onChange={handleChangeCasher}
        >
          <MenuItem 
            key={0} 
            value={0}>
              Все
          </MenuItem>
          {context.cashiers.map((item, index)=>
            <MenuItem 
              key={index} 
              value={item.id}>
              {item.name}
            </MenuItem>)}
        </Select>
      </FormControl>

      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={ruLocale}
      >
        <DatePicker
        
          label='Дата'
          value={date}
          onChange={(newValue) => {
            handleChangeDate(newValue);
          }}
          renderInput={(params) => (
            <TextField
              style={stylesObj.selectFilter}
              {...params}
              error={isDateError()}
              helperText={dateError}
              onFocus={(event) => {
                event.target.select();
              }}
              autoFocus
            />
          )}
        />
      </LocalizationProvider>


      {isStartWaiting ?
        <CircularProgress
          size={56}
        />
        :
        <Fragment>

          <TableContainer 
            //className={style.Paper} 
            component={Paper}>
            <Table 
              aria-label="collapsible table" 
              //className={style.Paperbody} 
            >
              <TableBody>
                <TableRow 
                  //className={style.Paperhead} 
                >             
                  <TableCell component="th" scope="row" align="left" >дата</TableCell>               
                  <TableCell component="th" scope="row" align="left">точка</TableCell>              
                  <TableCell component="th" scope="row" align="left">кассир</TableCell>     
                  <TableCell component="th" scope="row" align="right">открытие смены</TableCell> 
                  <TableCell component="th" scope="row" align="left">открытие по расписанию</TableCell>            
                  <TableCell component="th" scope="row" align="right">закрытие смены</TableCell>             
                  <TableCell component="th" scope="row" align="left">закрытие по расписанию</TableCell>   
                </TableRow>
                {openInvoice?.map((invoiceItem, invoiceIndex) => {
                  // для начала проверяем, соответствует ли эта накладная фильтрам по дате, точке и кассиру
                  if (( point == 0 || invoiceItem.pointOfSale == point )
                    && ( cashier == 0|| invoiceItem.sended == cashier )
                    // && ((date === null || ((+invoiceItem.time * 1000) >= date) && ((+invoiceItem.time * 1000) < date+(1000*60*60*24))))
                  ){

                    // находим пару для этой накладной, чтобы передать элемент ниже
                    // нужна накладная закрытия смены первая за после времени текущей накладной 
                    // для этого отфильтруем сначала все по точке, кассиру, дню и веремени после накладной
                    let filteredCloseInvoice = closeInvoices.filter((inv)=>{
                      return(
                        ( invoiceItem.pointOfSale == inv.pointOfSale )
                        && ( invoiceItem.sended == inv.sended )
                        //&& ((+inv.time * 1000) >= date) && ((+inv.time * 1000) < date+(1000*60*60*24)) 
                        //тут надо переписать с дня из настроек на день первой накладной. можно сразу с концом дня сравнивать
                        && (+inv.time > +invoiceItem.time)
                        && (+inv.time * 1000 < moment(+invoiceItem.time * 1000).endOf('day'))
                      );
                    });

                    // и потом отсортируем по времени по возрастанию и возьмём первую из списка
                    //console.log('before sort');
                    //console.log(filteredCloseInvoice);
                    filteredCloseInvoice.sort((a, b) => {
                      if (+a.time > +b.time) {
                        return 1;
                      }
                      if (+a.time == +b.time) {
                        return 0;
                      }
                      if (+a.time < +b.time) {
                        return -1;
                      }
                      return 0;
                    });

                    //console.log('after sort');
                    //console.log(filteredCloseInvoice);

                    return(
                      <WorkShiftItem
                        key = {invoiceIndex}
                        invoice = {invoiceItem}
                        closeInvoice = {filteredCloseInvoice[0]}
                        schedulePoint = {pointsObj[invoiceItem.pointOfSale]}
                      />
                    );
                  }
                  return('');
                })}
              </TableBody>
            </Table>
          </TableContainer>
         

        </Fragment>
      }

    </div>
  );
  
};


export { WorkSchedules };
