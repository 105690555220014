
//потом можно загружать этот перечень из бекенда, чтобы не требовалось обновлять морду если появится новый вид уведомления

const subscribeSettingsTogglesFields = [
  {
    name: 'passwordRecovery',
    label: 'доствака кода для восстановления пароля'
  },{
    name: 'productIsRunningOut',
    label: 'товар скоро закончится (число больше нуля)',
    inputLabel: 'при остатке'
  },{
    name: 'cashierLater',
    label: 'продавец открыл смену после начала рабочего дня',
    inputLabel: 'опоздание, мин.'
  },{
    name: 'newProductLater',
    label: 'продавец принял товар позже заявленного начла продаж при его отсутствии',
    inputLabel: 'позже на, мин'
  },
];

export default subscribeSettingsTogglesFields;