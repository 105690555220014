//потом можно загружать этот перечень из бекенда, чтобы не требовалось обновлять морду если появится новый вид уведомления

const rootSettingsFields = [
  {
    name: 'sqlurl',
    label: 'адрес SQL сервера',
    isPassword: false,
  },{
    name: 'sqluser',
    label: 'пользователь SQL сервера',
    isPassword: false,
  },{
    name: 'sqlpassword',
    label: 'пароль SQL сервера',
    isPassword: true,
  },{
    name: 'sqldb',
    label: 'SQL база данных',
    isPassword: false,
  },{
    name: 'inn',
    label: 'INN',
    isPassword: false,
  },{
    name: 'ofdlogin',
    label: 'OFD.ru логин',
    isPassword: false,
  },{
    name: 'ofdpassword',
    label: 'OFD.ru пароль',
    isPassword: true,
  },{
    name: 'sbiskktlogin',
    label: 'CБиС ККТ логин',
    isPassword: false,
  },{
    name: 'sbiskktpassword',
    label: 'CБиС ККТ пароль',
    isPassword: true,
  },{
    name: 'onesignalappid',
    label: 'OneSignal app id',
    isPassword: false,
  },{
    name: 'onesignalappkey',
    label: 'OneSignal app key token',
    isPassword: true,
  },{
    name: 'onesignaluserkey',
    label: 'OneSignal user key token',
    isPassword: true,
  },
];

export default rootSettingsFields;