import React, { createContext } from 'react';

const Context = createContext({
  token: 'test',
  nomenclature: [],
  setNomenclature: ()=>{},
  types: [],
  setTypes: ()=>{},
  salePoints: [],
  setSalePoints: ()=>{},
  axiLogIn: ()=>{},
  userExit: ()=>{},
  axiGetAllBase: ()=>{},
  setAllBase: ()=>{},
  admins: [],
  cashiers: [],
  kits: [],
  kkts: [],
  isInitOpenSignal: false,
  
  invoice:[],
  setInvoice: ()=>{},
  invoicePoint:null,
  setInvoicePoint: ()=>{},
});

export { Context };
