import * as React from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import { stylesObj } from '../../../../stylesObj/stylesObj';
import { Context } from '../../../../functions/context';
import { Card, CircularProgress } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';


const NewRow = (props) => {
  const context = React.useContext(Context);
  const [quantityInCheck, setQuantityInCheck] = React.useState();
  const [quantity, setQuantity] = React.useState();
  const [remuneration, setRemuneration] = React.useState();

  const handleChangeQuantity = (e) => {
    setQuantity(e.target.value);
  };

  const handleChangeQuantityInCheck = (e) => {
    setQuantityInCheck(e.target.value);
  };
  
  const handleChangeRemuneration = (e) => {
    setRemuneration(e.target.value);
  };

  const handlePressUpdate = () => {
    if(typeof props.onPressAddNewOneCheckMotivation === 'function'){
      props.onPressAddNewOneCheckMotivation(quantityInCheck, quantity, remuneration);
    }
  };

  return (                    
    <React.Fragment>
      <TableRow sx={{backgroundColor: '#eee', ...stylesObj.TableContainer}}>
        <TableCell sx={stylesObj.TableCellMinPadding} align="left">
          <TextField
            id="outlined-number"
            value={quantityInCheck}
            type="number"
            onChange={handleChangeQuantityInCheck}
            InputLabelProps={{
              shrink: true,
            }}
            size="small"
            sx={{width: '80px'}}
            disabled={props.isWaiting}
          />
        </TableCell>
        <TableCell sx={stylesObj.TableCellMinPadding} align="right">
          <TextField
            id="outlined-number"
            value={quantity}
            type="number"
            onChange={handleChangeQuantity}
            InputLabelProps={{
              shrink: true,
            }}
            size="small"
            sx={{width: '100px'}}
            disabled={props.isWaiting}
          />
        </TableCell>
        <TableCell sx={stylesObj.TableCellMinPadding} align="left">
          <TextField
            id="outlined-number"
            value={remuneration}
            type="number"
            onChange={handleChangeRemuneration}
            InputLabelProps={{
              shrink: true,
            }}
            size="small"
            sx={{width: '100px'}}
            disabled={props.isWaiting}
          />
        </TableCell>
        <TableCell>
          <LoadingButton 
            variant="contained"
            color="success"
            disabled={props.isWaiting || !quantityInCheck || !quantity || !remuneration}
            loading={props.isWaiting}
            loadingPosition="end"
            endIcon={<PlaylistAddIcon/>}
            onClick={handlePressUpdate}
            //sx={margin}
          >
            добавить
          </LoadingButton>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default NewRow;