import React, { useContext, useState } from 'react';
import { Context } from '../../../functions/context';
import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';
import IconButton from '@mui/material/IconButton';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { CircularProgress } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import axi from '../../../functions/axiosf';
import validateTextInput from '../../../functions/validateTextInput';

import propsF from '../../../functions/propsF';


const NewNomenclatureForm = ({
  labelTextAll,
  category, setCategory,
  categoryError, setCategoryError,
  setIsCategoriesSorted,
}) => {
  const context = useContext(Context);
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState('');
  const [isWaiting, setIsWaiting] = useState(false);



  const handleChangeName = (event) => {
    let newName = event?.target?.value;
    let [textError, isSetState] = 
      validateTextInput(newName, 'наименование нового товара');
    isSetState && setName(newName);
    if (newName!==''){
      setNameError(textError);
    } else {
      setNameError('');
    }
  };

  const handleSelectCategory =(event)=>{
    propsF(setCategory,event?.target?.value);
    propsF(setCategoryError,'');
  };
 
  //функция добавления товара в список в backend
  const handleClickAdd = async() => {
    
    if (category===0) {
      setCategoryError('Выберите категорию товара');
      return;
    }

    
    setIsWaiting(true);
    try {
      let result = await axi(
        'admin/addNewProduct.php', 
        '', 
        { 
          token: context.token, 
          newProductName: name,
          newProductType: category
        }); 
      if (typeof context.setAllBase === 'function'){ 
        context.setAllBase(result);
        propsF(setIsCategoriesSorted,false);
      }
    } catch (e) {
      console.log(e);
    }
    setIsWaiting(false);
    setName('');
  };

  return(
    <div style={{display: 'flex', flexWrap: 'wrap'}}>
      <TextField
        label="Название товара"
        variant="outlined"
        id="standard-size-small"
        // defaultValue=""
        sx={{width:'200px', margin: '8px', transition: '1s'}}
        onChange={handleChangeName}
        value={name}
        error={nameError!==''}
        helperText={nameError}
      />
            
      <FormControl sx={{width:'200px', margin: '8px'}}>
        <InputLabel id="demo-simple-select-label">Категория</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={category}
          label="Категория"
          onChange={handleSelectCategory}
          error={!!categoryError}
        >
          <MenuItem key={0} value={0}>
            {labelTextAll}
          </MenuItem>
          {context.types.map((item, index)=> 
            <MenuItem
              key={item.id}
              value={item.id}
              style={{
                backgroundColor: item.color,
              }}
            >
              {item.name}
            </MenuItem>
          )}
        </Select>
       
        <FormHelperText
          sx={{color:'red !important'}}
        >
          {categoryError}
        </FormHelperText> 

      </FormControl>

      {isWaiting ?
        <CircularProgress 
          color="success" 
          style={{
            margin: '8px', 
            width: '35px',
            height: '35px'
          }} 
        />
        :
        <IconButton 
          onClick={handleClickAdd}
          disabled={nameError!=='' || name==='' } //|| category===0
        >
          <AddCircleIcon 
            fontSize='large' 
            color={(nameError!=='' || name==='') ? 'error' : 'success' }
          />
        </IconButton>
      }
    </div>
  );
};

export default NewNomenclatureForm;