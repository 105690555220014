import * as React from 'react';
import Table from '@mui/material/Table';
import {TableBody, TableHead, TableRow, TableCell, TableContainer} from '@mui/material';
import Paper from '@mui/material/Paper';

import PointsChangeModal from './PointsChangeModal/index';
import { Context } from '../../../functions/context';
import PointRow from './PointRow';



export default function PointsTable(props) {
  let { pointsArray, handleRemovePoint } = props;
  const context = React.useContext(Context);

  return(
    <TableContainer component={Paper} sx={{ maxWidth: 900 }}>
      <Table aria-label="caption table">
        {/* написать тут шапку таблицы и увести строки таблицы в отдельный файл */}
        <TableHead>
          <TableRow >  
            <TableCell 
              sx={{fontWeight: '700'}}>Название</TableCell>
            {window.screen.width > 420?
              <React.Fragment>
                <TableCell sx={{fontWeight: '700'}} align="left">
                    Адрес
                </TableCell>
                <TableCell sx={{fontWeight: '700'}} align="left">
                    Кассы (KktRegId)
                </TableCell>
              </React.Fragment>
              :
              <TableCell sx={{fontWeight: '700'}} align="left">
                  Адресс
                  Кассы (KktRegId)
              </TableCell>
            }
            <TableCell sx={{fontWeight: '700'}} >Время открытия</TableCell>
            <TableCell sx={{fontWeight: '700'}} >Время закрытия</TableCell>    
            <TableCell sx={{fontWeight: '700'}} align="right">действия</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {context.salePoints.map((point, index) => 
            <PointRow
              key = {index}
              point = {point}
              handleChangePointsArr = {props.handleChangePointsArr}
              isWaiting={props.isWaiting}
            />
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
