import * as React from 'react';

import Alert from '@mui/material/Alert';
import ChecklistRtlIcon from '@mui/icons-material/ChecklistRtl';
import GetAppIcon from '@mui/icons-material/GetApp';

import { stylesObj } from '../../../stylesObj/stylesObj';
import moment from 'moment';
import ruLocale from 'date-fns/locale/ru';

import { Context } from '../../../functions/context';
import Row from './Row';
import { Paper, Stack } from '@mui/material';
import propsF from '../../../functions/propsF';
import axi from '../../../functions/axiosf';
import copyObj from '../../../functions/copyObj';
import defaultArrayStateForReducer from '../../../resources/defaultArrayStateForReducer';
import daysForStatisticReducer from './daysForStatisticReducer';
import { LoadingButton } from '@mui/lab';



const SettingsForStatistics = (props) => {
  const context = React.useContext(Context);

  //const [positionsInsideType, setPositionsInsideType] = React.useState([]);
  //const [sortOrder,setSortOrder] = React.useState('');

  const [daysForStatisticArray, setDaysForStatisticDispatch] = React.useReducer(daysForStatisticReducer , {...defaultArrayStateForReducer, isPredictionTaken: false});

  //const [selectedDays, setSelectedDays] = React.useState([]);

  React.useEffect(()=>{
    if (props.set !== null){
      handleRequestDaysForKit();//нам нужно будет отправлять туда так же и даты из наборов "день года", "день месяца" и "день недели"
    }
  },[props.set]);


  const handleRequestDaysForKit = async () => {
    //запрашиваем список дней с параметрами для отображения настроек
    //возвращаемые параметры задумывались:
    //-количество задействованных номенклатурных групп
    //-количесвто задесйствованной номенклатуры
    //- может быть сумма продаж за день или ещё что нибудь...

    propsF(props.onSetIsWaiting, true);
    let result = await axi(
      'admin/prediction/getDaysForKit.php', 
      '', 
      { 
        token: context.token, 
        point: context.invoicePoint, 
        kit: props.set,
      });
    if (result.type === 'no_authorized') {
      if(typeof context.userExit === 'function'){
        context.userExit();
      }
    } else {
      if (result.status === 'success'){
        if (result.daysArray){
          let newDaysForStatisticArray = copyObj(result.daysArray) ;
          setDaysForStatisticDispatch({
            type: 'GET_STATE', 
            payload: {
              data: newDaysForStatisticArray
            }
          });
        }else{
          setDaysForStatisticDispatch({
            type: 'SET_ERROR', 
            payload: {
              error: 'для данного набора не найдены дни для сбора статистики. они появятся в будущем. если вы хотите воспользоваться этим набором сейчас - измените или период или стартовую дату работы набора в его настройках'
            }
          });
        }
      }
    }
    propsF(props.onSetIsWaiting, false);
  };

  //выбираем или снимаем выбор с дня
  const handleToggleDate = (date) => {
    console.log(moment(date*1000).format('ll'));
    
    propsF(props.onSetIsStatisticsSuccess, false);
    setDaysForStatisticDispatch({
      type: 'TOGGLE', 
      payload: {
        date: date,
      }
    });
  };


  //получаем план производства на основе выбранных дней
  const handleGetPredictionBasedOnDays = async () => {
    // запрашиваем предсказания , основанные на запрашиваемых днях
    propsF(props.onSetIsWaiting, true);
    setDaysForStatisticDispatch({ type: 'HANDLE_CLICK_SAVE_BUTTON' });
    let result = await axi(
      'admin/prediction/getPredictionBasedOnDays.php', 
      '', 
      { 
        token: context.token, 
        //daysArray: selectedDays ,
        daysArray: daysForStatisticArray.data ,
        pointOfSale: context.invoicePoint, 
        kit: props.set,
      });
    if (result.type === 'no_authorized') {
      if(typeof context.userExit === 'function'){
        context.userExit();
      }
    } else {
      if (result.status === 'ok'){
        //накладную пишем сразу в контекст
        setDaysForStatisticDispatch({ type: 'TAKEN_ON' });
        let newCleanInvoice = [];
        result.cleanInvoice.map((kitItem)=>{
          let itemRow = context.nomenclature.filter(
            (nomenclatureItem)=> 
              +nomenclatureItem.id === +kitItem.product
          );
          let rowCleanInvoice = copyObj(itemRow[0]);
          if (kitItem.quantity>0){
            rowCleanInvoice.quantity = kitItem.quantity;
          }else{
            rowCleanInvoice.quantity = 0;
          }
          rowCleanInvoice.yesterdayQuantity = kitItem.yesterdayQuantity;
          rowCleanInvoice.coefficient = kitItem.coefficient;
          newCleanInvoice = [...newCleanInvoice, rowCleanInvoice];
        });
        propsF(context.setInvoice, newCleanInvoice);
        //теперь нужно вернуть рендер накладной
        propsF(props.onSetIsStatisticsSuccess, true);
      }
    }
    propsF(props.onSetIsWaiting, false);
  };

  //сохраняем вбитую накладную как ручной набор
  const onPressConfirmSend = async ( ) => {
    setDaysForStatisticDispatch({ type: 'START_WAITING' });
    let result = await axi(
      'admin/setCustomKitInvoice.php', 
      '', 
      { 
        token: context.token, 
        point: context.invoicePoint, 
        kit: props.set,
        invoice: context.invoice,
      });
    if (result.type === 'no_authorized') {
      if(typeof context.userExit === 'function'){
        context.userExit();
      }
    } else {
      if (result.status === 'ok'){
        if (typeof context.setAllBase === 'function'){ 
          context.setAllBase(result);
        }
        setDaysForStatisticDispatch({ type: 'TAKEN_ON' });
      }
    } 
  };

  return (
    <Paper>
      <Stack>
        {/* тут разворачиваем список из  daysForStatisticArray.data и прокидываем сразу on handleToggleDate*/
          daysForStatisticArray.data?.map((item, index) =>
            <Row
              key={index}
              index={index}
              item={item}
              onToggleDate={handleToggleDate}
              isWaiting={props.isWaiting || daysForStatisticArray.isWaiting}
              sx={{padding: 2, margin: 2}}
            />
          )
        }
      </Stack>
      {daysForStatisticArray.error ?
        <Alert severity="error"> { daysForStatisticArray.error } </Alert>
        : 
        ''
      }
      <LoadingButton variant="contained" 
        endIcon = {<GetAppIcon/>}  
        disabled = {daysForStatisticArray.isDisableSaveButton}
        loading = {props.isWaiting || daysForStatisticArray.isWaiting}
        loadingPosition = "end"
        onClick = {handleGetPredictionBasedOnDays}
        sx={{margin: 2}}
      > 
        заполнить накладную
      </LoadingButton>
      <LoadingButton variant="contained" 
        endIcon = {<ChecklistRtlIcon/>}  
        disabled = {!daysForStatisticArray.isPredictionTaken}
        loading = {props.isWaiting || daysForStatisticArray.isWaiting}
        loadingPosition = "end"
        onClick = {onPressConfirmSend} 
        sx={{margin: 2}}
      > 
        сохранить в ручной набор
      </LoadingButton>
    </Paper>
  );
};

export default SettingsForStatistics;