const checksColors = {
  tableUnderlineColor: '#5555',
  productBackground: '#eeeeee',
  selectedProductBackground: '#d7f8dc',
  checksHeaderBackground: '#ffffff',
  topCheckColorBackground: '#eeeeee',
  expandedCheckColor: '#feefef',
  expandedFontColor:'#0000ff'
};

export default checksColors;