import * as React from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import { stylesObj } from '../../../../stylesObj/stylesObj';
import { Context } from '../../../../functions/context';
import { Button, Card, CircularProgress } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import SaveIcon from '@mui/icons-material/Save';


const Row = (props) => {
  const context = React.useContext(Context);
  const [quantity, setQuantity] = React.useState(props.item.quantity);
  const [remuneration, setRemuneration] = React.useState(props.item.remuneration);

  const handleChangeQuantity = (e) => {
    setQuantity(e.target.value);
  };
  
  const handleChangeRemuneration = (e) => {
    setRemuneration(e.target.value);
  };

  React.useEffect(()=>{
    setQuantity(props.item.quantity);
    setRemuneration(props.item.remuneration);
  },[props.item.quantity, props.item.remuneration]);

  const handlePressUpdate = () => {
    if(typeof props.onPressUpdateShelfPositionMotivation === 'function'){
      props.onPressUpdateShelfPositionMotivation(props.item?.id, quantity, remuneration);
    }
  };

  const renderProductName = ()=>{
    let currentProductObj = context.nomenclature.filter((produkt) => produkt.id === props.product);
    return(currentProductObj[0].name);
  };
  const renderCategoryName = ()=>{
    let currentProductObj = context.types.filter((type) => type.id === props.type);
    return(currentProductObj[0].name);
  };
  return (                    
    <TableRow sx={{backgroundColor: props.backgroundColor, ...stylesObj.TableContainer}}>
      <TableCell component="th" scope="row" align="left">
        {renderCategoryName()}
      </TableCell>
      <TableCell sx={stylesObj.TableCellMinPadding} align="right">
        <TextField
          id="outlined-number"
          value={quantity}
          type="number"
          onChange={handleChangeQuantity}
          InputLabelProps={{
            shrink: true,
          }}
          size="small"
          sx={{width: '80px'}}
          disabled={props.isWaiting}
        />
      </TableCell>
      <TableCell sx={stylesObj.TableCellMinPadding} align="left">
        <TextField
          id="outlined-number"
          value={remuneration}
          type="number"
          onChange={handleChangeRemuneration}
          inputProps = {{step: 10}}
          InputLabelProps={{
            shrink: true,
          }}
          size="small"
          sx={{width: '80px'}}
          disabled={props.isWaiting}
        />
      </TableCell>
      <TableCell>
        {props.item.quantity !== quantity || props.item.remuneration !== remuneration ?
          <React.Fragment>
            {props.isWaiting?
              <CircularProgress 
                color="success" 
                style={{
                  margin: '8px', 
                  width: '35px',
                  height: '35px'
                }} 
              />
              :
              <IconButton 
                variant="contained" 
                onClick={handlePressUpdate}>
                <span style={{fontSize: 16, marginRight: 4}}>сохранить</span>
                <SaveIcon/>
              </IconButton>
            }
          </React.Fragment>
          : ''
        }
      </TableCell>
    </TableRow>
  );
};

export default Row;