import React, { useContext, useEffect, useReducer } from 'react';
import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';


import { Typography, TextField, Paper} from '@mui/material';

import { Context } from '../../../../functions/context';
import {stylesObj} from '../../../../stylesObj/stylesObj';
import validateEmail from '../../../../functions/validateEmail';

import addressesReducer from './../addressesReducer';
import defaultArrayStateForReducer from '../../../../resources/defaultArrayStateForReducer';

import ItemWithDeleteButton from '../../../../Components/ItemWithDeleteButton';
import getSubscribes from '../fetch/getSubscribes';
import addSubscribe from '../fetch/addSubscribe';
import deleteSubscribe from '../fetch/deleteSubscribe';
import CheckBoxList from '../CheckBoxList';

const DELIVERY_TYPE = 'adminsEmails';

const EmailSettings = (props) => {
  const context = useContext(Context);
  
  const [emails, emailsDispatch] = useReducer(addressesReducer , {...defaultArrayStateForReducer , newValue: ''});
    
  const getValues = async () => {
    emailsDispatch({
      type: 'START_WAITING'
    });
    const resultSubscribes = await getSubscribes({
      token: context.token,
      deliveryType: DELIVERY_TYPE
    });
    emailsDispatch({
      type: 'GET_STATE', 
      payload: {...resultSubscribes}
    });
  };

  useEffect(()=>{
    getValues();
  },[]);

  const handleChangeNewInput = (e) => {
    let newErrTxt = '';
    let newDisableSaveButton = false;
    if (!validateEmail(e.target.value)){
      newErrTxt = 'введите существующий email';
      newDisableSaveButton = true;
    }
    
    emailsDispatch({
      type: 'HANDLE_CHANGE_NEW_VALUE', 
      payload: {   
        value: e.target.value,
        error: newErrTxt,
        isDisableSaveButton: newDisableSaveButton,
      }
    });
  };

  const handleClickSaveEmailButton = async() => {
    emailsDispatch({
      type: 'START_WAITING'
    });
    const result = await addSubscribe({
      token: context.token,
      deliveryType: DELIVERY_TYPE,
      address: emails.newValue,
      //address: state.newValue ? state.newValue : action.payload.value,
    });
    emailsDispatch({
      type: 'ADD_NEW_VALUE', 
      payload: {newValue: '', ...result}
    });
  };

  const onClickDeleteButton = async(id) => {
    emailsDispatch({
      type: 'START_WAITING'
    });
    const result = await deleteSubscribe ({
      token: context.token,
      deliveryType: DELIVERY_TYPE,
      id:id
    });
    emailsDispatch({
      type: 'DELETE',
      payload: {...result}
    });
  };


  return(
    <Paper 
      elevation={4} 
      sx={{
        display: 'flex',
        flexDirection: 'column',
        padding: 4,
        margin: 1,
        minWidth: 300,
        maxWidth: 550
      }}>
      
      <h3>электронная почта</h3>
      <Typography>
      Раздел управления доставки уведомлений на электронную почту.
      Можно получать уведомния на этот аккаут на несколько почтовых ящиков.
      </Typography>
      <div style={{...stylesObj.simpleRowWrap, alignItems: 'flex-start'}}>
        <TextField
          label = "Новый email"
          inputProps={{ inputMode: 'email', pattern: '[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$' }}
          id = "standard-size-small"
          variant = "outlined"
          sx = {{...stylesObj.margin8, flex: 1}}
          onChange = {handleChangeNewInput}
          value = {emails.newValue}
          error = {!!emails.error}
          helperText = {emails.error}
        />
        <LoadingButton 
          variant="contained"
          color="success"
          disabled={emails.isDisableSaveButton}
          loading={emails.isWaiting}
          loadingPosition="end"
          endIcon={<SaveIcon/>}
          onClick={handleClickSaveEmailButton }
          sx={stylesObj.margin8}
        >
          Добавить
        </LoadingButton>
      </div>
      
      <Typography>
      Уведомления приходят на следующие почты:
      </Typography>

      {emails?.data?.map((item)=>
        <ItemWithDeleteButton
          key = {item.id}
          text = {item.email}
          id = {item.id}
          isWaiting = {emails.isWaiting}
          onClickDeleteButton = {onClickDeleteButton}
        />
      )}


      <Typography>
      Настройки уведомлений работают одинаково на все указанные выше почтовые ящики.
      </Typography>
       
      <CheckBoxList deliveryType = {DELIVERY_TYPE} />

    </Paper>
  );
  

};

export default EmailSettings;