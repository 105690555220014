import copyObj from '../../../functions/copyObj';

const toggleActive = (data, toggleDate) =>{
  let newData = [];
  data.map(day => {
    let newDay = copyObj(day);
    if (+newDay.date === +toggleDate){
      newDay.isActive = newDay.isActive ? 0 : 1;
    }
    newData.push(newDay);
  });
  return newData;

};

const daysForStatisticReducer = (state, action) =>{
  switch (action.type) {
    case 'START_WAITING': {
      return {...state, isWaiting: true, isDisableSaveButton: true, error: ''};
    }
    case 'GET_STATE': {
      return {...state, 
        isWaiting: false, 
        isDisableSaveButton: false,
        isPredictionTaken: false, 
        error: '',
        ...action.payload };  
    }   
    case 'SET_ERROR': {
      return {...state, isWaiting: false, isDisableSaveButton: true, error: action.payload.error};
    }
    case 'HANDLE_CLICK_SAVE_BUTTON': {
      return {...state, 
        isWaiting: true, 
        isDisableSaveButton: true,
        ...action.payload};  
    }  
    case 'TOGGLE':
      return {
        ...state,
        isDisableSaveButton: false,
        isPredictionTaken: false,
        data: toggleActive(state.data, action.payload.date)
      };
    case 'TAKEN_ON':
      return {...state, 
        isPredictionTaken: true, 
        isDisableSaveButton: true,
        isWaiting: false,};
    case 'TAKEN_OFF':
      return {...state, 
        isPredictionTaken: false, 
        isDisableSaveButton: false,};
    default:
      throw Error('Unknown action: ' + action.type);
  }
};
export default daysForStatisticReducer;