import React, { useContext, useState } from 'react';
import { Context } from '../../../functions/context';
import TextField from '@mui/material/TextField';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormHelperText from '@mui/material/FormHelperText';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { CircularProgress } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import axi from '../../../functions/axiosf';
import validateTextInput from '../../../functions/validateTextInput';

const NewUserForm = (props) => {
  const context = useContext(Context);
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState('');
  const [pass, setPass] = useState('');
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [passError, setPassError] = useState('');
  const [category, setCategory] = useState('cashiers');
  const [point, setPoint] = useState(0);

  const [isWaiting, setIsWaiting] = useState(false);

  const handleChangeName = (event) => {
    let newName = event?.target?.value;
    let [textError, isSetState] = validateTextInput(newName, 'имя');
    isSetState && setName(newName);
    setNameError(textError);
  };

  const handleChangePass = (event) => {
    let newPass = event?.target?.value;
    let [textError, isSetState] = validateTextInput(newPass, 'пароль');
    isSetState && setPass(newPass);
    setPassError(textError);
  };

  const handleSelectCategory =(event)=>{
    setCategory(event?.target?.value);
  };

  const handleSelectPoint =(event)=>{
    setPoint(+event?.target?.value);
  };

  const handleClickShowPassword = () => {
    setIsShowPassword(!isShowPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
 
  //функция добавления товара в список в backend
  const handleClickAdd = async() => {
    let postParams = { 
      token: context.token, 
      Name: name,
      password: pass,
      role: category,
    };
    if (point){
      postParams={...postParams, point: point};
    }
    setIsWaiting(true);
    try {
      let result = await axi(
        'admin/addNewUser.php', 
        '', 
        postParams,
      ); 
      if (typeof context.setAllBase === 'function'){ 
        context.setAllBase(result);
      }
    } catch (e) {
      console.log(e);
    }
    setIsWaiting(false);
    setName('');
    setPass('');
  };

  return(
    <div style={{display: 'flex', flexWrap: 'wrap'}}>
      <FormControl sx={{
        m: 1, 
        width: '25ch',
        width:'200px', 
        margin: '8px', 
        transition: '1s',
      }} 
      variant="outlined">
        <TextField
          label="Имя"
          variant="outlined"
          id="standard-size-small"
          defaultValue=""
          onChange={handleChangeName}
          value={name}
          error={nameError}
          helperText={nameError}
        />
      </FormControl>

      <FormControl sx={{
        m: 1, 
        width: '25ch',
        width:'200px', 
        margin: '8px', 
        transition: '1s',
      }} 
      variant="outlined">
        <InputLabel htmlFor="outlined-adornment-password">Пароль</InputLabel>
        <OutlinedInput
          label="Пароль"
          variant="outlined"
          id="standard-size-small"
          onChange={handleChangePass}
          type={isShowPassword ? 'text' : 'password'}
          value={pass}
          error={passError}
          helperText={passError}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {isShowPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
        />
        <FormHelperText error>{passError}</FormHelperText>
      </FormControl>
            
      <FormControl sx={{width:'200px', margin: '8px'}}>
        <InputLabel id="demo-simple-select-label">Роль</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={category}
          label="Роль"
          onChange={handleSelectCategory}
        >
          <MenuItem
            key={0}
            value="cashiers"
            
          >Кассир</MenuItem>
          <MenuItem
            key={1}
            value="admins"
          >Админ</MenuItem>
        </Select>
      </FormControl>
      {category==='cashiers' ?
        <FormControl sx={{width:'200px', margin: '8px'}}>
          <InputLabel id="demo-simple-select-label">Адрес</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={point}
            label="Категория"
            onChange={handleSelectPoint}
          >
            {context.salePoints.map((item, index)=> 
              <MenuItem
                key={item.id}
                value={item.id}
              >{item.name}</MenuItem>
            )}
          </Select>
        </FormControl>
        : null
      }

      {isWaiting ?
        <CircularProgress 
          color="success" 
          style={{
            margin: '8px', 
            width: '35px',
            height: '35px'
          }} 
        />
        :
        <IconButton 
          disabled={nameError || passError}
          onClick={handleClickAdd}>
          <AddCircleIcon 
            fontSize='large' 
            color={(nameError || passError)?'error':'success'} 
          />
        </IconButton>
      }
    </div>
  );
};

export default NewUserForm;