/* eslint-disable eqeqeq */
import React, { useContext, useEffect, useState, useRef, Fragment } from 'react';

import Autocomplete from '@mui/material/Autocomplete';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ruLocale from 'date-fns/locale/ru';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Slider from '@mui/material/Slider';
import TextField from '@mui/material/TextField';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import {stylesObj} from '../../../stylesObj/stylesObj';

import { Context } from '../../../functions/context';
import addLeftZeroIfLess10 from '../../../functions/addLeftZeroIfLess10';
import propsF from '../../../functions/propsF';
import styles from '../index.module.css';



const ChecksFilters = ({
  labelTextAll,

  point, setPoint,
  kkt, setKkt,
  category, setCategory,
  timeFromTo, setTimeFromTo,
  nomenclature, setNomenclature,
  nomenclatureInput, setNomenclatureInput,
  nomenclatureOptions, setNomenclatureOptions,
  
  nomenclatureItemAll,

}) => {
  const context = useContext(Context);

  const [timeFromToTemp,setTimeFromToTemp] = useState([0, 24]);
  
  const lowFrequencyCall=(milliSeconds)=>{
    let intervalId;
    return (someFunction,args)=>{
      if (intervalId!==undefined) {
        clearTimeout(intervalId);
        intervalId=undefined;
      }
      intervalId=setTimeout(someFunction,milliSeconds,args);
    };
  };
  let waitFunction=lowFrequencyCall(250);

  // const someFunction=(text) =>{
  //   console.log(text);
  // };

  const makeNomenclatureOptions = (newCategory) => {
    let options=context.nomenclature
      .filter((item) =>
        newCategory === 0 || +item.type === newCategory ? true : false
      )
      .map((item) => {
        return { label: item.name, id: +item.id };
      })
      .sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
    options.unshift(nomenclatureItemAll);
    return options;
  };


  const handleChangePoint = (event) => {
    let selectedPoint = event?.target?.value;
    // console.log('point', selectedPoint);
    propsF(setPoint,selectedPoint);
    if (selectedPoint === 0) {
      propsF(setKkt,0);
    } else {
      let firstKkt = context.kkts.find(
        (kktItem) => kktItem.salePoint === selectedPoint
      );
      // console.log('firstKkt', firstKkt);
      if (firstKkt !== undefined) {
        propsF(setKkt,firstKkt.KktRegId);
      } else {
        propsF(setKkt,'');
      }
    }
  };

  const handleChangeKkt = (event) => {
    let selectedKkt = event?.target?.value;
    // console.log('kkt', selectedKkt);
    propsF(setKkt,selectedKkt);
    
  };

  
  const handleChangeCategory = (event) => {
    let newCategory = +event?.target?.value;
    // console.log(newCategory);
    propsF(setNomenclatureOptions,makeNomenclatureOptions(newCategory));
    if (newCategory !== 0) {
      propsF(setNomenclatureInput,'');
      propsF(setNomenclature,nomenclatureItemAll);
    }
    propsF(setCategory,newCategory);
  };


  
  const valueLabelFormat=(value) =>{
    return value !== 24 ? addLeftZeroIfLess10(value).concat(':00') : '23:59';
  };
  
  


  const handleChangeTimeFromTo = (event) => {
    setTimeFromToTemp(event?.target?.value);
    if (typeof setTimeFromTo === 'function') {
      waitFunction((arg)=> {
        setTimeFromTo(arg);
      },
      event?.target?.value
      );
    }
    // propsF(setTimeFromTo,event?.target?.value);
  };

  const handleChangeNomenclature = (event, newValue) => {
    // console.log('nomenclature', newValue);
    propsF(setNomenclature,newValue);
  };

  const handleChangeNomenclatureInput = (event, newValue) => {
    // console.log('nomenclatureInput', newValue);
    propsF(setNomenclatureInput,
      newValue===null ? nomenclatureItemAll : newValue);
  };

  useEffect(() => {
    let nopt = makeNomenclatureOptions(category);
    // console.log('nomenclat.options',nopt);
    setNomenclatureOptions(nopt);
  },[context.nomenclature,]);

  return (
    <Fragment>

      <FormControl style={stylesObj.selectFilter}>
        <InputLabel id='demo-simple-select-label'>
          Торговая Точка
        </InputLabel>
        <Select
          labelId='demo-simple-select-label'
          id='demo-simple-select'
          value={point}
          label='Торговая Точка'
          onChange={handleChangePoint}
        >
          <MenuItem key={0} value={0}>
            {labelTextAll}
          </MenuItem>
          {context.salePoints.map((item, index) => (
            <MenuItem key={index} value={item.id}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl style={stylesObj.selectFilter}>
        <InputLabel id='demo-simple-select-label'>ККТ</InputLabel>
        <Select
          labelId='demo-simple-select-label'
          id='demo-simple-select'
          value={kkt}
          label="ККТ"
          onChange={handleChangeKkt}
          //style = {{maxWidth: document.innerWidth - 180}}
        >
          {point === 0 ? 
            <MenuItem 
              key={0} 
              value={0}     
              //style = {{maxWidth: document.innerWidth - 180}}
            >
              {labelTextAll}
            </MenuItem>
            : 
            context.kkts.map((item, index) =>
              item.salePoint === point ? (
                <MenuItem 
                  key={index} 
                  value={item.KktRegId} 
                  style = {{
                    //width: '200px',
                    overflowX: 'hidden'
                  }} 
                >
                  <div 
                    
                  >
                    {item.KktRegId}
                    <br />
                    <b>{item.FiscalPlace}</b>
                    <br />
                    <div 
                      style = {{
                        display: 'contents',
                        width: '236px',
                        height: 'auto',
                        overflowWrap: 'normal',
                        whiteSpace: 'normal',
                        color: '#333',
                        fontSize: 13
                      }}>
                      {item.FiscalAddress}
                    </div>
                  </div>
                </MenuItem>
              ) : null
            )
          }
        </Select>
      </FormControl>

      <FormControl style={stylesObj.selectFilter}
      >
        <InputLabel id='demo-simple-select-label'>Категория</InputLabel>
        <Select
          
          labelId='demo-simple-select-label'
          id='demo-simple-select'
          value={category}
          label='Категория'
          onChange={handleChangeCategory}
        >
          <MenuItem key={0} value={0}>
            {labelTextAll}
          </MenuItem>
          {context.types.map((item, index) => (
            <MenuItem
              key={item.id}
              value={item.id}
              style={{
                backgroundColor: item.color,
              }}
            >
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      

      <Slider className={styles.selectSlider}
        // transitionduration={{ enter: 0, exit: 0 }}
        transitionduration={0}
        area-label='Время'
        value={timeFromToTemp}
        onChange={handleChangeTimeFromTo}
        valueLabelFormat={valueLabelFormat}
        valueLabelDisplay='on'
        disableSwap
        defaultValue={[0, 24]}
        min={0}
        step={1}
        max={24}
        marks={[
          { value: 0, label: '00:00' },
          { value: 1 },
          { value: 2 },
          { value: 3 },
          { value: 4 },
          { value: 5 },
          { value: 6, label: '06:00' },
          { value: 7 },
          { value: 8 },
          { value: 9 },
          { value: 10 },
          { value: 11 },
          { value: 12, label: '12:00' },
          { value: 13 },
          { value: 14 },
          { value: 15 },
          { value: 16 },
          { value: 17 },
          { value: 18, label: '18:00' },
          { value: 19 },
          { value: 20 },
          { value: 21 },
          { value: 22 },
          { value: 23 },
          { value: 24, label: '23:59' },
        ]}
      />

      <Autocomplete style={stylesObj.selectFilter}
        selectOnFocus
        clearOnBlur
        disablePortal
        id='combo-box-demo'
        value={nomenclature}
        onChange={handleChangeNomenclature}
        inputValue={nomenclatureInput}
        onInputChange={handleChangeNomenclatureInput}
        options={nomenclatureOptions}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        
        renderInput={(params) => (
          <TextField {...params} label='Название товара' />
        )}
      />
    </Fragment>
  );
};  
        
        
export { ChecksFilters };
