const checkInfoFields=[
  ['Чек','Id'],
  ['FnNumber','FnNumber'],
  ['DecimalFiscalSign','DecimalFiscalSign'],
  ['Depth','Depth'],
  ['DocNumber','DocNumber'],
  ['DocShiftNumber','DocShiftNumber'],
  ['CDateUtc','CDateUtc','date'],
  ['DocDateTime','DocDateTime','date'],
  ['IsBso','IsBso'],
  ['IsCorrection','IsCorrection'],
  ['KktRegNumber','KktRegNumber'],
  ['Nds00_TotalSumm','Nds00_TotalSumm','sum'],
  ['NdsNA_TotalSumm','NdsNA_TotalSumm','sum'],
  ['OperationType','OperationType'],
  ['PrepaidSumm','PrepaidSumm','sum'],
  ['ProvisionSumm','ProvisionSumm','sum'],
  ['ReceiptNumber','ReceiptNumber'],
  ['Tag','Tag'],
  ['Tax0Summ','Tax0Summ','sum'],
  ['Tax10Summ','Tax10Summ','sum'],
  ['Tax18Summ','Tax18Summ','sum'],
  ['Tax110Summ','Tax110Summ','sum'],
  ['Tax118Summ','Tax118Summ','sum'],
  ['TaxNaSumm','TaxNaSumm','sum'],
  ['TaxTotalSumm','TaxTotalSumm','sum'],
  ['TotalSumm','TotalSumm','sum'],
  ['UserInn','UserInn'],
];


export default checkInfoFields;