import React, { Fragment, useContext, useEffect, useState } from 'react';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ruLocale from 'date-fns/locale/ru';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import Paper from '@mui/material/Paper';

import axi from './../../functions/axiosf';

import style from './index.module.css';

import TypesGroupsEditKit, { OneCheckMotivations } from './MotivationLists/OneCheck';

import { Context } from '../../functions/context';
import { ShelfPositionMotivations } from './MotivationLists/ShelfPosition';
import { ShelfCategoryMotivations } from './MotivationLists/ShelfCategory';

const Motivation = () => {
  const context = useContext(Context);
  const [point, setPoint] = useState(0);
  const [product, setProduct] = useState(null);
  const [type, setType] = useState(0);
  const [isWaiting, setIsWaiting] = useState(false);

  const handleChangePoint = (event) => {
    let newPointId = event?.target?.value;
    setPoint(newPointId);
  };
  
  const handleChangeProduct = (event) => {
    let newProductId = event?.target?.value;
    setProduct(newProductId);
  };

  const handleChangeType = (event) => {
    let newTypeId = event?.target?.value;
    setType(newTypeId);
  };

  useEffect(()=>{
    context.axiGetAllBase();
  },[]);

  return(
    <div className={style.verticalPage}>
      <div 
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          maxWidth: 600,
        }}
      >
        <FormControl style={{flex: 1, minWidth: '200px', margin: 16}}>
          <InputLabel id="demo-simple-select-label">Адрес</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={point}
            label="Адрес"
            onChange={handleChangePoint}
          >
            <MenuItem 
              key={0} 
              value={0}>
              Все
            </MenuItem>
            {context.salePoints.map((item, index)=>
              <MenuItem 
                key={index} 
                value={item.id}>
                {item.name}
              </MenuItem>)}
          </Select>
        </FormControl>
        {point ?
          <OneCheckMotivations
            point={point}
          />
          : ''
        }

        
        <FormControl style={{flex: 1 , minWidth: '200px', margin: 16}}>
          <InputLabel id="demo-simple-select-label">Категория</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={type}
            label="Категория"
            onChange={handleChangeType}
          >
            {context.types.map((item, index)=>
              <MenuItem 
                key={index} 
                value={item.id}>
                {item.name}
              </MenuItem>)}
          </Select>
        </FormControl>
        {/* 
        <FormControl style={{flex: 1 , minWidth: '200px', margin: 16}}>
          <InputLabel id="demo-simple-select-label">Товар</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={product}
            label="Товар"
            onChange={handleChangeProduct}
          >
            {context.nomenclature.map((item, index)=>
              <MenuItem 
                key={index} 
                value={item.id}>
                {item.name}
              </MenuItem>)}
          </Select>
        </FormControl>
         */}
        {point && type ?
          <ShelfCategoryMotivations
            point={point}
            type={type}
          />
          : ''
        }


      </div>

    </div>
  );
};


export { Motivation };