import * as React from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { stylesObj } from '../../../stylesObj/stylesObj';
import { Context } from '../../../functions/context';
import { Fragment } from 'react';




const Row = (props) => {
  const context = React.useContext(Context);

  const handleChangeQuantity = (e) => {
    if(typeof props.onChangeQuantity === 'function'){
      props.onChangeQuantity( props.item.id, e.target.value);
    };
  };

  
  const handleChangeComment = (e) => {
    if(typeof props.onChangeComment === 'function'){
      props.onChangeComment(props.item.id, e.target.value);
    };
  };

  const getComment=(item)=>{
    // console.dir(item);
    // console.dir(props.invoice);
    let itemComment=
      props.invoice.find((invoice)=>invoice.id===item.id)?.comment;
    return (
      typeof itemComment==='string' ? itemComment : ''
    );
  };

  const comment=()=>{
    let itemComment=getComment(props.item);
    let isCommentEmpty = (itemComment=== '' && props.item?.quantity > 0);
    return <TextField
      id="outlined-number1"
      value={itemComment}
      placeholder = "комментарий"
      type="text"
      onChange={handleChangeComment}
      InputLabelProps={{
        shrink: true,
      }}
      size="small"
      sx={{
        width: '100%', 
        backgroundColor: '#FEDDC9', 
        borderColor: isCommentEmpty ? '#f33' : null,
        borderRadius: '6px',
        borderStyle: isCommentEmpty ? 'solid' : null,
        borderWidth: isCommentEmpty ? '2px' : null,
      }}
    />;
  };

  return (                    
    <Fragment>
      <TableRow sx={{backgroundColor: props.backgroundColor, ...stylesObj.TableContainer}}>
        <TableCell component="th" scope="row">
          {props.item.name}
        </TableCell>
        <TableCell sx={stylesObj.TableCellMinPadding} align="right">
          {props.item?.yesterdayQuantity && props.item?.coefficient ? 
            //&& !(+props.item?.isManual) - это другой совершенно параметр, который указывает, что товар создан из кассы, если 0
            <span>{props.item.yesterdayQuantity} X {props.item.coefficient} = </span>
            :
            ''
          }
          <TextField
            id="outlined-number"
            value={props.item.quantity}
            type="number"
            onChange={handleChangeQuantity}
            onFocus={e => e.target.select()}
            InputLabelProps={{
              shrink: true,
            }}
            size="small"
            sx={{width: '120px', backgroundColor: '#A8E9B133'}}
            disabled={props.isDownloadWaiting}
            inputProps={{min: 0, style: { textAlign: 'right' }}}
          />
        </TableCell>
      </TableRow>
      {/* {props.item.quantity > 0 &&  */}
      <TableRow>
        <TableCell 
          style={{ paddingBottom: 0, paddingTop: 0 }} 
          colSpan={6}
        >
          <Collapse 
            in={props.item.quantity > 0}
            timeout="auto" 
            unmountOnExit>
            <Box sx={{ margin: 1 }}>
              {comment()}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      {/* } */}
    </Fragment>
  );
};

export default Row;