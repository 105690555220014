import React, {useState, useEffect, useContext, Fragment} from 'react';
import TextField from '@mui/material/TextField';
import PointsTable from './PointsTable/index';
import IconButton from '@mui/material/IconButton';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import ruLocale from 'date-fns/locale/ru';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';


import axi from '../../functions/axiosf';
import { Context } from '../../functions/context';
import { CircularProgress } from '@mui/material';
import validateTextInput from '../../functions/validateTextInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const NewPointForm = (props) => {
  const context = useContext(Context);

  const [pointName, setPointName] = useState('');
  const [nameError, setNameError] = useState('');
  const [pointAddress, setPointAddress] = useState('');
  const [pointKKT, setPointKKT] = useState('');
  const [timeOpen, setTimeOpen] = useState(0);
  const [timeClose, setTimeClose] = useState(0);

  const [isWaiting, setIsWaiting] = useState(false);

  const handleChangePointName = (event) => {
    let newName = event?.target?.value;
    let [textError, isSetState] = validateTextInput(newName, 'название точки');
    isSetState && setPointName(newName);
    setNameError(textError);
  };

  const handleChangePointAddress = (event) => {
    setPointAddress(event.target.value);
  };

  const handleChangeKKt = (event) => {
    let newKKT = event.target.value;
    setPointKKT(newKKT);
  };
  
  const handleSetNewTimeOpen =(event)=>{
    setTimeOpen((event.$H*60)+event.$m);
  };
  
  const handleSetNewTimeClose =(event)=>{
    setTimeClose((event.$H*60)+event.$m);
  };
  

  //функция добавления точки в список
  const handleClickAddPoint = async() => {
    setIsWaiting(true);
    try {
      let result = await axi(
        'admin/salePoints/addNewSalePoint.php', 
        '', 
        { 
          token: context.token, 
          newPointName: pointName,
          newPointAddress: pointAddress,
          KktRegId: pointKKT,
          timeOpen: timeOpen,
          timeClose: timeClose,
        }); 
      if (typeof context.setAllBase === 'function'){ 
        context.setAllBase(result);
      }
    } catch (e) {
      console.log(e);
    }
    setIsWaiting(false);
    setPointName('');
    setPointAddress('');
    setPointKKT('');
    setTimeOpen(0);
    setTimeClose(0);
  };

  return(
    <Fragment>
      <h4 style={{marginTop: '-10px'}}>Введите название и адрес точки, чтобы добавить её в список</h4>
      <div style={{display: 'flex', flexWrap: 'wrap'}}>  
        <TextField
          label="Название"
          id="standard-size-small"
          defaultValue=""
          variant="outlined"
          sx={{width:'200px', margin: '8px'}}
          onChange={handleChangePointName}
          value={pointName}
          error={nameError}
          helperText={nameError}
        />
                &nbsp;&nbsp;&nbsp;
        <TextField
          label="Адрес"
          id="standard-size-normal"
          defaultValue=""
          variant="outlined"
          sx={{flex:1, minWidth: '300px', margin: '8px'}}
          onChange={handleChangePointAddress}
          value={pointAddress}
        />
        <LocalizationProvider 
          dateAdapter={AdapterDayjs}
          adapterLocale={ruLocale}
        >
          <TimePicker
            label="начало"
            value={(timeOpen + new Date().getTimezoneOffset()) *60*1000}
            onChange={handleSetNewTimeOpen}
            renderInput={(params) => <TextField style={{width: 120, margin: 8}} {...params} />}
            
          />
        </LocalizationProvider>
        <LocalizationProvider 
          dateAdapter={AdapterDayjs}
          adapterLocale={ruLocale}
        >
          <TimePicker
            label="окончание"
            value={(timeClose + new Date().getTimezoneOffset()) *60*1000}
            onChange={handleSetNewTimeClose}
            renderInput={(params) => <TextField style={{width: 120, margin: 8}} {...params} />}
            
          />
        </LocalizationProvider>

        <FormControl style={{flex: 1 , minWidth: '200px', margin: 8}}>
          <InputLabel id="demo-simple-select-label">Касса</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={pointKKT}
            label="Касса"
            onChange={handleChangeKKt}
          >
            {props.availableKKTs.map((item, index)=>
              <MenuItem 
                key={index} 
                value={item.KktRegId}>
                №: {item.KktRegId}<br/>
                {item.FiscalPlace+'  '+item.KktName}<br/>
                {item.FiscalAddress}
              </MenuItem>)}
          </Select>
        </FormControl>
        &nbsp;&nbsp;&nbsp;&nbsp;
        {isWaiting || props.isWaiting ?
          <CircularProgress 
            color="secondary" 
            style={{
              margin: '8px', 
              width: '35px',
              height: '35px'
            }} 
          />
          :
          <IconButton
            disabled={nameError || !pointName || !timeOpen || !timeClose}
          >
            <AddCircleIcon 
              fontSize='large' 
              color={(nameError||!pointName) ? 'error' : 'secondary'}
              onClick={handleClickAddPoint}/>
          </IconButton>
        }
      </div>
    </Fragment>
  );
};


export { NewPointForm };