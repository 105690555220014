/* eslint-disable eqeqeq */
import React, { useContext, useEffect, useState, useRef, Fragment } from 'react';

import Typography from '@mui/material/Typography';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';

import axi from '../../../functions/axiosf';

import {stylesObj} from '../../../stylesObj/stylesObj';

import { Context } from '../../../functions/context';
import { CircularProgress } from '@mui/material';
import moment from 'moment';
import hourMinFromMinutes from '../../../functions/hourMinFromMinutes';

const ViolationStartItem = (props) => {
  const context = useContext(Context);
  
  const renderCashierName = (id) => {
    let cashiersArray = context.cashiers.filter((sp) => sp.id == id);
    return(cashiersArray[0]?.name);
  };

  const renderProductName = (id) => {
    let goodsArray = context.nomenclature.filter((sp) => sp.id == id);
    return(goodsArray[0]?.name);
  };

  //let startTimeInDayMin = (+moment(props.invoice?.time*1000).format('HH')*60) + (+moment(props.invoice?.time*1000).format('mm'));
  //let endTimeInDayMin = (+moment(props.closeInvoice?.time*1000).format('HH')*60) + (+moment(props.closeInvoice?.time*1000).format('mm'));
 
   
  return(
  
    <TableRow> 
    
      <TableCell component="th" scope="row" align="left">{typeof props.pointsObj[+props.violation?.salePoint] === 'object' ? props.pointsObj[+props.violation?.salePoint].name : ''}</TableCell>   
                    
      <TableCell component="th" scope="row" align="left">{renderCashierName(props.violation?.cashier)}</TableCell>
      <TableCell component="th" scope="row" align="left">{renderProductName(props.violation?.product)}</TableCell>    
       
      <TableCell component="th" scope="row" align="right">
        {hourMinFromMinutes(props.violation?.planStartTime)}
      </TableCell> 
      <TableCell component="th" scope="row" align="left"
        sx={{backgroundColor: '#f99' }}
      >{hourMinFromMinutes(props.violation?.factStartTime)}</TableCell>       
           
    </TableRow>
    
  );
  
};

export { ViolationStartItem };