import * as React from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';

import Button from '@mui/material/Button';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { Context } from '../../../functions/context';


export default function NomenclatureRow(props) {
  const context = React.useContext(Context);
  const [categoryColor, setCategoryColor] = React.useState('');
  const [categoryName, setCategoryName] = React.useState('');
  let {item} = props;
  const handlePressUpdate = () => {
    if (typeof props.onOpenModal === 'function'){
      // props.onOpenModal(props.item);
      props.onOpenModal(props.index);
    }
  };

  React.useEffect(()=>{
    context.types.map((type)=>{
      if(type.id === item.type){
        setCategoryColor(type.color);
        setCategoryName(type.name);
      }
    });
  }, [item]
  );

  return(
    <TableRow sx={{backgroundColor: `${categoryColor}`}} hover>
      <TableCell component="th" scope="row">
        {item.name}
      </TableCell>
      <TableCell component="th" scope="row">
        {categoryName}
      </TableCell>
      <TableCell align="right">
        <div style={{display:'flex', justifyContent:'flex-end'}}>
          {/* я думаю, что кнопка для удаления не нужна совсем
                    <IconButton onClick={() => handleRemoveCategory(item.id)} style={{color: 'black'}}>
                      <ClearIcon />
                    </IconButton>
                     */}
          <IconButton 
            variant="contained" 
            onClick={handlePressUpdate}>
            <BorderColorIcon/>
          </IconButton>
        </div>
      </TableCell>
    </TableRow>     
  );     
};
