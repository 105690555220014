import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { CSVLink, CSVDownload } from 'react-csv';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ruLocale from 'date-fns/locale/ru';
import moment from 'moment';
import 'moment/locale/ru';
import { CircularProgress, Divider, ListItemSecondaryAction } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import Send from '@mui/icons-material/Send';
import { Context } from '../../../functions/context';
import copyObj from '../../../functions/copyObj';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 360,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  maxHeight: '90vh',
  overflowX: 'hidden',
  overflowY: 'scroll',
};

const CSVheaders = [
  { label: 'Номенклатура', key: 'name' },
  { label: 'Характеристика', key: 'props' },
  { label: 'Категория', key: 'type' },
  { label: 'Количество', key: 'quantity' },
  { label: 'Единица измерения', key: 'unit' },
  { label: 'ЭтоУслуга', key: 'isService' }
];

moment.locale('ru'); 

export default function SendingProductModal(props) {
  
  const context = React.useContext(Context);
  const [open, setOpen] = React.useState(false);
  const [csvArray, setCSVArray] = React.useState([]);
  const [csv1cObj, setCSV1cObj] = React.useState([]);
  const [dateTime, setDateTime] = React.useState(+new Date());
  const handleSetDateTime = (newDateTime) => {
    setDateTime(+newDateTime);
  };
  const csvLinkRef = React.useRef();
  const csvLink1cRef = React.useRef();
  const handleOpen = () => setOpen(true);
  const handleClose = () =>{
    setOpen(false);
    if(typeof props.onSetIsModalOpen === 'function'){
      props.onSetIsModalOpen(false);
    }
  };

  const getCategoryName = (typeId) => {
    // читаем имя этого типа из категории
    let itemRows = context.types.filter(
      (typesItem)=> +typesItem.id === +typeId
    );
    if (itemRows.length > 0){
      let categoryRow = itemRows[0];
      return(categoryRow.name);
    }else{
      
      return('');
    }
  };

  React.useEffect(()=>{
    setOpen(props.isOpen);
  },[props.isOpen]);

  React.useEffect(()=>{
    let csv1cArr = copyObj(props.cleanInvoice);
    
    // console.log(props.cleanInvoice);
    let currentPoint = context.salePoints.filter((point)=>point.id === props.point);
    let currentAdmin = context.admins.filter((a)=>a.token === context.token);
    let newCSVArray = [
      ['дата',moment(dateTime).format('LLL')],
      ['',''],
      ['место отправки','склад'],
      ['место получения', currentPoint[0]?.name, currentPoint[0]?.address ],
      ['',''],
      ['Наименование','количество','комментарий'],
    ];

    // csv : array to get all positions by types/groups
    let csvGroups=[];

    props.cleanInvoice.map((item, index)=>{
      if(typeof item === 'object'){

        // csv : grouping positions
        //newCSVArray = [...newCSVArray, [item.name, item.quantity, item.comment]];
        let foundGroup=csvGroups.find((group)=>group[0]===item.type);
        // console.log('foundItem',foundItem);
        if (foundGroup!==undefined) {
          foundGroup[1].push(item);
        } else {
          let groupName=context.types.find((typeItem) => 
            item.type===typeItem.id);
          if (groupName===undefined) {
            groupName='без категории';
          } else {
            groupName=groupName.name;
          }
          csvGroups.push([
            item.type, // type.id
            [item], // position
            groupName.toUpperCase(), // type.name
          ]);
        }
    
        csv1cArr[index].props = '';
        csv1cArr[index].type = getCategoryName(item.type);
        csv1cArr[index].unit = 'шт';
        csv1cArr[index].isService = 'Нет';
      }
    });

    // csv : processing positions
    let total=0;
    csvGroups
      // sort by type
      .sort((a,b)=>a[2].localeCompare(b[2])) 
      .map((group) => { 
        let groupTotal=0;
        
        let positions=group[1]
          // sort positions by name
          .sort((a,b)=>a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
          // collect positions
          .map((position) => {
            groupTotal+= position.quantity;
            return [position.name, position.quantity, position.comment];
          });

        total+=groupTotal;

        newCSVArray = [
          ...newCSVArray, 
          ['','',''],
          [group[2]+` ( всего: ${groupTotal} )`,'',''],
          ...positions,
        ];
      });


    newCSVArray = [
      ...newCSVArray, 
      ['','------------------',''],
      ['ВСЕГО',`${total}`,''],
      ['',''],
      ['отправитель', currentAdmin[0]?.name],
      ['',''],
      ['получатель', ''],
    ];

    setCSVArray(newCSVArray);
    setCSV1cObj(csv1cArr);

  },[props.cleanInvoice]);


  const handleAccept = () => {
    let phpUnixTime = Math.round(dateTime/1000);
    if (typeof props.onPressConfirmSend === 'function'){
      props.onPressConfirmSend(phpUnixTime);
      csvLinkRef.current.link.click();
    }
  };



  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <LocalizationProvider 
              dateAdapter={AdapterDateFns}
              adapterLocale={ruLocale}
            >
              <DateTimePicker
                label="дата и время накладной"
                value={dateTime}
                onChange={handleSetDateTime}
                renderInput={(params) => <TextField {...params} />}
                disabled = {props.isWaiting }
                fullWidth
              />
            </LocalizationProvider>
            <table
              style={{width: '100%'}}
            >
              {props.cleanInvoice && props.cleanInvoice.map((item, index)=>
                <>
                  {index ? <Divider/> : null}
                  <tr 
                    key={item.name}
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      margin: '8px',
                    }}>
                    <td>{item.name}</td>
                    <td>{item.quantity}</td>
                  </tr>
                  <tr 
                    key={item.name+'_comment'}
                    style={{ margin: '8px',}}>
                    {item.comment}
                  </tr>
                </>
              )}    
            </table>
            <div style={{
              display:'flex',
              flexDirection: 'column',
              justifyContent: 'space-around',
              alignItems: 'center',
              marginTop:'10px',
              height: '190px',
            }}>
              <Divider/>
              <CSVLink  
                ref={csvLinkRef}
                data={csvArray}
                //data={props.cleanInvoice} 
                //headers={CSVheaders} 
                style={{marginBottom: 8}}
                filename={`накладная_${moment(dateTime).format('LLL')}.csv`}
                separator=";">
                  Скачать Excel накладную
              </CSVLink>          
              <Divider/>

              <CSVLink  
                ref={csvLink1cRef}
                data={csv1cObj}
                //data={props.cleanInvoice} 
                headers={CSVheaders} 
                filename={`export1c_${moment(dateTime).format('LLL')}.csv`}
                separator=";"
                style={{marginBottom: 8}}>
                  Скачать экспорт для 1С
              </CSVLink>    
              <Divider/>

              <LoadingButton 
                variant="contained"
                color="success"
                //disabled={props.isWaiting}
                loading={props.isWaiting}
                loadingPosition="end"
                endIcon={<Send/>}
                onClick={handleAccept}
                sx={{marginBottom: 2}}
              >
                Отправить накладную
              </LoadingButton>
              <Button 
                variant="contained"
                color="error"
                onClick={handleClose}>Вернуться к редактированию</Button>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}