import React, {useState, useContext, useEffect} from 'react';
import { Context } from '../../functions/context';
import NewNomenclatureForm from './NewNomenclatureForm/index.js';
import NomenclatureTable from './NomenclatureTable/index';
 

const Nomenclature = () => {
  const context = useContext(Context);


  const labelTextAll = 'Все';

  const [category,setCategory] = useState(0);
  const [categoryError, setCategoryError] = useState('');

  const [isCategoriesSorted, setIsCategoriesSorted] = useState(false);
  const [sortedNomenclature, setSortedNomenclature] = useState(false);


  useEffect(()=>{
    context.axiGetAllBase();

  },[]);
 
  if (!isCategoriesSorted
    && context.nomenclature?.length>0
    && context.types?.length>0) {
    setIsCategoriesSorted(true);
   
    let categories={'0':[]};
    context.types.map((item)=>{
      categories[item.id]=[];
    });

    context.nomenclature.map((item)=>{
      categories[item.type].push(item);
    });

    let newNomenclature=[];
    for (let key in categories) {
      // if (key!=='0') {
      newNomenclature.push(
        ...categories[key].sort((a,b)=>a.name.toLowerCase().localeCompare(b.name.toLowerCase())));
      // }
    };

    // newNomenclature.push(
    //   ...categories['0'].sort((a,b)=>a.name.localeCompare(b.name)));

    setSortedNomenclature(newNomenclature);
    // console.log(newNomenclature);
  }
  
  return(
    <div>
    
      <div>
        <h4 style={{marginTop: '-10px'}}>
          Введите название и категорию, чтобы добавить новую номенклатурную позицию в список
        </h4>
        
        <NewNomenclatureForm
          labelTextAll={labelTextAll}
          category={category}
          setCategory={setCategory}
          categoryError={categoryError}
          setCategoryError={setCategoryError}
          setIsCategoriesSorted={setIsCategoriesSorted}
        />

        <NomenclatureTable
          category={category}
          sortedNomenclature={sortedNomenclature}
          setIsCategoriesSorted={setIsCategoriesSorted}
        />
      </div>
      
    </div>
  );
};


export { Nomenclature };