import React, {useState, useContext, useEffect} from 'react';
import TextField from '@mui/material/TextField';
import CategoriesTable from './CategoriesTable/index';
import IconButton from '@mui/material/IconButton';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { CircularProgress } from '@mui/material';
import axi from '../../functions/axiosf';
import { Context } from '../../functions/context';
import generatePaletteColor from '../../functions/generatePaletteColor';
import ColorPicker from './../../Components/ColorPicker';
import validateTextInput from '../../functions/validateTextInput';
 
const Categories = () => {
  const context = useContext(Context);
  const [categoryName, setCategoryName] = useState('');
  const [nameError, setNameError] = useState('');
  const [newColor, setNewColor] = React.useState('#46f66699');
  const [isWaiting, setIsWaiting] = useState(false);

  let palette = generatePaletteColor();

  const handleChangeCategoryName = (event) => {
    let newCategoryName = event.target.value;
    let [textError, isSetState] = validateTextInput(newCategoryName, 'название категории');
    isSetState && setCategoryName(newCategoryName);
    setNameError(textError);
  };

  const onSelectColor =(color)=>{
    setNewColor(color);
  };
 
  //функция добавления категории в список в backend
  const handleClickAdd = async() => {
    setIsWaiting(true);
    try {
      let result = await axi(
        'admin/addNewProductsType.php', 
        '', 
        { 
          token: context.token, 
          newTypeName: categoryName,
          newTypeColor: newColor
        }); 
      if (typeof context.setAllBase === 'function'){ 
        context.setAllBase(result);
      }
    } catch (e) {
      console.log(e);
    }
    setIsWaiting(false);
    setCategoryName('');
  };

  useEffect(()=>{
    context.axiGetAllBase();
  },[]);

  return(
    <div>
    
      <div>
        <h4 style={{marginTop: '-10px'}}>Введите название категории, чтобы добавить её в список</h4>
        <div style={{display: 'flex', flexWrap: 'wrap'}}>
          <TextField
            label="Название"
            id="standard-size-small"
            variant="outlined"
            sx={{width:'200px'}}
            onChange={handleChangeCategoryName}
            value={categoryName}
            error={!!nameError}
            helperText={nameError}
          />
            
          <ColorPicker
            onSelectColor={onSelectColor}
            selected={newColor}
          />

          {isWaiting ?
            <CircularProgress 
              color="success" 
              style={{
                margin: '8px', 
                width: '35px',
                height: '35px'
              }} 
            />
            :
            <IconButton 
              onClick={handleClickAdd}
              disabled={!!nameError || !categoryName}
            >
              <AddCircleIcon 
                fontSize='large' 
                color={(nameError || !categoryName) ? 'error' : 'success'} />
            </IconButton>
          }
        </div>
      </div>
      <CategoriesTable/>
    </div>
  );
};


export { Categories };