import React, { useContext, useEffect, useState, forwardRef } from 'react';
import styled, { keyframes } from 'styled-components';
import Button from '@mui/material/Button';

import { Context } from '../../../functions/context';

import { Invoice } from './Invoice';

// eslint-disable-next-line react/display-name
const PrintInvoice = forwardRef((props, ref) => {
  
  const onClickClose = () => {
    if (typeof props.onShowPrintTable === 'function'){
      props.onShowPrintTable(false);
    }
  };


  return(
    <PopUp ref={ref}>
      <NoPrint>
        <Button 
          style={{margin: 4,}}
          color="secondary"
          variant="contained"
          onClick={onClickClose}>
          Закрыть
        </Button> 
      </NoPrint>
      {/* <ContraindicationsPrint/> */}
      <RowWrap>
        <Invoice
          invoice = {props.invoice}
          currentInvoice = {props.currentInvoice}
          isHideEmpty = {props.isHideEmpty}
          salePoint = {props.salePoint}
          allInvoices = {props.allInvoices}
          deliverUser = {props.deliverUser}
          signedUser = {props.signedUser}
        />     
      </RowWrap>  
    </PopUp>
  );
});

const PopUp = styled.div`
  display: flex;

  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: #fff;
  margin-bottom: 80;
  
  position: fixed;
  z-index: 1300;
  top: 0px;
  left: 0px;

  height: auto;
  
  min-height: 100vh;

  width: 100vw;
  @page {
    margin: 0.1in;
  }
  @media print {
    /* здесь будут стили для печати */
    padding-top: 60px;
  }
`;

const RowWrap = styled.div`
${'' /* 
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  max-height: 100vh;
   */}
   
  display: block;
  max-width: 670px;
  overflow-y: auto;
`;

const NoPrint = styled.div`
  @media print {
    /* здесь будут стили для печати */
    display: none;
  }
`;

export default PrintInvoice;