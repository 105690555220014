import * as React from 'react';
import Table from '@mui/material/Table';
import {TableBody, TableHead, TableRow, TableCell, TableContainer} from '@mui/material';
import Paper from '@mui/material/Paper';

import PointsChangeModal from './PointsChangeModal/index';
import { Context } from '../../../functions/context';
import hourMinFromMinutes from '../../../functions/hourMinFromMinutes';

export default function PointRow(props) {
  let { point } = props;
  const context = React.useContext(Context);

  return(
    <TableRow 
      key={point.id}
      style={point.KktRegNumber
        ?{}
        :{
          borderStyle: 'dotted',
          borderWidth: 2,
          borderColor: '#833'
        }}               
      hover >
      <TableCell component="th" scope="row">
        {point.name}
      </TableCell>
      {window.screen.width > 420?
        <React.Fragment>
          <TableCell align="left">
            {point.address}
          </TableCell>
          <TableCell align="left">
            {context.kkts.map((kkt)=>{
              if(kkt.salePoint===point.id){
                return(<p>{kkt.KktRegId}</p>);
              }else{
                return null;
              }
            })}
          </TableCell>
        </React.Fragment>
        :
        <TableCell align="left">
                  Адресс: {point.address}
          <br/>
                  Касса №: {point.KktRegNumber}
        </TableCell>
      }
      <TableCell component="th" scope="row">
        {hourMinFromMinutes(point.open)}
      </TableCell>
      <TableCell component="th" scope="row">
        {hourMinFromMinutes(point.close)}
      </TableCell>
      <TableCell align="right">
        <div style={{display:'flex', justifyContent:'flex-end'}}>
          <PointsChangeModal 
            point = {point}
            PointName = {point.name}
            PointAddress = {point.address}
            PointId = {point.id}
            //pointKKT = {point.KktRegNumber}
            handleChangePointsArr = {props.handleChangePointsArr}
            isWaiting={props.isWaiting}
            //availableKKTs = {props.availableKKTs}
          />
        </div>
      </TableCell>
    </TableRow>
         
  );
}
