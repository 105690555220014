import React, { useContext, useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import { Context } from '../../../functions/context';
import TextField from '@mui/material/TextField';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ruLocale from 'date-fns/locale/ru';

import IconButton from '@mui/material/IconButton';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { CircularProgress } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import axi from '../../../functions/axiosf';
import validateTextInput from '../../../functions/validateTextInput';
import kitsTypes from '../../../resources/kitsTypes';
import daysOfWeek from '../../../resources/daysOfWeek';
import moment from 'moment';
import { DatePicker, MobileDatePicker } from '@mui/x-date-pickers';
import DayOfMonthPicker from '../../../Components/DatePickers/DayOfMonthPicker';
import DayOfWeekPicker from '../../../Components/DatePickers/DayOfWeekPicker';
import DayOfYearPicker from '../../../Components/DatePickers/DayOfYearPicker';


const NewKitForm = (props) => {
  const context = useContext(Context);
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState('');
  const [category, setCategory] = useState('');
  const [point, setPoint] = useState(0);

  const [dayOfWeek, setDayOfWeek] = useState(moment().format('E'));
  const handleChangeDayOfWeek = (event) => {
    setDayOfWeek(event?.target?.value);
  };

  const [dayOfMonth, setDayOfMonth] = useState(moment());
  const handleChangeDayOfMonth = (event) => {
    console.log(+event);
    setDayOfMonth(+event);
  };

  const [dayOfYear, setDayOfYear] = useState(moment());
  const handleChangeDayOfYear = (event) => {
    console.log(+event);
    setDayOfYear(+event);
  };


  const [startDay, setStartDay] = useState(moment());
  const handleChangeStartDay = (event) => {
    setStartDay(+event);
  };


  const [arbitraryPeriod, setArbitraryPeriod] = useState(14);
  const handleChangeArbitraryPeriod = (event) => {
    setArbitraryPeriod(event?.target?.value);
  };

  const [isWaiting, setIsWaiting] = useState(false);

  const handleChangeName = (event) => {
    let newName = event?.target?.value;
    let [textError, isSetState] = validateTextInput(newName, 'имя');
    isSetState && setName(newName);
    setNameError(textError);
  };

  const handleSelectCategory =(event)=>{
    setCategory(event?.target?.value);
  };

  const handleSelectPoint =(event)=>{
    setPoint(+event?.target?.value);
  };
 
  //функция добавления товара в список в backend
  const handleClickAdd = async() => {
    let postParams = { 
      token: context.token, 
      name: name,
      type: category,
      point: point,
      dayOfWeek: dayOfWeek,
      dayOfMonth: moment(dayOfMonth).format('DD'),
      dayOfYear: moment(dayOfYear).format('DDD'),
      startDay: Math.round( +startDay/1000 ),
      arbitraryPeriod: arbitraryPeriod,
    };
    setIsWaiting(true);
    try {
      let result = await axi(
        'admin/addNewKit.php', 
        '', 
        postParams,
      ); 
      if (typeof context.setAllBase === 'function'){ 
        context.setAllBase(result);
      }
    } catch (e) {
      console.log(e);
    }
    setIsWaiting(false);
    setName('');
  };

  return(
    <div style={{display: 'flex', flexWrap: 'wrap'}}>
      <FormControl sx={{
        m: 1, 
        width: '25ch',
        width:'200px', 
        margin: '8px', 
        transition: '1s',
      }} 
      variant="outlined">
        <TextField
          label="Наименование"
          variant="outlined"
          id="standard-size-small"
          defaultValue=""
          onChange={handleChangeName}
          value={name}
          error={nameError}
          helperText={nameError}
        />
      </FormControl>
            
      <FormControl sx={{width:'200px', margin: '8px'}}>
        <InputLabel id="demo-simple-select-label">Тип набора</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={category}
          label="Тип набора"
          onChange={handleSelectCategory}
        >
          {Object.keys(kitsTypes).map((kitType, keyKitType)=>
            <MenuItem
              key={keyKitType}
              value={kitType} 
            >{kitsTypes[kitType]}</MenuItem>
          )}
          {/* 
          <MenuItem
            key={0}
            value="custom" 
          >ручной</MenuItem>
          <MenuItem
            key={1}
            value="week"
          >день недели</MenuItem>
          <MenuItem
            key={1}
            value="month"
          >день месяца</MenuItem>
          <MenuItem
            key={1}
            value="year"
          >день года</MenuItem>
        */}
        </Select>
      </FormControl>
      <FormControl sx={{width:'200px', margin: '8px'}}>
        <InputLabel id="demo-simple-select-label">Адрес</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={point}
          label="Адрес"
          onChange={handleSelectPoint}
        >
          <MenuItem
            key={0}
            value={0}
          >для всех</MenuItem>
          {context.salePoints.map((item, index)=> 
            <MenuItem
              key={item.id}
              value={item.id}
            >{item.name}</MenuItem>
          )}
        </Select>
      </FormControl>
      {(category !== 'custom')?
        <div style={{display: 'flex'}}>
          <LocalizationProvider 
            dateAdapter={AdapterDateFns}
            adapterLocale={ruLocale}
          >
            <DatePicker
              label="день отсчёта"
              value={startDay}
              onChange={handleChangeStartDay}
              renderInput={(params) => 
                <TextField 
                  {...params} 
                  sx={{m: 1, width: '160px'}}
                />}
              disabled = {isWaiting}
            />
          </LocalizationProvider>
              
          {(category === 'week')?
              
            <DayOfWeekPicker
              dayOfWeek = {dayOfWeek}
              onChangeDayOfWeek = {handleChangeDayOfWeek}
              isWaiting = {isWaiting}
            />
            :
            null
          }
          {(category === 'month')?
              
            <DayOfMonthPicker
              dayOfMonth = {dayOfMonth}
              onChangeDayOfMonth = {handleChangeDayOfMonth}
              isWaiting = {isWaiting}
            />
            :null
          }
          {(category === 'year')?
            <DayOfYearPicker
              dayOfYear = {dayOfYear}
              onChangeDayOfYear = {handleChangeDayOfYear}
              isWaiting = {isWaiting}
            />
            :null
          }
          {(category === 'arbitrary')? 
          
            <FormControl sx={{
              m: 1, 
              width:'120px', 
              transition: '1s',
            }} 
            variant="outlined">
              <TextField
                label="период в днях"
                type="number"
                variant="outlined"
                id="standard-size-small"
                defaultValue={arbitraryPeriod}
                onChange={handleChangeArbitraryPeriod}
                value={arbitraryPeriod}
                disabled = {isWaiting}
                //error={nameError}
                //helperText={nameError}
              />
            </FormControl>
            
            :null
          }
        </div>
        :
        null
      }
      <LoadingButton 
        variant="contained"
        color={(nameError || !name || !category)?'error':'success'}
        disabled={nameError || !name || !category}
        loading={isWaiting}
        loadingPosition="end"
        endIcon={<AddCircleIcon/>}
        onClick={handleClickAdd}
        sx={{m:1}}
      >
        создать новый набор
      </LoadingButton>
      
    </div>
  );
};

export default NewKitForm;