const subscribesSettingsReducer = (state, action) =>{
  switch (action.type) {
    case 'START_WAITING': {
      return {...state, isWaiting: true, isDisableSaveButton: true};
    }
    case 'GET_STATE': {
      return {...state, isWaiting: false, ...action.payload};  
    }   
    case 'HANDLE_CLICK_SAVE_BUTTON': {
      return {...state, isWaiting: false, ...action.payload};  
    }  
    case 'TOGGLE':
      if ( typeof state.data[action.payload.name] === 'object'){
        return {
          ...state,
          isDisableSaveButton: false,
          data: {
            ...state.data,
            [action.payload.name]: {
              ...state.data[action.payload.name],
              isActive: state.data[action.payload.name].isActive ? 0 : 1,
            }
          }
        };
      }else{
        let defaultInputValue = 3;
        if (action.payload.name === 'passwordRecovery'){
          defaultInputValue = 0;
        }
        return {
          ...state,
          isDisableSaveButton: false,
          data: {
            ...state.data,
            [action.payload.name]: {
	            deliveryType: action.payload.deliveryType,
	            eventType: action.payload.name,
	            momentParam: defaultInputValue,
	            isActive: 1,
            }
          }
        }; 
      }
    case 'HANDLE_CHANGE_VALUE':
      return {
        ...state,
        isDisableSaveButton: false,
        data: {
          ...state.data,
          [action.payload.name]: {
            ...state.data[action.payload.name],
            momentParam: +action.payload.value,
          }
        }
      }; 
    default:
      throw Error('Unknown action: ' + action.type);
  }
};

export default subscribesSettingsReducer;