/* eslint-disable eqeqeq */
import React, { useContext, useEffect, useState, useRef, Fragment } from 'react';

import { 
  Checkbox, 
  CircularProgress, 
  FormControlLabel, 
  Alert,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Button,
  Paper
} from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SendIcon from '@mui/icons-material/Send';
import PrintIcon from '@mui/icons-material/Print';
import { useReactToPrint } from 'react-to-print';

import axi from './../../../functions/axiosf';

import {stylesObj} from './../../../stylesObj/stylesObj';

import { Context } from '../../../functions/context';
import moment from 'moment';
import invoiceTypes from '../../../resources/invoiceTypes';
import invoiceTypesColors from '../../../stylesObj/invoiceTypesColors';
import { Invoice } from './Invoice';
import PrintInvoice from './PrintInvoice';
import NoPrint from '../../../Components/NoPrint';

const tableRowStyle = {

  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  
  borderStyle: 'dashed',
  borderWidth: '0px',
  borderTopWidth: '1px',

  borderTopColor: '#5555',
  flexWrap: 'wrap',
};

const InvoiceList = (props) => {
  const context = useContext(Context);
  const [isWaiting, setIsWaiting] = useState(false);
  const [currentInvoice, setInvoice] = useState([]);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isRenderPrintPage, setIsRenderPrintPage] = useState(false);
  const [infoText, setInfoText] = useState('');
  const [typeInfo, setTypeInfo] = useState('');
  const exRef = useRef();

  
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const axiGetInvoiceList = () => {
    
    setIsWaiting(true);
    
    axi('admin/getInvoiceList.php', '', { token: context.token, invoiceId:  props.invoice?.id})
      .then((result) => { 
        let newResult = [...result];
        if (props.invoice?.type === 'acceptanceGoods'){
          
          axi('admin/getInvoiceList.php', '', { token: context.token, invoiceId:  props.invoice?.basisInvoice})
            .then((basisInvoiceResult) => { 
              newResult.map((good, goodIndex)=>{
                //newResult = [...newResult, {...good}];
                let isIncomeDidInCurrent = false;
                basisInvoiceResult.map((basisGood, basisGoodIndex)=>{

                  if ( +good.product === +basisGood.product ){
                    newResult[goodIndex].incomeQuantity = basisGood.quantity;
                    isIncomeDidInCurrent = true;
                  }
                });
                if (!isIncomeDidInCurrent){
                } 
              });
              setInvoice(newResult);
            }, 
            (e) => {
              console.log(e);
            }
            );
        }else{
          setInvoice(newResult);
        }
        setIsWaiting(false);
      }, 
      (e) => {
        console.log(e);
      }
      );
  };

  const handleExpand = (event, expanded) => {
    setIsExpanded(expanded);
    if(expanded){
      axiGetInvoiceList();
    }
  };

  const onSetIsScroll = (isScroll) => {
    if (typeof props.onSetIsScroll === 'function'){
      props.onSetIsScroll(isScroll);
    }
  };

  const handleOpenPrint = () => {
    setIsRenderPrintPage(true);
    onSetIsScroll(false);
    handlePrint();
  };

  const handleClosePrint = () => {
    setIsRenderPrintPage(false);
    onSetIsScroll(true);
  };

  useEffect(()=>{
    if(isExpanded){
      axiGetInvoiceList();
    }
    //console.log(exRef);
  },[props.invoice?.id]);
 
  ///////////////// этот код наверное стоит переписать на useState
  let salePoint = context.salePoints.filter((sp) => sp.id == props.invoice?.pointOfSale);

  let deliverRole = 'cashiers';
  if (props.invoice?.type === 'shipmentGoods'){
    deliverRole = 'admins';
  };
  let deliverUser = context[deliverRole].filter((du) => du.id == props.invoice?.sended);

  let signRole = 'admins';
  if (props.invoice?.type === 'shipmentGoods'){
    signRole = 'cashiers';
  };
  let signedUser = context[signRole].filter((su) => su.id == props.invoice?.signed);
  ////////////////
  return(
    <div>
      {isRenderPrintPage ?
        <PrintInvoice
          // это устаревший pop up для печати, который может быть будем использовать в будущем
          //ref={componentRef} 
          onShowPrintTable = {handleClosePrint} 
          invoice = {props.invoice}
          salePoint = {salePoint}
          currentInvoice = {currentInvoice}
          isHideEmpty = {props.isHideEmpty}
          deliverUser = {deliverUser}
          signedUser = {signedUser}
              
        />
        : ''
      }
  
      <Accordion
        onChange = {handleExpand}
        ref = {exRef}
        sx = {{
          backgroundColor: invoiceTypesColors[props.invoice?.type],
          maxWidth: '480px',
          marginBottom: 1,
          marginTop: 1,
        }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon/>}
          aria-controls={'panel'+props.invoice?.id+'a-content'}
          id={'panel'+props.invoice?.id+'a-header'}
        >
          <Typography
            sx={{display: 'flex', justifyContent: 'space-between', width: '95%'}}
          >
            <span>{salePoint[0]?.name}: <br/><b>№{props.invoice?.id}:</b> {moment(+props.invoice?.time * 1000).format('ll')}</span>
            <b>{invoiceTypes[props.invoice?.type]}</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails 
          sx={{
            marginBottom: 1,
            display: 'flex',
            flexDirection: 'column',
          }}>
          {isWaiting ? 
            <CircularProgress
              size={56}
            />
            :
            <Fragment>
              <Invoice
                invoice = {props.invoice}
                isHideEmpty = {props.isHideEmpty}
                salePoint = {salePoint}
                currentInvoice = {currentInvoice}
                deliverUser = {deliverUser}
                signedUser = {signedUser}
                onGetInvoiceList = {axiGetInvoiceList}
                setInfoText = {setInfoText}
                setTypeInfo = {setTypeInfo}
                ref={componentRef}
              />
              <Button 
                variant="contained" 
                onClick={handlePrint}
                endIcon={<PrintIcon/>}
                sx={{
                  //alignSelf: 'flex-end',
                  margin: 1
                }}
              >
                    Печать
              </Button>
              {infoText ?
                <Alert severity={typeInfo}>{infoText}</Alert>
                : ''
              }
            </Fragment>
          }
        </AccordionDetails>
      </Accordion>
    </div>
  );
  
};


export { InvoiceList };