import * as React from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { stylesObj } from '../../../stylesObj/stylesObj';
import { Context } from '../../../functions/context';
import Row from './Row';




const TypesGroupsEditKit = (props) => {
  const context = React.useContext(Context);

  const [positionsInsideType, setPositionsInsideType] = React.useState([]);
  React.useEffect(()=>{
    let newPositionsInsideType = [];
    context.nomenclature.map((item, index) => {
      if (props.typeTab && props.invoice){
        if (item.type === props.typeTab.id){
          let fullItem = {...item};
          fullItem.quantity = 0;
          props.invoice.map((invoiceItem) => {
            if(invoiceItem.id===item.id){
              fullItem.quantity = invoiceItem.quantity;
            }
          });
          newPositionsInsideType = [...newPositionsInsideType, fullItem];
        }
      }
    });
    setPositionsInsideType(newPositionsInsideType);
  },[context.nomenclature, props.typeTab, props.invoice]);

  
  const onChangeQuantity = (id, value) => {
    if(typeof props.onChangeQuantity === 'function'){
      props.onChangeQuantity(id, value);
    };
  };

  const setOpen = () =>{
    if(typeof props.onSetOpenedTabIndex === 'function'){
      props.onSetOpenedTabIndex(props.index);
    }
  };

  const setClose = () => {
    if(typeof props.onSetOpenedTabIndex === 'function'){
      props.isOpen ?
        props.onSetOpenedTabIndex('')
        :
        props.onSetOpenedTabIndex(props.index);
    }
  };

  return (
    <React.Fragment>
      <TableRow 
        sx={{ '& > *': { borderBottom: 'unset', cursor: 'pointer' , backgroundColor: `${props.typeTab.color}`} }}
      >
        <TableCell sx={stylesObj.TableCellMinPadding}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={setClose}
          >
            {props.isOpen ? <KeyboardArrowRightIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell 
          sx={stylesObj.TableCellMinPadding} 
          component="th" scope="row"
          onClick={setOpen}>
          <Typography variant="h6" gutterBottom component="div">
            {props.typeTab.name}
          </Typography>
        </TableCell>
        
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0, backgroundColor: props.typeTab.color }} colSpan={6}>
          <Collapse in={props.isOpen} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>товар</TableCell>
                    <TableCell align="right">количество</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {positionsInsideType.map((item,index)=>
                    <Row
                      key={index}
                      item={item}
                      onChangeQuantity={onChangeQuantity}
                      backgroundColor={props.typeTab.color}
                    />
                  )}
                </TableBody>
            
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default TypesGroupsEditKit;