/* eslint-disable eqeqeq */
import React, { Fragment, useContext, useEffect, useState } from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ruLocale from 'date-fns/locale/ru';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import Paper from '@mui/material/Paper';

import axi from '../../functions/axiosf';

import {stylesObj} from '../../stylesObj/stylesObj';

import { Context } from '../../functions/context';
import { CircularProgress } from '@mui/material';
import { MotivationOneCheckItem } from './MotivationOneCheckItem';
import motivationTypes from '../../resources/motivationTypes';
import moment from 'moment';
import { MotivationShelfPositionItem } from './MotivationShelfPositionItem';
import { MotivationShelfCategoryItem } from './MotivationShelfCategoryItem';
import dateInMsToSeconds from '../../functions/dateInMsToSeconds';
import { clear } from '@testing-library/user-event/dist/clear';

const getMonthYearIntegerFromDate=(newDate)=>{
  return (+moment(newDate).month()*10000) + +moment(newDate).year();
};

const MotivationDashboard = () => {
  const context = useContext(Context);
  const [isStartWaiting, setIsStartWaiting] = useState(false);
  const [oneCheckMotivationsSettings, setOneCheckMotivationsSettings] = useState([]);
  const [shelfPositionMotivationsSettings, setShelfPositionMotivationsSettings] = useState([]);
  const [shelfCategoryMotivationsSettings, setShelfCategoryMotivationsSettings] = useState([]);
  const [allInvoices, setAllInvoices] = useState([]);
  const [allShelfMotivation, setAllShelfMotivation] = useState([]);
  const [allShelfCategory, setAllShelfCategory] = useState([]);
  const [filteringInvoices, setFilteringInvoices] = useState([]);
  const [filteringShelfMotivation, setFilteringShelfMotivation] = useState([]);
  const [filteringCategoryMotivation, setFilteringCategoryMotivation] = useState([]);
  const [filteringLeftOneCheckMotivation, setFilteringLeftOneCheckMotivation] = useState([]);
  const [filteringLeftShelfMotivation, setFilteringLeftShelfMotivation] = useState([]);
  
  const currentDate=+moment().startOf('day');

  const [date, setDate] = useState(currentDate); // тут по умолчанию поставить не ткущее время а начало дня
  const [monthYear, setMonthYear] = useState(getMonthYearIntegerFromDate(currentDate));
  const [dateError, setDateError] = useState('');


  const [type, setType] = useState('oneCheckMotivations');
  const [point, setPoint] = useState(0);

  const axiGetAllInvoices = () => {
    setIsStartWaiting(true);
    axi(
      'admin/motivations/read'+type+'Results.php',
      '',
      { token: context.token , date: dateInMsToSeconds(date) }
    ).then(
      (result) => { 
        console.log(result);
        let newFilterKits = [];
        let newFilterLeftKits = [];
        switch(type) {
          case 'oneCheckMotivations':
            setAllInvoices(result.oneCheckMotivationRemunerations);
            
            newFilterKits = result.oneCheckMotivationRemunerations?.filter(inv => {
              
              return (
                (point === 0 || inv.cashierId == point) && 
                (date === null || ((inv.date * 1000) >= date) && ((inv.date * 1000) < date+(1000*60*60*24)))
              );
            });

            setFilteringInvoices(newFilterKits);
            setOneCheckMotivationsSettings(result.oneCheckMotivations);
            break;

          case 'shelfPositionMotivations':
            setAllShelfMotivation(result.shelfPositionMotivationRemunerations);
            newFilterKits = result.shelfPositionMotivationRemunerations?.filter(inv => {
              
              return (
                (point === 0 || inv.cashierId == point) && 
                (date === null || ((inv.date * 1000) >= date) && ((inv.date * 1000) < date+(1000*60*60*24)))
              );
            });
            setFilteringShelfMotivation(newFilterKits);
            setShelfPositionMotivationsSettings(result.shelfPositionMotivations);
            break;

          case 'shelfCategoryMotivations':
            setAllShelfCategory(result.shelfCategoryMotivationRemunerations);
            newFilterKits = result.shelfCategoryMotivationRemunerations?.filter(inv => {
              
              return (
                (point === 0 || inv.cashierId == point) && 
                (date === null || ((inv.date * 1000) >= date) && ((inv.date * 1000) < date+(1000*60*60*24)))
              );
            });
            setFilteringCategoryMotivation(newFilterKits);
            setShelfCategoryMotivationsSettings(result.shelfCategoryMotivations);
        }
        
        setIsStartWaiting(false);
      }, 
      (e) => {
        console.log(e);
      }
    );
  };

  useEffect(()=>{
    axiGetAllInvoices();
  },[type,monthYear]);
  
  
  const handleChangePoint = (event) => {
    let newPointId = event?.target?.value;
    setPoint(newPointId);
    let newFilterKits = [];
    switch(type) {
      case 'oneCheckMotivations':
        
        setFilteringInvoices([]);
        newFilterKits = allInvoices?.filter(inv => {
          return (
            (newPointId === 0 || inv.cashierId == newPointId) && 
            (date === null || ((inv.date * 1000) >= date) && ((inv.date * 1000) < date+(1000*60*60*24)))
          );
        });
        setFilteringInvoices(newFilterKits);
        break;

      case 'shelfPositionMotivations':
        setFilteringShelfMotivation([]);
        newFilterKits = allShelfMotivation?.filter(inv => {
          return (
            (newPointId === 0 || inv.cashierId == newPointId) && 
            (date === null || ((inv.date * 1000) >= date) && ((inv.date * 1000) < date+(1000*60*60*24)))
          );
        });
        setFilteringShelfMotivation(newFilterKits);
        break;

      case 'shelfCategoryMotivations':
        setFilteringCategoryMotivation([]);
        newFilterKits = allShelfCategory?.filter(inv => {
          return (
            (newPointId === 0 || inv.cashierId == newPointId) && 
            (date === null || ((inv.date * 1000) >= date) && ((inv.date * 1000) < date+(1000*60*60*24)))
          );
        });
        setFilteringCategoryMotivation(newFilterKits);
        break;
    }
    
  };

  
  const handleChangeType = (event) => {
    let newTypeId = event?.target?.value;
    setType(newTypeId);
  };
 

  const handleChangeDate = (value) => {
    let newDate = +value;
    setDate(newDate);

    let newMonthYear=getMonthYearIntegerFromDate(newDate);
    if (newMonthYear===monthYear) {
      
      let newFilterKits = [];
      switch(type) {
        case 'oneCheckMotivations':
          setFilteringInvoices([]);
          newFilterKits = allInvoices?.filter(inv => {
            return (
              (point === 0 || inv.cashierId == point) && 
              (newDate === null || ((inv.date * 1000) >= newDate) && ((inv.date * 1000) < newDate+(1000*60*60*24)))
            );
          });
          setFilteringInvoices(newFilterKits);
          break;

        case 'shelfPositionMotivations':
          setFilteringShelfMotivation([]);
          newFilterKits = allShelfMotivation?.filter(inv => {
            return (
              (point === 0 || inv.cashierId == point) && 
              (newDate === null || ((inv.date * 1000) >= newDate) && ((inv.date * 1000) < newDate+(1000*60*60*24)))
            );
          });
          setFilteringShelfMotivation(newFilterKits);
          break;
        case 'shelfCategoryMotivations':
          setFilteringCategoryMotivation([]);
          newFilterKits = allShelfCategory?.filter(inv => {
            return (
              (point === 0 || inv.cashierId == point) && 
              (newDate === null || ((inv.date * 1000) >= newDate) && ((inv.date * 1000) < newDate+(1000*60*60*24)))
            );
          });
          setFilteringCategoryMotivation(newFilterKits);
          break;
      }
    } else {
      setMonthYear(newMonthYear);
    }
  };


  const onGetSummaryRemuneration = (cashierObj) => {
    let remunerationSum = 0;
    let cashierDataObj = {};

    let newOneCheckFilter = [];

    if (cashierObj.id && cashierObj.point){
      newOneCheckFilter = allInvoices?.filter(inv => {
        if (date>0){
          return (
            (inv.cashierId == cashierObj.id) && 
            ((+inv.date * 1000) >= +moment(date).startOf('month')) && 
            ((+inv.date * 1000) < +moment(date).endOf('month')) 
          );
        }else{
          return (
            (inv.cashierId == cashierObj.id) && 
            ((+inv.date * 1000) >= +moment().startOf('month')) && 
            ((+inv.date * 1000) < +moment().endOf('month')) 
          );
        }
      });

      newOneCheckFilter.map((item, index) => {
        let remuneration = 0;
        let motivationsSettingsArray = oneCheckMotivationsSettings?.filter((setting)=>{
          return(+cashierObj.point == +setting.point
          && +item.quantityInCheck == +setting.quantityInCheck
          && +item.accumulatedResult >= +setting.quantity
          );
        });
        motivationsSettingsArray.sort((a, b) => {
          if (+a.quantity > +b.quantity) {
            return 1;
          }
          if (+a.quantity == +b.quantity) {
            return 0;
          }
          if (+a.quantity < +b.quantity) {
            return -1;
          }
          return 0;
        });
        if (motivationsSettingsArray.length > 0){
          remuneration = +motivationsSettingsArray[0].remuneration;
        }
        newOneCheckFilter[index].remuneration = +remuneration;
        remunerationSum = +remunerationSum + +remuneration;
      });

      let newAllShelfFilter = [];

      if(!allShelfMotivation){ 
        cashierDataObj.onlyOneCheckFilter = true;
      } 
      
      //тут мы просто взяли вместо мотивации каждого товара в отдельности  - мотивации категории
      //newAllShelfFilter = allShelfMotivation?.filter(inv => {
      newAllShelfFilter = allShelfCategory?.filter(inv => {
        if (date>0) {
          
          return (
            (inv.cashierId == cashierObj.id) && 
            ((+inv.date * 1000) >= +moment(date).startOf('month')) && 
            ((+inv.date * 1000) < +moment(date).endOf('month')) 
          );
        }else{
          
          return (
            (inv.cashierId == cashierObj.id) && 
            ((+inv.date * 1000) >= +moment().startOf('month')) && 
            ((+inv.date * 1000) < +moment().endOf('month')) 
          );
        }
    
      });

      newAllShelfFilter.map((item, index) => {
        let remuneration = 0;
        let shelfMotivationsSettingsArray = shelfPositionMotivationsSettings?.filter((setting)=>{
          return(+cashierObj.point == +setting.point
          && +item.productId == +setting.product
          && +item.accumulatedResult >= +setting.quantity
          );
        });
        shelfMotivationsSettingsArray.sort((a, b) => {
          if (+a.quantity > +b.quantity) {
            return 1;
          }
          if (+a.quantity == +b.quantity) {
            return 0;
          }
          if (+a.quantity < +b.quantity) {
            return -1;
          }
          return 0;
        });
        remuneration = +shelfMotivationsSettingsArray[0].remuneration;
        newAllShelfFilter[index].remuneration = +remuneration;
        remunerationSum = +remunerationSum + +remuneration;
      });

      cashierDataObj.remunerationSum = +remunerationSum;
    }
    return(cashierDataObj);
  };

  const renderMonthName = () => {
    if (date>0) {
          
      return (
        moment(date).format('MMMM')
      );
    }else{
    
      return (
        moment().format('MMMM')
      );
    }
  };
  
  return(
    <div>
      <FormControl style={{flex: 1, minWidth: '200px', margin: 16}}>
        <InputLabel id="demo-simple-select-label">Вид мотивации</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={type}
          label="Вид мотивации"
          onChange={handleChangeType}
        >
          {Object.keys(motivationTypes).map((item, index)=>
            <MenuItem 
              key={index} 
              value={item}>
              {motivationTypes[item]}
            </MenuItem>)}
        </Select>
      </FormControl>

      <FormControl style={{flex: 1, minWidth: '200px', margin: 16}}>
        <InputLabel id="demo-simple-select-label">Кассир</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={point}
          label="Кассир"
          onChange={handleChangePoint}
        >
          <MenuItem 
            key={0} 
            value={0}>
              Все
          </MenuItem>
          {context.cashiers.map((item, index)=>
            <MenuItem 
              key={index} 
              value={item.id}>
              {item.name}
            </MenuItem>)}
        </Select>
      </FormControl>

      <LocalizationProvider 
        dateAdapter={AdapterDateFns}
        adapterLocale={ruLocale}
      >
        <DatePicker
          label="Дата"
          value={date}
          onChange={(newValue) => {
            handleChangeDate(newValue);
          }}
          renderInput={(params) => <TextField sx={{margin: '16px'}} {...params} />}
        />
      </LocalizationProvider>
      {isStartWaiting ?
        <CircularProgress
          size={56}
        />
        :
        <Fragment>

          {type==='oneCheckMotivations' ?
            <TableContainer 
              //className={style.Paper} 
              component={Paper}>
              <Table 
                aria-label="collapsible table" 
                //className={style.Paperbody} 
              >
                <TableBody>
                  <TableRow 
                    //className={style.Paperhead} 
                  >             
                    <TableCell component="th" scope="row" align="left" >дата</TableCell>               
                    <TableCell component="th" scope="row" align="right">кассир</TableCell>     
                    <TableCell component="th" scope="row" align="right">товаров в чеке, шт</TableCell>                
                    <TableCell component="th" scope="row" align="right">ближайший план, шт</TableCell>                
                    <TableCell component="th" scope="row" align="left">достигнуто, шт</TableCell>             
                    <TableCell component="th" scope="row">вознаграждение, руб.</TableCell>   
                  </TableRow>
                  {filteringInvoices?.map((invoiceItem, invoiceIndex) => 
                    <MotivationOneCheckItem
                      key = {invoiceIndex}
                      oneCheckMotivationsSettings = {oneCheckMotivationsSettings}
                      invoice = {invoiceItem}
                      onGetSummaryRemuneration = {onGetSummaryRemuneration}
                      renderMonthName = {renderMonthName}
                    />
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            : ''
          }
          {type==='shelfPositionMotivations' ?
            <TableContainer 
              //className={style.Paper} 
              disable = {true}
              component = {Paper}>
              <Table 
                aria-label="collapsible table" 
                //className={style.Paperbody} 
              >
                <TableBody>
                  <TableRow 
                    //className={style.Paperhead} 
                  >             
                    <TableCell component="th" scope="row" align="left" >дата</TableCell>               
                    <TableCell component="th" scope="row" align="right">кассир</TableCell>     
                    <TableCell component="th" scope="row" align="right">название товара</TableCell>             
                    <TableCell component="th" scope="row" align="right">достигнуто, шт</TableCell>             
                    <TableCell component="th" scope="row">вознаграждение, руб</TableCell>   
                  </TableRow>
                  {filteringShelfMotivation?.map((invoiceItem, invoiceIndex) => 
                    <MotivationShelfPositionItem
                      key = {invoiceIndex}
                      shelfPositionMotivationsSettings = {shelfPositionMotivationsSettings}
                      invoice = {invoiceItem}
                      onGetSummaryRemuneration = {onGetSummaryRemuneration}
                      renderMonthName = {renderMonthName}
                    />
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            : ''
          }
          {type==='shelfCategoryMotivations' ?
            <TableContainer 
              //className={style.Paper} 
              component={Paper}>
              <Table 
                aria-label="collapsible table" 
                //className={style.Paperbody} 
              >
                <TableBody>
                  <TableRow 
                    //className={style.Paperhead} 
                  >             
                    <TableCell component="th" scope="row" align="left" >дата</TableCell>               
                    <TableCell component="th" scope="row" align="right">кассир</TableCell>     
                    <TableCell component="th" scope="row" align="right">название категории</TableCell>             
                    <TableCell component="th" scope="row" align="left">достигнуто, шт</TableCell>             
                    <TableCell component="th" scope="row">вознаграждение, руб</TableCell>   
                  </TableRow>
                  {filteringCategoryMotivation?.map((invoiceItem, invoiceIndex) => 
                    <MotivationShelfCategoryItem
                      key = {invoiceIndex}
                      shelfPositionMotivationsSettings = {shelfCategoryMotivationsSettings}
                      invoice = {invoiceItem}
                      onGetSummaryRemuneration = {onGetSummaryRemuneration}
                      renderMonthName = {renderMonthName}
                    />
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            : ''
          }

        </Fragment>
      }

    </div>
  );
  
};


export { MotivationDashboard };