const propsF=(propsFunction,...params)=>{
  if (propsFunction !==null && typeof propsFunction === 'function') {
    try {
      propsFunction(...params);
    } catch {
      console.log(`Error during calling propsF`);
    }
  }
};

export default propsF;
