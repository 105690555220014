/* eslint-disable eqeqeq */
import React, { useContext, useEffect, useState, useRef, Fragment } from 'react';

import Autocomplete from '@mui/material/Autocomplete';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ruLocale from 'date-fns/locale/ru';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {TextField , FormControlLabel , Slider} from '@mui/material';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';


import {stylesObj} from '../../../stylesObj/stylesObj';

import { Context } from '../../../functions/context';
import addLeftZeroIfLess10 from '../../../functions/addLeftZeroIfLess10';
import propsF from '../../../functions/propsF';
import styles from '../index.module.css';
import { Typography } from '@mui/material';



const ControlNomenclatureFilters = ({
  labelTextAll,

  point, setPoint,
  cashier, setCashier,
  category, setCategory,
  showOnlyEndedProducts, setShowOnlyEndedProducts
}) => {
  const context = useContext(Context);


  const handleChangePoint = (event) => {
    let selectedPoint = event?.target?.value;
    // console.log('point', selectedPoint);
    propsF(setPoint,selectedPoint);
    propsF(setCashier,0);
  };

  const handleChangeCashier = (event) => {
    let selectedCashier = event?.target?.value;
    // console.log('cashier', selectedCashier);
    propsF(setCashier, selectedCashier);
    propsF(setPoint,0);
  };

  
  const handleChangeCategory = (event) => {
    let newCategory = +event?.target?.value;
    propsF(setCategory, newCategory);
  }; 
  
  const handleChangeshowOnlyEndedProducts = (event) => {
    let isShow = +event?.target?.checked;
    // console.log(isShow);
    propsF(setShowOnlyEndedProducts, isShow===0?false:true);
  };


  
  return (
    <Fragment>

      <FormControl style={stylesObj.selectFilter}>
        <InputLabel id='demo-simple-select-label'>
          Точка
        </InputLabel>
        <Select
          labelId='demo-simple-select-label'
          id='demo-simple-select'
          value={point}
          label='Точка'
          onChange={handleChangePoint}
        >
          <MenuItem key={0} value={0}>
            {labelTextAll}
          </MenuItem>
          {context.salePoints.map((item, index) => (
            <MenuItem key={index} value={item.id}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl style={stylesObj.selectFilter}>
        <InputLabel id='demo-simple-select-label'>
          Кассир
        </InputLabel>
        <Select
          labelId='demo-simple-select-label'
          id='demo-simple-select'
          value={cashier}
          label="Кассир"
          onChange={handleChangeCashier}
        >
          <MenuItem key={0} value={0}>
            {labelTextAll}
          </MenuItem>
          {context.cashiers.map((item, index) =>
            <MenuItem 
              key={index} 
              value={item.id}>
              {item.name}
            </MenuItem>
          )}
        </Select>
      </FormControl>

      <FormControl style={stylesObj.selectFilter}
      >
        <InputLabel id='demo-simple-select-label'>Категория</InputLabel>
        <Select
          
          labelId='demo-simple-select-label'
          id='demo-simple-select'
          value={category}
          label='Категория'
          onChange={handleChangeCategory}
        >
          <MenuItem key={0} value={0}>
            {labelTextAll}
          </MenuItem>
          {context.types.map((item, index) => (
            <MenuItem
              key={item.id}
              value={item.id}
              style={{
                backgroundColor: item.color,
              }}
            >
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <div
        style={{...stylesObj.simpleRowWrap, alignItems: 'center'}}
      >
        <FormControlLabel 
          control={
            <Checkbox 
              // defaultChecked
              value={showOnlyEndedProducts} 
              onChange={handleChangeshowOnlyEndedProducts}
            />
          } 
          label = "Только товар, который закончился"
          sx={{
            minWidth: 280,
            maxWidth: 350
          }}
        />
      </div>
    </Fragment>
  );
};  
        
        
export { ControlNomenclatureFilters };
