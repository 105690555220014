import React, {Fragment, useContext, useEffect, useState} from 'react';
import { Context } from '../../../../../functions/context';

import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import IconButton from '@mui/material/IconButton';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import SaveIcon from '@mui/icons-material/Save';
import UndoIcon from '@mui/icons-material/Undo';
import AddIcon from '@mui/icons-material/Add';


import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import { Card, CircularProgress } from '@mui/material';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import ruLocale from 'date-fns/locale/ru';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import SpeedRuleItem from './SpeedRuleItem';
import {speedRuleTypes, speedRuleTypesHelpComment} from '../../../../../resources/speedRuleTypes';
import cardStyle from '../cardStyle';
import dayjs from 'dayjs';
import hourMinFromMinutes from '../../../../../functions/hourMinFromMinutes';

export default function TradePeriodItem(props) {
  const context = useContext(Context);
  const [newDuration, setNewDuration] = useState();
  const [newIncrease, setNewIncrease] = useState();
  const [newRuleType, setNewRuleType] = useState();
  const [isWaiting, setIsWaiting] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  const [updStart, setUpdStart] = useState(props.period.from);
  const [updMinutesStart, setMinutesUpdStart] = useState(+props.period.from);
  const [updEnd, setUpdEnd] = useState(props.period.to);
  const [updMinutesEnd, setMinutesUpdEnd] = useState(+props.period.to);

  const handleUpdStart =(event)=>{
    setUpdStart(event);
    setMinutesUpdStart((event.$H*60)+event.$m);
  };

  const handleUpdEnd =(event)=>{
    setUpdEnd(event);
    setMinutesUpdEnd((event.$H*60)+event.$m);
  };

  const handleSelectRuleType =(event)=>{
    setNewRuleType(event?.target?.value);
  };


  const handleChangeNewDuration = (event) => {
    let newNumb = +event.target.value;
    newNumb && ( typeof newNumb === 'number' ) && setNewDuration(newNumb);
  };

  const handleChangeNewIncrease = (event) => {
    let newNumb = +event.target.value;
    newNumb && ( typeof newNumb === 'number' ) && setNewIncrease(newNumb);
  };

  useEffect(()=>{
    setUpdStart(props.period.from);
  },[props.period.from]);

  useEffect(()=>{
    setUpdEnd(props.period.to);
  },[props.period.to]);

  const handleAddNewIncreaseRule = async()=>{
    setIsWaiting(true);
    if(typeof props.onAddNewIncreaseRule === 'function'){
      await props.onAddNewIncreaseRule(props.period.id, newRuleType, newDuration, newIncrease);
    }
    setNewDuration('');
    setNewIncrease('');
    setIsWaiting(false);
  };

  const handlePressEdit = () =>{
    setIsEdited(true);
  };

  const handlePressUpdate = async () => {
    setIsWaiting(true);
    //тут отправляем новые данные
    if(typeof props.axiUpdateTradePeriod === 'function'){
      await props.axiUpdateTradePeriod(props?.period?.id, updMinutesStart, updMinutesEnd);
    }
    setIsWaiting(false);
    setIsEdited(false);
  };

  const calcMaxTimeForStart = () => {
    let minutes = +props.maxAvailableMinutes;
    if (+props.maxAvailableMinutes > +updMinutesEnd){
      minutes = +updMinutesEnd;
    }
    return (dayjs((minutes - 1 + new Date().getTimezoneOffset()) *60*1000));
  };

  const calcMinTimeForEnd = () => {
    let minutes = +props.minAvailableMinutes;
    if (+props.minAvailableMinutes < +updMinutesStart){
      minutes = +updMinutesStart;
    }
    return (dayjs((minutes + 1 + new Date().getTimezoneOffset()) *60*1000));
  };

  return(
    <Card sx={{ padding: 1, marginBottom: 1 }}>
      <div
        style={{display: 'flex'}}
      >
        {isEdited?
          <div
            style={{
              display: 'flex', 
              width: '100%', 
              height: 'auto',
              justifyContent: 'space-between', 
              alignItems: 'center',
            }}>
            <LocalizationProvider 
              dateAdapter={AdapterDayjs}
              adapterLocale={ruLocale}
            >
              <TimePicker
                label="начало"
                value={(updMinutesStart + new Date().getTimezoneOffset()) *60*1000}
                onChange={handleUpdStart}
                renderInput={(params) => <TextField {...params} sx={{m:1, width: '120px'}}/>}
                disableIgnoringDatePartForTimeValidation
                minTime = {dayjs((+props.minAvailableMinutes + 1 + new Date().getTimezoneOffset())*60*1000)}
                maxTime = {calcMaxTimeForStart()}
              />
            </LocalizationProvider>
            <LocalizationProvider 
              dateAdapter={AdapterDayjs}
              adapterLocale={ruLocale}
            >
              <TimePicker
                label="окончание"
                value={(updMinutesEnd + new Date().getTimezoneOffset()) *60*1000}
                onChange={handleUpdEnd}
                renderInput={(params) => <TextField {...params} sx={{m:1, width: '120px'}}/>}
                disableIgnoringDatePartForTimeValidation
                minTime = {calcMinTimeForEnd()}
                maxTime = {dayjs((+props.maxAvailableMinutes - 1 + new Date().getTimezoneOffset())*60*1000)}
              />
            </LocalizationProvider>
            {isWaiting?
              <CircularProgress 
                color="success" 
                style={{
                  margin: '8px', 
                  width: '35px',
                  height: '35px'
                }} 
              />
              :
              <div style={{width: '40px'}}>
                <IconButton 
                  variant="contained" 
                  onClick={()=>{
                    setIsEdited(false);
                  }}>
                  <UndoIcon/>
                </IconButton>
                <IconButton 
                  variant="contained" 
                  onClick={handlePressUpdate}>
                  <SaveIcon/>
                </IconButton>
              </div>
            }
            
          </div>
          :
          <div 
            style={{
              display: 'flex', 
              width: '100%', 
              justifyContent: 'space-evenly', 
              alignItems: 'center',
            }}>
            {/* 
            <span>{'c '+ parseInt(+props.period.from/60)+':'+(+props.period.from%60)}</span>
            <span>{'по ' + parseInt(+props.period.to/60)+':'+(+props.period.to%60)}</span>
             */}
            <span>{'c '+ hourMinFromMinutes(+props.period.from)}</span>
            <span>{'по ' + hourMinFromMinutes(+props.period.to)}</span>
            
            <IconButton 
              variant="contained" 
              onClick={handlePressEdit}>
              <BorderColorIcon/>
            </IconButton>
          </div>  
        }
        
      </div>
      <Card sx={cardStyle}>
        <h4>правила изменения выпуска продукции</h4>
        {(props.speedRules && (typeof props.speedRules === 'object')) && props.speedRules.map(
          (speedRule, speedRuleIndex)=>
            <SpeedRuleItem
              key = {speedRuleIndex}
              speedRule = {speedRule}
              axiUpdateIncreaseRule = {props.axiUpdateIncreaseRule}
            />
        )
        }
        <p>новое правило</p>
        <FormControl fullWidth sx={{marginBottom: 2}}>
          <InputLabel id="demo-simple-select-label">тип правила</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={newRuleType}
            label="тип правила"
            onChange={handleSelectRuleType}
          >
            {Object.keys(speedRuleTypes).map((item, index)=>
              <MenuItem 
                key={index} 
                value={item}>
                {<div 
                  style={{
                    width: '100%', 
                    display: 'flex', 
                    flexDirection: 'column'}}
                >
                  <span style={{
                    width: '100%',
                    textWrap: 'balance'
                  }}>
                    {speedRuleTypes[item]}
                  </span>
                  <span
                    style = {{
                      color: '#444',
                      fontSize: '10px',
                      width: '100%',
                      maxWidth: '280px',
                      textWrap: 'balance'
                    }}
                  >{speedRuleTypesHelpComment[item]}</span>
                </div>}
              </MenuItem>)
            }
          </Select>
        </FormControl>
      
        {newRuleType ?
          <div
            style={{display: 'flex', marginBottom: 1}}
          >
            <TextField
              label="часы"
              id="standard-size-small"
              variant="outlined"
              type="number"
              sx={{width:'100px'}}
              value={newDuration}
              onChange={handleChangeNewDuration}
            />
            <TextField
              label="%"
              id="standard-size-small"
              variant="outlined"
              type="number"
              sx={{width:'100px'}}
              value={newIncrease}
              onChange={handleChangeNewIncrease}
            />
          
          </div>
          : ''
        }
        
        <LoadingButton 
          variant="contained"
          color="success"
          disabled={isWaiting || !(!!newDuration && !!newIncrease)}
          loading={isWaiting}
          loadingPosition="end"
          endIcon={<AddIcon/>}
          onClick={handleAddNewIncreaseRule}
        >
            добавить правило
        </LoadingButton>
         
      </Card>
    </Card>
  );  
}