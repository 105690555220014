/* eslint-disable eqeqeq */
import React, { Fragment, useContext, useEffect, useState } from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ruLocale from 'date-fns/locale/ru';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import Paper from '@mui/material/Paper';

import axi from '../../functions/axiosf';

import {stylesObj} from '../../stylesObj/stylesObj';

import { Context } from '../../functions/context';
import { CircularProgress } from '@mui/material';
import moment from 'moment';
import { ViolationStartItem } from './ViolationStartItem';

const ControlStartSelling = () => {
  const context = useContext(Context);
  const [isStartWaiting, setIsStartWaiting] = useState(false);
  
  const [point, setPoint] = useState(0);
  const [cashier, setCasher] = useState(0);
  const [date, setDate] = useState(+moment().startOf('day'));// тут по умолчанию поставить не ткущее время а начало дня

  const [pointsObj, setPointsObj] = useState({});
  const [violations, setViolations] = useState([]);


  const axiGetControlStartSelling = () => {
    setIsStartWaiting(true);
    axi('admin/errorControl/getControlStartSelling.php', '', { token: context.token, date: Math.round(date/1000) })
      .then((result) => { 
        setViolations(result?.violations);
        setIsStartWaiting(false);
      }, 
      (e) => {
        console.log('admin/errorControl/getControlStartSelling.php ', e);
      }
      );
  };

  useEffect(()=>{
    axiGetControlStartSelling();
  },[date]);

  useEffect(()=>{
    let newPointsObj = {};
    context.salePoints.map((point)=>{
      newPointsObj[point.id] = point;
    });
    setPointsObj(newPointsObj);
  }, [context.salePoints]);
  
  const handleChangeCasher = (event) => {
    let newCasherId = event?.target?.value;
    setCasher(newCasherId);
  };

  const handleChangePoint = (event) => {
    let newPointId = event?.target?.value;
    setPoint(newPointId);
  };

  const handleChangeDate = (value) => {
    let newDate = +value;
    setDate(newDate); 
  };

  
  return(
    <div>
      <FormControl style={{flex: 1, minWidth: '200px', margin: 16}}>
        <InputLabel id="demo-simple-select-label">Точка</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={point}
          label="Точка"
          onChange={handleChangePoint}
        >
          <MenuItem 
            key={0} 
            value={0}>
              Все
          </MenuItem>
          {context.salePoints.map((item, index)=>
            <MenuItem 
              key={index} 
              value={item.id}>
              {item.name}
            </MenuItem>)}
        </Select>
      </FormControl>

      <FormControl style={{flex: 1, minWidth: '200px', margin: 16}}>
        <InputLabel id="demo-simple-select-label">Кассир</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={cashier}
          label="Кассир"
          onChange={handleChangeCasher}
        >
          <MenuItem 
            key={0} 
            value={0}>
              Все
          </MenuItem>
          {context.cashiers.map((item, index)=>
            <MenuItem 
              key={index} 
              value={item.id}>
              {item.name}
            </MenuItem>)}
        </Select>
      </FormControl>

      <LocalizationProvider 
        dateAdapter={AdapterDateFns}
        adapterLocale={ruLocale}
      >
        <DatePicker
          label="Дата"
          value={date}
          onChange={(newValue) => {
            handleChangeDate(newValue);
          }}
          renderInput={(params) => <TextField sx={{margin: '16px'}} {...params} />}
        />
      </LocalizationProvider>
      {isStartWaiting ?
        <CircularProgress
          size={56}
        />
        :
        <Fragment>

          <TableContainer 
            //className={style.Paper} 
            component={Paper}>
            <Table 
              aria-label="collapsible table" 
              //className={style.Paperbody} 
            >
              <TableBody>
                <TableRow 
                  //className={style.Paperhead} 
                >                    
                  <TableCell component="th" scope="row" align="left">точка</TableCell>              
                  <TableCell component="th" scope="row" align="left">кассир</TableCell> 
                  <TableCell component="th" scope="row" align="left">товар</TableCell>            
                  <TableCell component="th" scope="row" align="right">плановое начало продаж</TableCell>             
                  <TableCell component="th" scope="row" align="left">приём отсутствующего товара</TableCell>   
                </TableRow>
                
                { typeof pointsObj === 'object' 
                  && typeof violations === 'object' 
                  && violations.map((violationItem, violationIndex) => {
                  // для начала проверяем, соответствует ли эта накладная фильтрам по дате, точке и кассиру
                    {/* 
                    if (( +point == 0 || +violationItem.pointOfSale == +point )
                      && ( +cashier == 0|| +violationItem.sended == +cashier )) {
                    */}
                  
                    if (( +point == 0 || +violationItem.salePoint == +point )
                    && ( +cashier == 0|| +violationItem.cashier == +cashier )) {    
                   
                      return(
                      
                        <ViolationStartItem
                          key = {violationIndex}
                          violation = {violationItem}
                          pointsObj = {pointsObj}
                        />
                       
                      );
                    }
                  
                    return('');
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Fragment>
      }

    </div>
  );
  
};

export { ControlStartSelling };