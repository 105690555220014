import React, {useState, useContext, useEffect} from 'react';
import Table from '@mui/material/Table';
import {TableBody, TableHead, TableRow, TableCell} from '@mui/material';
import TableContainer from '@mui/material/TableContainer';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import UserRow from './UsersRow';
import UsersUpdateModal from './UsersUpdateModal';
import { Context } from '../../../functions/context';
import { stylesObj } from './../../../stylesObj/stylesObj';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


export default function UsersTable(props) {
  const context = useContext(Context);
  
  const [isOpenModal, setIsOpenModal] = useState(false);
  
  const [isWaiting, setIsWaiting] = useState(false);

  const [pointsObj, setPointObj] = useState({});

  const [modalItem, setModalItem] = useState();

  useEffect(()=>{
    let newPintObj = {};
    context.salePoints.map((point)=>{
      newPintObj[point.id] = {...point};
    });
    setPointObj(newPintObj);
  },[context.salePoints]);

  useEffect(()=>{
    let filtredPers = context[props.role].filter(person => +person.id === +isOpenModal);
    setModalItem(filtredPers[0]);
  },[isOpenModal]);

  let roleLabel = 'кассиры';
  if (props.role ==='admins'){
    roleLabel = 'администраторы';
  }
  if (props.fired && props.role ==='cashiers'){
    roleLabel = 'уволенные кассиры';
  }

  return(
    <div style={{display:'flex'}}>
      {typeof isOpenModal === 'number' ?
        <UsersUpdateModal
          indexOf={+isOpenModal}
          isOpenModal={typeof isOpenModal === 'number'}
          onOpenModal={setIsOpenModal}
          onSetIsWaiting={setIsWaiting}
          isWaiting={isWaiting}
          role={props.role}
          pointsObj={pointsObj}
          item={modalItem}
        /> 
        :
        null
      }

      <Accordion 
        sx = {{width: '100%'}}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={roleLabel}
          id={roleLabel}
        ><Typography 
            variant="h6" 
            //align="center" 
            gutterBottom 
            component="div"
            sx={{marginLeft: '16px', marginTop: '8px'}}
          >
            {roleLabel}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TableContainer component={Paper} sx={{ marginBottom: '40px' }}>
            <Table aria-label="caption table">
              <TableHead>
                <TableRow >  
                  <TableCell>имя</TableCell>
                  {props.role==='cashiers'?
                    <TableCell>точка</TableCell>
                    :null
                  }
                  {props.fired ?
                    <TableCell>дата увольнения</TableCell>
                    :null
                  }
                  <TableCell align="right">действия</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {context[props.role]?.length ?
                  context[props.role].filter(person => {
                    let filterResult;
                    if (props.fired){
                      filterResult = person.isFired !== undefined && +person.isFired > 0;
                      return (filterResult);
                    }else{
                      filterResult = person.isFired === undefined || +person.isFired === 0;
                      return (filterResult);
                    }
                  }
                  ).map((item, index) => (
                    <UserRow
                      key = {item.id}
                      index = {index}
                      item = {item}
                      isWaiting = {isWaiting}  
                      onOpenModal={setIsOpenModal}
                      role={props.role}
                      point={(item.point) ? pointsObj[item.point]?.name : '' }
                    />
                  ))
                  :
                  null
                }
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
