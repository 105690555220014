import * as React from 'react';

import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Send from '@mui/icons-material/Send';

export default function ModalBottomButtonsBlock(props) {

  const handleAccept = () => {
    if (typeof props.handleAccept === 'function'){
      props.handleAccept();
    }
  };

  const handleClose = () => {
    if (typeof props.handleClose === 'function'){
      props.handleClose();
    }
  };

  return(
    <div style={{
      display:'flex',
      flexDirection: 'column',
      justifyContent: 'space-around',
      alignItems: 'center',
      marginTop:'10px',
      height: '100px',
    }}>
      <LoadingButton 
        variant="contained"
        color="success"
        disabled={props.isWaiting || props.disabled}
        loading={props.isWaiting}
        loadingPosition="end"
        endIcon={<Send/>}
        onClick={handleAccept}
      >
        {typeof props.textOK === 'string' ? props.textOK : 'сохранить'}
      </LoadingButton>
      <Button 
        variant="contained"
        color="error"
        onClick={handleClose}>
        {typeof props.textClose === 'string' ? props.textClose : 'закрыть'}
      </Button>
    </div>
  );
}