/* eslint-disable eqeqeq */
import React, { useContext, useEffect, useState } from 'react';


import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ruLocale from 'date-fns/locale/ru';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import Paper from '@mui/material/Paper';

import axi from './../../functions/axiosf';

import {stylesObj} from './../../stylesObj/stylesObj';

import { Context } from '../../functions/context';
import { Checkbox, CircularProgress, FormControlLabel } from '@mui/material';
import { InvoiceList } from './InvoiceList';
import { typeImplementation } from '@testing-library/user-event/dist/type/typeImplementation';
import invoiceTypes from '../../resources/invoiceTypes';
import styled from 'styled-components';
import moment from 'moment';
import axios from 'axios';
import isResultObjectOk from '../../functions/isResultObjectOk';
import dateInMsToSeconds from '../../functions/dateInMsToSeconds';
import LoadingButton from '@mui/lab/LoadingButton';
import CachedIcon from '@mui/icons-material/Cached';
import styles from './index.module.css';
import { MOBILE_SCREEN_WIDTH } from '../../stylesObj/layoutConsts';


const Invoices = () => {
  const context = useContext(Context);

  const minDate = +moment([2015]).startOf('year').startOf('day');
  const maxDate = +moment().endOf('year').endOf('day');

  const [isStartWaiting, setIsStartWaiting] = useState(false);
  const [allInvoices, setAllInvoices] = useState([]);
  const [filteringInvoices, setFilteringInvoices] = useState([]);

  const [date, setDate] = useState(+moment().startOf('day'));// тут по умолчанию поставить не ткущее время а начало дня
  const [dateError, setDateError] = useState('');

  const [type, setType] = useState(0);
  const [point, setPoint] = useState(0);

  const [isWait, setIsWait] = useState(false);

  
  const [isHideEmpty, setHideEmpty] = useState(false);
  
  const handleChangeHideEmpty = (event) => {
    setHideEmpty(event.target.checked);
  };


  const onSetIsScroll = (isScroll) => {
    
  };


  const applyFilters=(data)=>{
    let filtered=[];
    filtered=data.filter(inv => {
      return (point === 0 || inv.pointOfSale == point) 
          && (type === 0 || inv.type == type); 
    });
    setFilteringInvoices(filtered);
  };


  const axiGetAllInvoices = (axiCancelToken) => {
    if ((minDate<=date) && (date<=maxDate)) {
      setIsStartWaiting(true);
      axi(
        'admin/getAllInvoices.php', 
        '', 
        { token: context.token, date: dateInMsToSeconds(date) }, 
        axiCancelToken
      ).then(
        (result) => { 
          console.log(result);
          if (isResultObjectOk(result,['invoices'],'ошибка в получении списка',setDateError)) {
            setAllInvoices(result.invoices);
            applyFilters(result.invoices);
          }else{
            setDateError('ошибка в получении списка');
          }
          if (result.err) {
            setDateError(result.err);
          }
          setIsStartWaiting(false);
        }, 
        (e) => {
          setIsStartWaiting(false);
          console.log(e);
        }
      );
    } else {
      setDateError('Неактуальная дата');
    }
  };

  

  useEffect(()=>{
    const axiCancelToken = axios.CancelToken.source();
    axiGetAllInvoices(axiCancelToken);
    return () => {
      axiCancelToken.cancel();
    };
  },[date]);

  const handleChangeDate = (value) => {
    setDateError('');
    let newDate = +value;
    setDate(newDate);
    
  };

  useEffect(()=>{
    applyFilters(allInvoices);
  },[point,type]);

  const handleChangePoint = (event) => setPoint(event?.target?.value);
  const handleChangeType = (event) => setType(event?.target?.value);

  const renderMarginTop = () => {
    let mT = '400px';
            
    if (window.outerWidth > 487){
      mT = '300px';
      if (window.outerWidth > MOBILE_SCREEN_WIDTH){
        mT = '230px';  
        if (window.outerWidth > 755){
          mT = '160px';
          if (window.outerWidth > 1225){
            mT = '80px';
          }         
        }         
      }
    }
    let currentMarginTop = {marginTop: mT};
    return (currentMarginTop);
  };

  return(
    <MainDiv>
      <div style={{
        ...stylesObj.simpleRowWrap,
        position: 'fixed',
        backgroundColor: '#fffd',
        zIndex: 256 }}>
        <FormControl style={stylesObj.selectFilter}>
          <InputLabel id="demo-simple-select-label">Торговая Точка</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={point}
            label="Торговая Точка"
            onChange={handleChangePoint}
          >
            <MenuItem 
              key={0} 
              value={0}>
              Все
            </MenuItem>
            {context.salePoints.map((item, index)=>
              <MenuItem 
                key={index} 
                value={item.id}>
                {item.name}
              </MenuItem>)}
          </Select>
        </FormControl>

        <FormControl style={stylesObj.selectFilter}>
          <InputLabel id="demo-simple-select-label">Тип накладной</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={type}
            label="Тип накладной"
            onChange={handleChangeType}
          >
            <MenuItem 
              key={0} 
              value={0}>
              Все
            </MenuItem>
            {Object.keys(invoiceTypes).map((item, index)=>
              <MenuItem 
                key={index} 
                value={item}>
                {invoiceTypes[item]}
              </MenuItem>)}
          </Select>
        </FormControl>

        <LocalizationProvider 
          dateAdapter={AdapterDateFns}
          adapterLocale={ruLocale}
        >
          <DatePicker
            label="Дата накладной"
            value={date}
            onChange={(newValue) => {
              handleChangeDate(newValue);
            }}
            renderInput={ (params) => 
              <TextField 
                style={stylesObj.selectFilter}
                {...params} 
                error={dateError!==''}    
                helperText={dateError} 
                onFocus={event => {
                  event.target.select();
                }}
                autoFocus
              />
            }
          />
        </LocalizationProvider>
        
        <LoadingButton 
          variant="contained"
          disabled={isWait}
          loading={isWait}
          loadingPosition="end"
          endIcon={<CachedIcon/>}
          onClick={axiGetAllInvoices}
          sx={{margin:  2}}
        >
          Обновить
        </LoadingButton>
        <FormControlLabel 
          control={
            <Checkbox
              checked = {isHideEmpty}
              onChange = {handleChangeHideEmpty}
            />
          } 
          label = "Скрывать нулевые позиции"
          sx = {{margin: 3}}
        />
      </div>

      {(isStartWaiting)?
        <CircularProgress
          size={56}
          style={renderMarginTop()}
        />
        :
        <div 
          style={renderMarginTop()}
        >
          {filteringInvoices.length>0?
            filteringInvoices.map((invoiceItem, invoiceIndex) => 
              <InvoiceList
                key = {invoiceIndex}
                invoice = {invoiceItem}
                isHideEmpty = {isHideEmpty}
                onSetIsScroll = {onSetIsScroll}
              />
            ):<></>}
        </div>
      }
    </MainDiv>
  );
};

const MainDiv = styled.div`
    display: flex;
    flex-direction: column;
`;

export { Invoices };
