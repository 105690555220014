/* eslint-disable eqeqeq */
import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import ModalBottomButtonsBlock from '../../../../Components/ModalBottomButtonsBlock';
import { Context } from '../../../../functions/context';
import ColorPicker from '../../../../Components/ColorPicker';
import validateTextInput from '../../../../functions/validateTextInput';
import LoadingButton from '@mui/lab/LoadingButton';
import AddIcon from '@mui/icons-material/Add';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import ruLocale from 'date-fns/locale/ru';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import TradePeriodItem from './TradePeriodItem';
import axi from '../../../../functions/axiosf';
import { Card, CircularProgress } from '@mui/material';
import cardStyle from './cardStyle';
import dayjs from 'dayjs';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  //width: 320,
  maxHeight: innerHeight-64,
  overflowY: 'scroll',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 1,
  /* 
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
   */
};

export default function CategoryUpdateModal(props) {
  const context = React.useContext(Context);
  const [newName, setNewName] = React.useState('');
  const [nameError, setNameError] = React.useState('');
  const [newColor, setNewColor] = React.useState('');
  const [typeId, setTypeId] = React.useState(null);
  const [isEdited, setIsEdited] = React.useState(false);
  const [newTimeStart, setNewTimeStart] = React.useState(480);
  const [newTimeEnd, setNewTimeEnd] = React.useState(1200);
  const [tradePeriods, setTradePeriods] = React.useState([]);
  const [speedRules, setSpeedRules] = React.useState([]);
  const [isWaiting, setIsWaiting] = React.useState(false);

  React.useEffect(()=>{
    if(typeof props.indexOfType === 'number'){
      let currentType = context.types[props.indexOfType];
      if (currentType){
        setNewName(currentType.name);
        setNewColor(currentType.color);
        setTypeId(currentType.id);
      }
      axiReadTradePeriods(currentType.id);
    }
  },[props.indexOfType]);

  const handleChangeNewName = (event) => {
    let newName = event.target.value;
    let [textError, isSetState] = validateTextInput(newName, 'название категории');
    isSetState && setNewName(newName);
    setNameError(textError);
  };

  const handleChangeNewColor = (e)=>{
    setNewColor(e);
    setIsEdited(true);
  };

  const handleSetNewTimeStart =(event)=>{
    setNewTimeStart((event.$H*60)+event.$m);
  };
  
  const handleSetNewTimeEnd =(event)=>{
    setNewTimeEnd((event.$H*60)+event.$m);
  };


  const axiReadTradePeriods = async (productsTypeId) => {
    setIsWaiting(true);
    let data = { 
      token: context?.token, 
      productsTypeId: productsTypeId,
    };
    let result = await axi('admin/getTradePeriods.php', '', data);
    if (result.status === 'ok'){
      setTradePeriods(result.tradePeriods);
      setSpeedRules(result.speedRules);
    }
    setIsWaiting(false);
  };

  const axiAddNewTradePeriod = async() =>{
    setIsWaiting(true);
    let data = { 
      token: context?.token, 
      productsTypeId: typeId,
      newTimeStart: newTimeStart,
      newTimeEnd: newTimeEnd,

    };
    let result = await axi('admin/addNewTradePeriod.php', '', data);
    if (result.status === 'ok'){
      setTradePeriods(result.tradePeriods);
      setSpeedRules(result.speedRules);
    }
    setIsWaiting(false);
  };

  const axiUpdateTradePeriod = async(tradePeriodId, updStart, updEnd) =>{
    setIsWaiting(true);
    let data = { 
      token: context?.token, 
      productsTypeId: typeId,
      tradePeriodId: tradePeriodId,
      updStart: updStart,
      updEnd: updEnd,

    };
    let result = await axi('admin/updateTradePeriod.php', '', data);
    if (result.status === 'ok'){
      setTradePeriods(result.tradePeriods);
      setSpeedRules(result.speedRules);
    }
    setIsWaiting(false);
    return true;
  };

  const axiAddNewIncreaseRule = async(tradePeriodId, newRuleType, newDuration, newIncrease)=>{
    setIsWaiting(true);
    let data = { 
      token: context?.token, 
      productsTypeId: typeId,
      tradePeriodId: tradePeriodId,
      newRuleType: newRuleType,
      newDuration: newDuration,
      newIncrease: newIncrease,
    };
    let result = await axi('admin/addNewIncreaseRule.php', '', data);
    if (result.status === 'ok'){
      setTradePeriods(result.tradePeriods);
      setSpeedRules(result.speedRules);
    }
    setIsWaiting(false);
    return true;
  };

  const axiUpdateIncreaseRule = async(speedRuleId, newDuration, newIncrease)=>{
    setIsWaiting(true);
    let data = { 
      token: context?.token, 
      productsTypeId: typeId,
      speedRuleId: speedRuleId,
      newDuration: newDuration,
      newIncrease: newIncrease,
    };
    let result = await axi('admin/updateIncreaseRule.php', '', data);
    if (result.status === 'ok'){
      setTradePeriods(result.tradePeriods);
      setSpeedRules(result.speedRules);
    }
    setIsWaiting(false);
    return true;
  };

  const onPressAccept = async() => {
    if (typeof props.onUpdateCategory === 'function'){
      await props.onUpdateCategory(typeId, newName, newColor);
    }
  };

  const handleClose = () =>{
    if (typeof props.onOpenModal === 'function'){
      props.onOpenModal(false);
    }
  };

  return(
    <div>
      <Modal
        //aria-labelledby="transition-modal-title"
        //aria-describedby="transition-modal-description"
        open={!!props.isOpenModal}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <TextField
              fullWidth
              label="Название"
              id="standard-size-small"
              value={newName}
              variant="outlined"
              sx={{marginTop: 2}}
              onChange={handleChangeNewName}
              error={!!nameError}
              helperText={nameError}
            />
            <ColorPicker
              onSelectColor={handleChangeNewColor}
              selected={newColor}
              quantity={3}
            />
            
            <Card sx={cardStyle}>
              <h4>настройки периодов скорости продаж</h4>
              {!!tradePeriods ? 
                <React.Fragment>
                  {tradePeriods.map(
                    (period, periodIndex)=>{
                    
                      let minAvailableMinutes = 0;
                      let maxAvailableMinutes = 1439;
                      let i = 0;
                      while(!!tradePeriods[i]){
                        if(+tradePeriods[i].id !== +period.id){
                          if(+tradePeriods[i].to < +period.from && +tradePeriods[i].to > +minAvailableMinutes){
                            minAvailableMinutes = +tradePeriods[i].to;
                          }
                          if(+tradePeriods[i].from > +period.to && +tradePeriods[i].from < +maxAvailableMinutes){
                            maxAvailableMinutes = +tradePeriods[i].from;
                          }
                        }
                        i++;
                      }
                      console.log(minAvailableMinutes);
                      return (
                        <TradePeriodItem
                          period={period}
                          key={periodIndex}
                          speedRules={speedRules.filter(rule => rule.tradePeriodId == period.id)}
                          minAvailableMinutes = {minAvailableMinutes}
                          maxAvailableMinutes = {maxAvailableMinutes}
                          onAddNewIncreaseRule = {axiAddNewIncreaseRule}
                          axiUpdateTradePeriod = {axiUpdateTradePeriod}
                          axiUpdateIncreaseRule = {axiUpdateIncreaseRule}
                        />
                      ); 
                      
                    }
                  )}
                  <p>новый период</p>
                  <div
                    style={{display: 'flex'}}
                  >
                    <LocalizationProvider 
                      dateAdapter={AdapterDayjs}
                      adapterLocale={ruLocale}
                    >
                      <TimePicker
                        label="начало"
                        value={(newTimeStart + new Date().getTimezoneOffset()) *60*1000}
                        onChange={handleSetNewTimeStart}
                        renderInput={(params) => <TextField {...params} sx={{m:1, width: '120px' }}/>}
                        disableIgnoringDatePartForTimeValidation
                        maxTime = {dayjs((newTimeEnd - 1 + new Date().getTimezoneOffset()) *60*1000)}
                      />
                    </LocalizationProvider>
                    <LocalizationProvider 
                      dateAdapter={AdapterDayjs}
                      adapterLocale={ruLocale}
                    >
                      <TimePicker
                        label="окончание"
                        value={(newTimeEnd + new Date().getTimezoneOffset()) *60*1000}
                        onChange={handleSetNewTimeEnd}
                        renderInput={(params) => <TextField {...params} sx={{m:1, width: '120px'}}/>}
                        disableIgnoringDatePartForTimeValidation
                        minTime = {dayjs((newTimeStart + 1 + new Date().getTimezoneOffset()) *60*1000)}
                      />
                    </LocalizationProvider>
                  </div>
                  {
                    newTimeStart && newTimeEnd ?
                      <LoadingButton 
                        variant="contained"
                        color="success"
                        disabled={isWaiting}
                        loading={isWaiting}
                        loadingPosition="end"
                        endIcon={<AddIcon/>}
                        onClick={axiAddNewTradePeriod}
                      >
                  добавить период
                      </LoadingButton>
                      : 
                      null
                  }

                </React.Fragment>
                : 
                <CircularProgress
                  color="success" 
                  style={{
                    margin: '8px', 
                    width: '100px',
                    height: '100px'
                  }} 
                />
              }
            </Card>

            <ModalBottomButtonsBlock
              isWaiting={props.isWaiting}
              handleAccept={onPressAccept}
              textClose="Отклонить изменения"
              handleClose={handleClose}
              disabled={!!nameError}
            />
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}