import React, { useContext, useState, useEffect } from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormHelperText from '@mui/material/FormHelperText';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import ModalBottomButtonsBlock from '../../../Components/ModalBottomButtonsBlock';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ruLocale from 'date-fns/locale/ru';

import { Context } from '../../../functions/context';
import ColorPicker from '../../../Components/ColorPicker';
import axi from '../../../functions/axiosf';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import validateTextInput from '../../../functions/validateTextInput';
import kitsTypes from '../../../resources/kitsTypes';
import daysOfWeek from '../../../resources/daysOfWeek';
import moment from 'moment';
import { DatePicker, MobileDatePicker } from '@mui/x-date-pickers';
import DayOfMonthPicker from '../../../Components/DatePickers/DayOfMonthPicker';
import DayOfWeekPicker from '../../../Components/DatePickers/DayOfWeekPicker';
import DayOfYearPicker from '../../../Components/DatePickers/DayOfYearPicker';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 320,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',

};

export default function KitUpdateModal(props) {
  const context = React.useContext(Context);
  const [newName, setNewName] = useState('');
  const [nameError, setNameError] = useState('');
  const [category, setCategory] = useState('custom');
  const [newPoint, setNewPoint] = useState('');

  const [dayOfWeek, setDayOfWeek] = useState(moment().format('E'));
  const handleChangeDayOfWeek = (event) => {
    setDayOfWeek(event?.target?.value);
  };

  const [dayOfMonth, setDayOfMonth] = useState(moment());
  const handleChangeDayOfMonth = (event) => {
    console.log(+event);
    setDayOfMonth(+event);
  };

  const [dayOfYear, setDayOfYear] = useState(moment());
  const handleChangeDayOfYear = (event) => {
    console.log(+event);
    setDayOfYear(+event);
  };


  const [startDay, setStartDay] = useState(moment());
  const handleChangeStartDay = (event) => {
    setStartDay(+event);
  };


  const [arbitraryPeriod, setArbitraryPeriod] = useState(14);
  const handleChangeArbitraryPeriod = (event) => {
    setArbitraryPeriod(event?.target?.value);
  };

  const [isEdited, setIsEdited] = useState(false);

  useEffect(()=>{
    if(typeof props.item === 'object'){   
      setNewName(props.item.name);
      setCategory(props.item.type);
      setNewPoint(props.item.point ? props.item.point : '');
      
      setDayOfWeek(props.item.dayOfWeek ? props.item.dayOfWeek : '');

      let varToSetDayOfMonth = +moment('08-'+props.item?.dayOfMonth+'-2022', 'MM-DD-YYYY');
      setDayOfMonth(varToSetDayOfMonth);

      const dayOfYearBase = +props.item.dayOfYear;
      let varToSetDayOfYear = (+dayOfYearBase>0 && +dayOfYearBase<367) ? +moment(dayOfYearBase+'-2022', 'DDD-YYYY') : +moment();
      setDayOfYear(varToSetDayOfYear);

      setStartDay(props.item.startDay ? +props.item?.startDay*1000 : moment());
    
      setArbitraryPeriod(props.item.point ? props.item.arbitraryPeriod : '');
    }
  },[props.item]);

  const handleChangeNewName = (event) => {
    let newName = event?.target?.value;
    let [textError, isSetState] = validateTextInput(newName, 'имя');
    isSetState && setNewName(newName);
    setNameError(textError);
  };

  const handleSelectCategory =(event)=>{
    setCategory(event?.target?.value);
  };

  const handleChangeNewPoint = (event) => {
    setNewPoint(event?.target?.value);
  };

  const onSetIsWaiting = (boolean) => {
    if (typeof props.onSetIsWaiting === 'function'){
      props.onSetIsWaiting(boolean);
    }
  };

  const handleClose = () =>{
    if (typeof props.onOpenModal === 'function'){
      props.onOpenModal(false);
    }
  };

  
  const onPressAccept = async() => {
    onSetIsWaiting(true);
    let postParams = { 
      token: context.token, 
      name: newName,
      type: category,
      point: newPoint,
      id: props.item.id,
      dayOfWeek: dayOfWeek,
      dayOfMonth: moment(dayOfMonth).format('DD'),
      dayOfYear: moment(dayOfYear).format('DDD'),
      startDay: Math.round( +startDay/1000 ),
      arbitraryPeriod: arbitraryPeriod,
    };
    try {
      let result = await axi(
        'admin/updateKit.php', 
        '', 
        postParams); 
      if (typeof context.setAllBase === 'function'){ 
        context.setAllBase(result);
      }
    } catch (e) {
      console.log(e);
    }
    onSetIsWaiting(false);
    handleClose();
  };

  return(
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={props.isOpenModal}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <TextField
              fullWidth
              label="Название"
              id="standard-size-small"
              value={newName}
              variant="outlined"
              sx={{
                margin: '8px',
                display: 'flex',
                alignItems: 'center'
              }}
              onChange={handleChangeNewName}
              error={nameError}
              helperText={nameError}
            />

            <FormControl 
              sx={{ margin: '8px'}}
              fullWidth>
              <InputLabel id="demo-simple-select-label">Тип набора</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={category}
                label="Тип набора"
                onChange={handleSelectCategory}
              >
                {Object.keys(kitsTypes).map((kitType, keyKitType)=>
                  <MenuItem
                    key={keyKitType}
                    value={kitType} 
                  >{kitsTypes[kitType]}</MenuItem>
                )}
                {/* 
                <MenuItem
                  key={0}
                  value="custom" 
                >ручной</MenuItem>
                <MenuItem
                  key={1}
                  value="week"
                >день недели</MenuItem>
                <MenuItem
                  key={1}
                  value="month"
                >день месяца</MenuItem>
                <MenuItem
                  key={1}
                  value="year"
                >день года</MenuItem>
                */}
              </Select>
            </FormControl>

            <FormControl 
              fullWidth
              sx={{margin: '8px'}}
            >
              <InputLabel id="demo-simple-select-label">Точка</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={newPoint}
                label="Точка"
                onChange={handleChangeNewPoint}
              >
                <MenuItem
                  key={0}
                  value={0}
                >для всех</MenuItem>
                {context.salePoints.map((item, index)=> 
                  <MenuItem
                    key={item.id}
                    value={item.id}
                  >{item.name}</MenuItem>
                )}
              </Select>
            </FormControl>
            {(category !== 'custom')?
              <div style={{display: 'flex', flexDirection: 'column'}}>
                <LocalizationProvider 
                  dateAdapter={AdapterDateFns}
                  adapterLocale={ruLocale}
                >
                  <DatePicker
                    label="день отсчёта"
                    value={startDay}
                    onChange={handleChangeStartDay}
                    renderInput={(params) => 
                      <TextField 
                        {...params} 
                        sx={{m: 1, width: '160px'}}
                      />}
                    disabled = {props.isWaiting}
                  />
                </LocalizationProvider>
              
                {(category === 'week')?
                  <DayOfWeekPicker
                    dayOfWeek = {dayOfWeek}
                    onChangeDayOfWeek = {handleChangeDayOfWeek}
                    isWaiting = {props.isWaiting}
                  />
                  :
                  null
                }
                {(category === 'month')?
                  <DayOfMonthPicker
                    dayOfMonth = {dayOfMonth}
                    onChangeDayOfMonth = {handleChangeDayOfMonth}
                    isWaiting = {props.isWaiting}
                  />
                  :null
                }
                {(category === 'year')?
                  <DayOfYearPicker
                    dayOfYear = {dayOfYear}
                    onChangeDayOfYear = {handleChangeDayOfYear}
                    isWaiting = {props.isWaiting}
                  />
                  :null
                }
                {(category === 'arbitrary')? 
          
                  <FormControl sx={{
                    m: 1, 
                    width:'120px', 
                    transition: '1s',
                  }} 
                  variant="outlined">
                    <TextField
                      label="период в днях"
                      type="number"
                      variant="outlined"
                      id="standard-size-small"
                      defaultValue={arbitraryPeriod}
                      onChange={handleChangeArbitraryPeriod}
                      value={arbitraryPeriod}
                      disabled = {props.isWaiting}
                    //error={nameError}
                    //helperText={nameError}
                    />
                  </FormControl>
            
                  :null
                }
              </div>
              :
              null
            }

            <ModalBottomButtonsBlock
              isWaiting={props.isWaiting}
              handleAccept={onPressAccept}
              textClose="Отклонить изменения"
              handleClose={handleClose}
              disabled={nameError || !newName}
            />
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}