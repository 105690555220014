import React, {useState, useContext, useEffect} from 'react';


import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { Context } from '../../functions/context';
import NewUserForm from './NewUserForm';
import UsersTable from './UsersTable';
 
const Users = () => {
  const context = useContext(Context);

  useEffect(()=>{
    context.axiGetAllBase();
  },[]);
 

  return(
    <div>
    
      <div>
        <Accordion 
          sx = {{width: '100%'}}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="new"
            id="new"
          ><Typography 
              variant="h6" 
              //align="center" 
              gutterBottom 
              component="div"
              sx={{marginLeft: '16px', marginTop: '8px'}}
            >
            Введите имя, пароль и роль, чтобы добавить нового пользователя
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
     
            <NewUserForm/>
          </AccordionDetails>
        </Accordion>
        <UsersTable
          role="admins"
        />

        <UsersTable
          role="cashiers"
        />

        <UsersTable
          role="cashiers"
          fired
        />

      </div>
      
    </div>
  );
};


export { Users };