import React, {Fragment, useContext, useState, useEffect} from 'react';
import { Context } from '../../../../../functions/context';

import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import IconButton from '@mui/material/IconButton';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import SaveIcon from '@mui/icons-material/Save';
import UndoIcon from '@mui/icons-material/Undo';
import AddIcon from '@mui/icons-material/Add';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';

import Popover from '@mui/material/Popover';

import { Card, CircularProgress } from '@mui/material';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import ruLocale from 'date-fns/locale/ru';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import {speedRuleTypes, speedRuleTypesHelpComment} from '../../../../../resources/speedRuleTypes';

export default function SpeedRuleItem(props) {
  const context = useContext(Context);
  const [duration, setDuration] = useState(+props.speedRule.time);
  const [increase, setIncrease] = useState(+props.speedRule.percent);
  const [isWaiting, setIsWaiting] = useState();
  const [isEdited, setIsEdited] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClickHelp = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseHelp = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const idHelp = open ? 'simple-popover' : undefined;

  const handleChangeDuration = (event) => {
    let newNumb = +event.target.value;
    (newNumb && ( typeof newNumb === 'number' )) && setDuration(newNumb);
  };

  const handleChangeIncrease = (event) => {
    let newNumb = +event.target.value;
    (newNumb && ( typeof newNumb === 'number' )) && setIncrease(newNumb);
  };

  
  useEffect(()=>{
    setDuration(+props.speedRule.time);
  },[props.speedRule.time]);

  useEffect(()=>{
    setIncrease(props.speedRule.percent);
  },[props.speedRule.percent]);


  const handlePressEdit = () =>{
    setIsEdited(true);
  };

  const handlePressUpdate = async () => {
    setIsWaiting(true);
    //тут отправляем новые данные
    if(typeof props.axiUpdateIncreaseRule === 'function'){
      await props.axiUpdateIncreaseRule(props?.speedRule?.id, duration, increase);
    }
    setIsWaiting(false);
    setIsEdited(false);
  };

  return(
    <Card sx={{ padding: 1, marginBottom: 2 }}>
      <div 
        style={{
          display: 'flex', 
          width: '100%', 
          height: 40,
          justifyContent: 'space-between', 
          alignItems: 'center',
        }}>
        <span>{speedRuleTypes[props.speedRule.ruleType]}</span>
        <IconButton 
          aria-describedby={idHelp} 
          variant="contained" 
          onClick={handleClickHelp}>
          <LiveHelpIcon/>
        </IconButton>
        <Popover
          id={idHelp}
          open={open}
          anchorEl={anchorEl}
          onClose={handleCloseHelp}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
        >
          <p
            style = {{
              color: '#444',
              fontSize: 12,
              padding: 4,
              maxWidth: 280,
            }}
          >{speedRuleTypesHelpComment[props.speedRule.ruleType]}</p>
        </Popover>
        {isEdited &&
          <IconButton 
            variant="contained" 
            onClick={()=>{
              setIsEdited(false);
            }}>
            <UndoIcon/>
          </IconButton>
        }
      </div>

      
      {isEdited?
        <div
          style={{display: 'flex'}}
        >
          <TextField
            label="часы"
            id="standard-size-small"
            variant="outlined"
            type="number"
            sx={{width:'70px', m: 1}}
            value={duration}
            onChange={handleChangeDuration}
          />
          <TextField
            label="%"
            id="standard-size-small"
            variant="outlined"
            type="number"
            sx={{width:'70px', m: 1}}
            value={increase}
            onChange={handleChangeIncrease}
          />
          {isWaiting?
            <CircularProgress 
              color="success" 
              style={{
                margin: '8px', 
                width: '35px',
                height: '35px'
              }} 
            />
            :
            <IconButton 
              variant="contained" 
              onClick={handlePressUpdate}>
              <SaveIcon/>
            </IconButton>
          }
            
        </div>
        :
        <div
          style={{display: 'flex', alignItems: 'center'}}
        >
          <span>{props.speedRule.time} ч.  </span>
          <span>: {props.speedRule.percent} %</span>
          <IconButton 
            variant="contained" 
            onClick={handlePressEdit}>
            <BorderColorIcon/>
          </IconButton>
        </div>
      }
        
    </Card>
  );  
}