import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ruLocale from 'date-fns/locale/ru';
import { CircularProgress, Divider, ListItemSecondaryAction } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import Send from '@mui/icons-material/Send';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 360,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function ApprovalModal(props) {
  

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () =>{
    setOpen(false);
    if(typeof props.onSetIsModalOpen === 'function'){
      props.onSetIsModalOpen(false);
    }
  };
  React.useEffect(()=>{
    setOpen(props.isOpen);
  },[props.isOpen]);

  const handleAccept = () => {
    if (typeof props.onPressConfirmSend === 'function'){
      props.onPressConfirmSend();
    }
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            {props.cleanInvoice && props.cleanInvoice.map((item, index)=>
              <>
                {index ? <Divider/> : null}
                <div 
                  key={item.name}
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    margin: '8px',
                  }}>
                  <span>{item.name}</span>
                  <span>{item.quantity}</span>
                </div>
              </>
            )}    
            <div style={{
              display:'flex',
              flexDirection: 'column',
              justifyContent: 'space-around',
              alignItems: 'center',
              marginTop:'10px',
              height: '100px',
            }}>
              <LoadingButton 
                variant="contained"
                color="success"
                //disabled={props.isWaiting}
                loading={props.isWaiting}
                loadingPosition="end"
                endIcon={<Send/>}
                onClick={handleAccept}
              >
                Отправить набор
              </LoadingButton>
              <Button 
                variant="contained"
                color="error"
                onClick={handleClose}>Вернуться к редактированию</Button>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}