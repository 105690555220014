import axi from '../../../../functions/axiosf';

const setSettings = async (data) => {

  try {
    let result = await axi(
      'admin/subscribes/setSettings.php',
      '', 
      {...data});
    let resultObj = {};
    result.subscribesSettings?.map((item) => { 
      resultObj[item.eventType] = { ...item }; 
    });
    return {data: {...resultObj}};
          
  } catch (error){
    return {error: error};
  }
};

export default setSettings;