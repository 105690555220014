/* eslint-disable eqeqeq */
import React, { useContext, useEffect, useState, useRef, Fragment } from 'react';

import { Context } from '../../../functions/context';
import moment from 'moment';
import hourMinFromMinutes from '../../../functions/hourMinFromMinutes';
import { stylesObj } from '../../../stylesObj/stylesObj';
import invoiceTypesColors from '../../../stylesObj/invoiceTypesColors';
import invoiceTypes from '../../../resources/invoiceTypes';
  
   
const InvoiceTile = ({item, index, endRemainsHeader, quantity}) => {
  const context = useContext(Context);
  return(
    <div
      key = {index}
      style = {{
        padding: 4,
        backgroundColor: invoiceTypesColors[item?.invoice?.type]
      }}
    > 
      <div>
        {invoiceTypes[item?.invoice?.type]}
      </div>
      <div>
        {'№ ' + item?.invoice?.id}
      </div>
      <div>
        {endRemainsHeader + quantity}
      </div>
    </div>
  );
};

export { InvoiceTile };