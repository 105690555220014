const addressesReducer = (state, action) =>{
  switch (action.type) {
    case 'START_WAITING': {
      return {...state, isWaiting: true, isDisableSaveButton: true};
    }
    case 'GET_STATE': {  
      return {...state, isWaiting: false, ...action.payload}; 
    }     
    case 'ADD_NEW_VALUE': {
      return {...state, isWaiting: false, ...action.payload}; 
    }  
    case 'DELETE': {
      return {...state, isWaiting: false, ...action.payload}; 
    }  
    case 'HANDLE_CHANGE_NEW_VALUE': {
      return {
        ...state, 
        newValue: action.payload.value, 
        error: action.payload.error, 
        isWaiting: false,
        isDisableSaveButton: action.payload.isDisableSaveButton,
      };   
    }
    case 'ERROR': {
      return {
        ...state, 
        error: action.payload.error, 
        isDisableSaveButton: action.payload.isDisableSaveButton, 
      };   
    }
    default:
      throw Error('Unknown action: ' + action.type);
  }
};

export default addressesReducer;