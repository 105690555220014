/* eslint-disable eqeqeq */
import React, { useContext, useEffect, useState, useRef, Fragment, forwardRef } from 'react';

import styled, { keyframes } from 'styled-components';

import Typography from '@mui/material/Typography';

import { Context } from '../../../functions/context';
import { Checkbox, CircularProgress, FormControlLabel, Alert } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import moment from 'moment';
import invoiceTypes from '../../../resources/invoiceTypes';
import invoiceTypesColors from '../../../stylesObj/invoiceTypesColors';
import axi from '../../../functions/axiosf';
import propsF from '../../../functions/propsF';
import NoPrint from '../../../Components/NoPrint';

const tableRowStyle = {

  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  
  borderStyle: 'dashed',
  borderWidth: '0px',
  borderTopWidth: '1px',

  borderTopColor: '#5555',
  flexWrap: 'wrap',
};

// eslint-disable-next-line react/display-name
const Invoice = forwardRef((props, ref) => {
  let { salePoint, currentInvoice, deliverUser, signedUser} = props;
  const context = useContext(Context);
  const [selectedItems, setSelectedItems] = useState([]);
  const [isWaiting, setIsWaiting] = useState(false);
  
  //тут при удалении всех 0х позиций по какой то причине галочка остаётся стоять и её невозможно снять (баг)
  const isSelectAll = () => {
    //проверяем все ли нулевые позиции из currentInvoice лежат в selectedItems
    let flagIsAll = true;
    let flagIsWithoutEmpty = true; 
    currentInvoice.map((inItem, index) => { //currentInvoice вылетает ошибка, что не определено
      if(+inItem.quantity < 1){
        flagIsWithoutEmpty = false;
        let isCheck = selectedItems.some((element) => +element === +inItem.product);
        if(!isCheck){
          flagIsAll = false; 
        };
      }
    });
    if (flagIsWithoutEmpty) {
      flagIsAll = false;
    }
    return(flagIsAll);
  };

  const handleChangeSelectAll = () => {
    let newSelectedItems = [];
    if (selectedItems.length>0){
      //тут если что то есть в selectedItems - то всё удаляем оттуда
      setSelectedItems(newSelectedItems);
    }else{
      //если ничего нет  - набираем все id нулевых позиций
      currentInvoice.map((inItem, index) => {
        if(+inItem.quantity < 1){
          newSelectedItems = [...newSelectedItems, +inItem.product];
        }
      });
      setSelectedItems(newSelectedItems);
    }
  }; 

  const handleChangeSelectedItems = (inItem, isCheck) => {
    let newSelectedItems = [];
    if (isCheck){
      selectedItems.map((checkedItem)=>{
        if(+checkedItem !== +inItem.product){
          newSelectedItems = [...newSelectedItems, +checkedItem];
        };
      });
    }else{
      newSelectedItems = [...selectedItems, +inItem.product];
    }
    setSelectedItems(newSelectedItems);
  };

  const axiDeleteFromInvoice = () => {
    propsF(props.setInfoText, '');
    setIsWaiting(true);
    axi(
      'admin/deleteItemFromInvoice.php', 
      '', 
      { 
        token: context.token, 
        invoice: props.invoice?.id,
        productsArray: selectedItems
      }
    ).then(
      (result) => {
        propsF(props.setInfoText, result.text);
        propsF(props.setTypeInfo, result.status);
        if(result.status === 'success'){
          setSelectedItems([]);
          propsF(props.onGetInvoiceList);
        }else{
          console.log(result);
        }
        setIsWaiting(false);
      }, 
      (e) => {
        setIsWaiting(false);
        console.log(e);
      }
    ); 
  };

  const axiDeleteFromShop = () => {
    propsF(props.setInfoText, '');
    setIsWaiting(true);
    axi(
      'admin/deleteItemFromShop.php', 
      '', 
      { 
        token: context.token, 
        shop: salePoint[0]?.id,
        productsArray: selectedItems
      }
    ).then(
      (result) => {  
        //на бекенде перед удалением из магазина каждую строчку читаем и проверяем, есть ли там остатки в данным момент.
        //те строчки, которые содержать остаток возвращаем в ответ как ошибку и рендерим тут табличку в варнинге "позиция: количество"
        if(result.status === 'success'){
          setSelectedItems([]);
          propsF(props.onGetInvoiceList);
        }else{
          console.log(result);
        }
        propsF(props.setInfoText, result.text);
        propsF(props.setTypeInfo, result.status);
        setIsWaiting(false);
      }, 
      (e) => {
        setIsWaiting(false);
        console.log(e);
      }
    ); 
  };

  return(
    <InvoiceTable ref={ref}>
      <tbody>
        <tr
          style={{...tableRowStyle, justifyContent: 'flex-start'}}
        >
          <td>номер накладной: </td>
          <td><b>{props.invoice?.id}</b></td>
        </tr>
        <tr
          style={tableRowStyle}
        >
          <td>дата и время накладной: </td>
          <td>&nbsp;&nbsp; {moment(+props.invoice?.time * 1000).format('LLL')}</td>
        </tr>
        <tr
          style={tableRowStyle}
        >
          <td style={{width: '100px'}}>место отправки: </td>
          <td>{props.invoice?.type==='shipmentGoods' ? 'склад' : salePoint[0]?.name}</td>
          <td>{props.invoice?.type==='shipmentGoods' ? '' : salePoint[0]?.address}</td>
        </tr>
        <tr
          style={tableRowStyle}
        >
          <td style={{width: '100px'}}>место назначения: </td>
          <td>{props.invoice?.type==='returnsGoods' ? 'склад' : salePoint[0]?.name}</td>
          <td>{props.invoice?.type==='returnsGoods' ? '' : salePoint[0]?.address}</td>
        </tr>
        <tr
          style={{...tableRowStyle, paddingTop: 16}}
        >
          <td style={{flex: 1}}><b>наименование</b></td>
          {(props.invoice?.type === 'acceptanceGoods') ? 
            <td style={{width: '80px', textAlign: 'right'}}><b>пришло</b></td> 
            : ''
          }
          {(props.invoice?.type === 'acceptanceGoods') ? 
            <td style={{width: '80px', textAlign: 'right'}}><b>принято</b></td> 
            : <td style={{width: '100px', textAlign: 'right'}}><b>количество</b></td>
          }
          {!props.isHideEmpty ?
            <td style={{width: '50px', textAlign: 'right'}}>
              <Checkbox
                checked = {isSelectAll()}
                onChange = {handleChangeSelectAll}
              />
            </td>
            : ''
          }     
        </tr>
        {currentInvoice.map((inItem, index) => {
          if (props.isHideEmpty && +inItem.quantity < 1){
            return ('');
          }else{
            let currentProductObj = context.nomenclature.filter((produkt) => +produkt.id === +inItem.product);
            let isCheck = selectedItems.some((element) => +element === +inItem.product);
            return(
              <Fragment
                key = {index}>
                <tr
                  style={{
                    minHeight: '40px',
                    ...tableRowStyle,
                  }}
                >
                  <td style={{flex: 1}}><Typography>{currentProductObj[0]?.name}</Typography></td>
                  {(props.invoice?.type === 'acceptanceGoods') ?
                    <td style={{width: '80px', textAlign: 'right'}}><Typography>{inItem.incomeQuantity}</Typography></td>
                    :''
                  }
                  <td style={{width: '80px', textAlign: 'right'}}><Typography>{inItem.quantity}</Typography></td>
                  {!props.isHideEmpty ?
                    <td style={{width: '50px', textAlign: 'right'}}>
                      {+inItem.quantity < 1 ?
                        <Checkbox
                          checked = {isCheck}
                          onChange = {() => {
                            handleChangeSelectedItems(inItem, isCheck);
                          }}
                        />
                        : ''
                      }
                    </td>  
                    : ''
                  }    
                </tr>
                {inItem.comment ?
                  <tr
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <td><i>комментарий {props.invoice?.type==='shipmentGoods' ? 'склада' : 'буфета'}:</i></td>
                    <td><i>{inItem.comment}</i></td>
                  </tr>
                  :''
                }
              </Fragment>
            );
          }
        })}
        <tr
          style={{...tableRowStyle, paddingTop: 16}}
        >
          <td>отправитель: </td>
          <td>{deliverUser[0]?.name}</td>
        </tr>
        {(props.invoice?.type === 'acceptanceGoods' || props.invoice?.type === 'returnsGoods')?
      
          <tr
            style={tableRowStyle}
          >
            <td>получатель: </td>
            <td>{signedUser[0]?.name}</td>
          </tr>
          :''
        }
        {(props.invoice?.type === 'shipmentGoods')?
      
          <tr
            style={tableRowStyle}
          >
            <td>получатель: </td>
            <td>{signedUser[0]?.name}</td>
          </tr>
          :''
        }
        {!props.isHideEmpty ?
          <Fragment>
            <tr>
              <td style={{ 
                textAlign: 'right',
              }}>
                <NoPrint>
                  <b
                    style={{ 
                      marginRight: 8,
                      marginTop: 8
                    }}> 
                    удаление выбранных позиций: 
                  </b>
                </NoPrint>
              </td>
            </tr>
            <tr>
              <NoPrintTd style={{ textAlign: 'right'}}>
                <LoadingButton
                  variant="contained"
                  color="error"
                  disabled={isWaiting || !(selectedItems.length)}
                  loading={isWaiting}
                  loadingPosition="end"
                  endIcon={<DeleteSweepIcon />}
                  onClick={axiDeleteFromInvoice}
                  sx={{ margin: 1}}
                >
                  из накладной
                </LoadingButton>
                <LoadingButton
                  variant="contained"
                  color="error"
                  disabled={isWaiting || !(selectedItems.length)}
                  loading={isWaiting}
                  loadingPosition="end"
                  endIcon={<DeleteForeverIcon />}
                  onClick={axiDeleteFromShop}
                  sx={{ margin: 1 }}
                >
                  из точки
                </LoadingButton>
              </NoPrintTd>
            </tr>
          </Fragment>
          : ''
        } 
      </tbody>
      
    </InvoiceTable>
  );
  
});

const NoPrintTd = styled.td`
  @media print {
    display: none;
  }
`;

const InvoiceTable = styled.table`
  width: 100%; 
  @page {
    margin: 0.5in;
  }
  @media print {
    padding: 20px;
  }
`;

export { Invoice };