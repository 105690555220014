/* eslint-disable eqeqeq */
import React, { Fragment, useContext, useEffect, useState } from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ruLocale from 'date-fns/locale/ru';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import Paper from '@mui/material/Paper';

import axi from '../../functions/axiosf';
import axios from 'axios';

import {stylesObj} from '../../stylesObj/stylesObj';

import { Context } from '../../functions/context';
import { CircularProgress, Typography } from '@mui/material';
import moment from 'moment';
import { ControlNomenclatureItem } from './ControlNomenclatureItem';
import { ControlNomenclatureFilters } from './ControlNomenclatureFilters';

import isResultObjectOk from '../../functions/isResultObjectOk';
import dateInMsToSeconds from '../../functions/dateInMsToSeconds';

const ControlNomenclatureEnded = () => {

  const labelTextAll = 'Все';

  const context = useContext(Context);

  const minDate = +moment([2015]).startOf('year').startOf('day');
  const maxDate = +moment().endOf('year').endOf('day');

  const [isStartWaiting, setIsStartWaiting] = useState(false);
  const [violations, setViolations] = useState([]);
  // const [pointsObj, setPointsObj] = useState(0);


  const [date, setDate] = useState(+moment().startOf('day')); // тут по умолчанию поставить не текущее время а начало дня
  const [dateError, setDateError] = useState('');

  // Фильтры по категории, точке, продавцу, пагинация по дате
  const [cashier, setCashier] = useState(0);
  const [point, setPoint] = useState(0);
  const [category, setCategory] = useState(0);
  
  const [showOnlyEndedProducts, setShowOnlyEndedProducts] = useState(false);


  const axiGetControlNomenclatureEnded = (axiCancelToken) => {
    if (minDate <= date && date <= maxDate) {
      setIsStartWaiting(true);
      axi(
        'admin/errorControl/getFirstAndSoldOut.php', 
        '', 
        { token: context.token, date: dateInMsToSeconds(date) },
        axiCancelToken
      ).then(
        (result) => { 
          if (result.type === 'no_authorized') {
            if(typeof context.userExit === 'function'){
              context.userExit();
            }
          }else{
            if (isResultObjectOk(
              result,
              ['keyEvents'],
              'ошибка в получении списка',
              setDateError)
            ) {
              setViolations(result.keyEvents);
            } else {
              setViolations([]);
            }
          }
          setIsStartWaiting(false);
        }, 
        (e) => {
          console.log(e);
          setIsStartWaiting(false);

        }
      );
    } else {
      setDateError('Неактуальная дата');
    }
  };


  useEffect(() => {
    if (
      context.salePoints.length>0 
      && context.cashiers.length > 0 
      && context.types.length > 0 
    ) {
      const axiCancelToken = axios.CancelToken.source();
      axiGetControlNomenclatureEnded(axiCancelToken);
      return () => {
        axiCancelToken.cancel();
      };
    } else {
      return () => {};
    }
  }, [date, 
    // context.salePoints, 
    context.cashiers, 
    context.types]);


  const handleChangeDate = (value) => {
    setDateError('');
    let newDate = +value;
    setDate(newDate);
  };

  const isDateError=()=> dateError !== '';
    
  const isMatchCategory = (item) => {
    let isNomenclatureSome = context.nomenclature.some((product) =>    
      (+product.id === +item.productId)
            &&(+product.type === category)
    );
    return(isNomenclatureSome);
  };

  const isPassedFilters=(item)=>{

    return (
      (point === 0 
        || item.salePointId === point) 
      && (cashier === 0 
        || item.cashierId === cashier) 
      && (category === 0 
        || isMatchCategory(item))
      && (showOnlyEndedProducts===false
        || item.lastSaleMinute!='0'
      ) 
    );
  };

  // console.log(context.cashiers);
  // console.log(context.nomenclature);
  // console.log(context.types);
  
  return(
    <div>
      <div style={stylesObj.simpleRowWrap} >
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          adapterLocale={ruLocale}
        >
          <DatePicker
            label='Дата'
            value={date}
            onChange={(newValue) => {
              handleChangeDate(newValue);
            } }
            renderInput={(params) => (
              <TextField
                style={stylesObj.selectFilter}
                {...params}
                error={isDateError()}
                helperText={dateError}
                onFocus={(event) => {
                  event.target.select();
                } }
                autoFocus 
              />
            )} 
          />
        </LocalizationProvider>

        <ControlNomenclatureFilters
          labelTextAll={labelTextAll}

          point={point}
          setPoint={setPoint}
          cashier={cashier}
          setCashier={setCashier}
          category={category}
          setCategory={setCategory}
          showOnlyEndedProducts={showOnlyEndedProducts}
          setShowOnlyEndedProducts={setShowOnlyEndedProducts}
        />
      </div>
      <Typography>
        В таблице указан каждый факт начала продаж товара и момента когда он закончился, но должен был быть в наличии
      </Typography>
      {isStartWaiting ?
        <CircularProgress
          size={56}
        />
        :
        <Fragment>

          <TableContainer 
            //className={style.Paper} 
            component={Paper}>
            <Table 
              aria-label="collapsible table" 
              //className={style.Paperbody} 
            >
              <TableBody>
                <TableRow 
                  //className={style.Paperhead} 
                >                    
                  <TableCell component="th" scope="row" align="left" sx={{maxWidth: 120}}>точка</TableCell>              
                  <TableCell component="th" scope="row" align="left" sx={{maxWidth: 120}}>кассир</TableCell> 
                  <TableCell component="th" scope="row" align="left" sx={{maxWidth: 120}}>товар</TableCell>  
                  <TableCell component="th" scope="row" align="left" sx={{maxWidth: 80}}>продано за день, шт</TableCell>  
                  <TableCell component="th" scope="row" align="left">движение товара за день</TableCell>
                  <TableCell component="th" scope="row" align="left" sx={{maxWidth: 120}}>период обязательного наличия</TableCell>   
                  <TableCell component="th" scope="row" align="right" sx={{maxWidth: 80}}>первая продажа</TableCell>             
                  <TableCell component="th" scope="row" align="left" sx={{maxWidth: 80}}>отсутствие товара с</TableCell>   
                </TableRow>
                {violations?.map((Item, Index) => {
                  if (isPassedFilters(Item)) {
                    return (
                      <ControlNomenclatureItem
                        key = {Index}
                        item = {Item}
                      />
                    );
                  } else {
                    return null;
                  }    
                })}
              </TableBody>
            </Table>
          </TableContainer>
         

        </Fragment>
      }

    </div>
  );
  
};

export { ControlNomenclatureEnded };