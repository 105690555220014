import axi from '../../../../functions/axiosf';

const getSubscribes = async (data) => {
  try {
    let result = await axi(
      'admin/subscribes/getSubscribes.php', 
      '', 
      {...data});
    return {data: [...result.subscribes]};
          
  } catch (error){
    return {error: error};
  }
};

export default getSubscribes;