import React, { Fragment, useContext, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';


import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ruLocale from 'date-fns/locale/ru';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';

import moment from 'moment';
import TextField from '@mui/material/TextField';
import {stylesObj} from '../../../stylesObj/stylesObj';

import axi from '../../../functions/axiosf';

import { Context } from '../../../functions/context';

import SettingsItemInput from './SettingsItemInput';
import rootSettingsFields from '../../../resources/rootSettingsFields';

const RootSettings = () => {
  const context = useContext(Context);
  const [licensePassword, setLicensePassword] = useState('');
  const [error, setError] = useState('');
  const [isShow, setIsShow] = useState(false);
  const [isWaiting, setIsWaiting] = useState(false);
  const [values, setValues] = useState(false);

  
  const handleChangePassword = (e) => {
    if (e.target.value.length < 4){
      setError('длинна должна быть больше трёх символов');
    }else{
      setError('');
    }
    setLicensePassword(e.target.value);
  };

  const getValues = async () => {
    setIsWaiting(true);
    try {
      let result = await axi(
        'rootSettings/getRootSettings.php',
        '', 
        { 
          rootPassword: licensePassword,
          token: context.token, 
          fields: rootSettingsFields,
        });  
      if (result.err){
        setError(result.err);
      }else{
        if(result.fields){
          
          setValues(result.fields);
          setIsShow(true);
        }
      }
  
    } catch (e) {
      alert(e);
    } finally {     
      setIsWaiting(false);
    }
  };

  useEffect(()=>{
  },[]);

  return(
    <Fragment>
      {!isShow ?
        <div
          style={{
            ...stylesObj.simpleRowWrap,
            backgroundColor: '#ffde9b88',
            borderWidth: '1px',
            borderStyle: 'outset',
            borderColor: '#ffde9b88',
            borderRadius: 4,

          }}
        >
          <TextField
            label={'лицензионный ключ'}
            id="standard-size-normal"
            variant="outlined"
            sx={{flex:3, minWidth: '280px', margin: 1}}
            disabled = {isWaiting}
            type = "password"
            onChange = {handleChangePassword}
            value = {licensePassword}
            error = {!!error}
            helperText = {error}
          />
          <LoadingButton 
            color="success"
            variant="contained"
            sx={{flex: 1, margin: 1, padding: 2, height: 56}}
            disabled={ isWaiting || licensePassword.length<4 }
            loading={ isWaiting }
            loadingPosition="end"
            endIcon={<SendIcon/>}
            onClick={getValues}
          >
            войти
          </LoadingButton>

        </div>
        :
        rootSettingsFields.map((settingsField, indexSettingsField)=>
          <SettingsItemInput 
            key = {indexSettingsField}
            {...settingsField}
            value = {values[settingsField.name]}
            isWaiting = {isWaiting}
            onGetAllSettings = {getValues}
            licensePassword = {licensePassword}
          />
        )
      }
    </Fragment>
  );
  

};

export default RootSettings;