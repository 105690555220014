import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Checkbox, FormControlLabel, TextField, Divider } from '@mui/material';

import {stylesObj} from '../../stylesObj/stylesObj';
import { Context } from '../../functions/context';

const CheckBoxWithInput = ({
  name,
  label,
  inputLabel,
  isActive,
  value,
  isWaiting,
  onToggle,
  onChangeValue,
}) => {
  const context = useContext(Context);

  
  const handleChangeValue = (e) => {
    if ( typeof onChangeValue === 'function'){
      onChangeValue(e);
    }
  };

  const handleToggle = (e) => {
    if ( typeof onToggle === 'function'){
      onToggle(e);
    }
  };

  return(
    <Fragment>
      <Divider variant="fullWidth" sx = {stylesObj.margin8}/>
      <div
        style={{
          ...stylesObj.simpleRowWrap,
          justifyContent: 'space-between'
        }}
      >
        <FormControlLabel 
          control={
            <Checkbox 
              checked = {!!(+isActive)}
              name = {name}
              onChange={handleToggle}/>
          } 
          label = {label}
          disabled = {!!isWaiting} 
          sx={{
            minWidth: 280,
            maxWidth: 350
          }}
        />
        {inputLabel &&
        <TextField
          label={inputLabel}
          id="standard-size-normal"
          variant="outlined"
          sx={{width: 130}}
          margin="normal"
          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
          value = {typeof value === 'number' || typeof value === 'string' ? +value : '' }
          name = {name}
          onChange={handleChangeValue}
          disabled = {!!isWaiting || !(+isActive)}
        />
        }
      </div>
    </Fragment>
  );
};

export default CheckBoxWithInput;