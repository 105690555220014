import React, {useState, useContext} from 'react';
import Table from '@mui/material/Table';
import {TableBody, TableHead, TableRow, TableCell} from '@mui/material';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import CategoryRow from './NomenclatureRow';
import NomenclatureUpdateModal from './NomenclatureUpdateModal';
import { Context } from '../../../functions/context';
import axi from '../../../functions/axiosf';



export default function NomenclatureTable({
  category,
  sortedNomenclature,
  setIsCategoriesSorted,
}) {
  const context = useContext(Context);
  
  const [isOpenModal, setIsOpenModal] = useState(false);
  
  const [isWaiting, setIsWaiting] = useState(false);

  const renderNomenclature=(item,index)=>{
    if (category===0 || item.type===category) {
      return <CategoryRow
        key = {item.id}
        index = {index}
        item = {item}
        isWaiting = {isWaiting}  
        onOpenModal={setIsOpenModal}
      />;
    } else {
      return null;
    }
  };

  return(
    <div style={{display:'flex'}}>
      {typeof isOpenModal === 'number' ?
        <NomenclatureUpdateModal
          indexOf={isOpenModal}
          isOpenModal={typeof isOpenModal === 'number'}
          onOpenModal={setIsOpenModal}
          onSetIsWaiting={setIsWaiting}
          isWaiting={isWaiting}
          sortedNomenclature={sortedNomenclature}
          setIsCategoriesSorted={setIsCategoriesSorted}
        /> 
        :
        null
      }
      <TableContainer component={Paper} sx={{ maxWidth: 500 }}>
        <Table aria-label="caption table">
          <TableHead>
            <TableRow >  
              <TableCell>Наименование</TableCell>
              <TableCell align="right">категория</TableCell>
              <TableCell>действия</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedNomenclature.length 
              ? sortedNomenclature.map((item,index)=>renderNomenclature(item,index))
              : null
            }
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
