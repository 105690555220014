import React, { useContext, useState, useEffect } from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormHelperText from '@mui/material/FormHelperText';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import ModalBottomButtonsBlock from '../../../Components/ModalBottomButtonsBlock';
import { Context } from '../../../functions/context';
import ColorPicker from '../../../Components/ColorPicker';
import axi from '../../../functions/axiosf';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import validateTextInput from '../../../functions/validateTextInput';
import { Checkbox, FormControlLabel } from '@mui/material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 320,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',

};

export default function UsersUpdateModal(props) {
  const context = React.useContext(Context);
  const [newName, setNewName] = useState('');
  const [nameError, setNameError] = useState('');
  const [newPass, setNewPass] = useState('');
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [passError, setPassError] = useState('');
  const [newPoint, setNewPoint] = useState('');
  const [isEdited, setIsEdited] = useState(false);
  const [isActive, setIsActive] = useState(false);

  useEffect(()=>{
    if(typeof props.item === 'object'){   
      setNewName(props.item.name);
      setNewPass(props.item.password); 
      setNewPoint(props.item.point ? +props.item.point : '');
      setIsActive(!!(+props.item.isFired));
    }
  },[props.item]);

  const handleChangeNewName = (event) => {
    let newName = event?.target?.value;
    let [textError, isSetState] = validateTextInput(newName, 'имя');
    isSetState && setNewName(newName);
    setNameError(textError);
  };

  const handleChangePass = (event) => {
    let newPass = event?.target?.value;
    let [textError, isSetState] = validateTextInput(newPass, 'пароль');
    isSetState && setNewPass(newPass);
    setPassError(textError);
  };

  const handleChangeNewPoint = (event) => {
    setNewPoint(+event?.target?.value);
  };
  
  const handleToggleIsFired = (event) => {
    setIsActive(event?.target?.checked);
    setNewPoint(0);
  };

  const onSetIsWaiting = (boolean) => {
    if (typeof props.onSetIsWaiting === 'function'){
      props.onSetIsWaiting(boolean);
    }
  };

  const handleClose = () =>{
    if (typeof props.onOpenModal === 'function'){
      props.onOpenModal(false);
    }
  };

  
  const handleClickShowPassword = () => {
    setIsShowPassword(!isShowPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const onPressAccept = async() => {
    onSetIsWaiting(true);
    let postParams = { 
      token: context.token, 
      Name: newName,
      pass: newPass,
      role: props.role,
      userId: props.item.id,
      isFired: isActive ? Math.round(+ new Date()/1000) : 0,
    };
    if (newPoint){
      postParams={...postParams, point: newPoint};
    }
    try {
      let result = await axi(
        'admin/updateUser.php', 
        '', 
        postParams); 
      if (typeof context.setAllBase === 'function'){ 
        context.setAllBase(result);
      }
    } catch (e) {
      console.log(e);
    }
    onSetIsWaiting(false);
    handleClose();
  };

  return(
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={props.isOpenModal}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <TextField
              fullWidth
              label="Имя"
              id="standard-size-small"
              value={newName}
              variant="outlined"
              sx={{
                margin: '8px',
                display: 'flex',
                alignItems: 'center'
              }}
              onChange={handleChangeNewName}
              error={nameError}
              helperText={nameError}
            />
            <FormControl 
              sx={{
                margin: '8px', 
              }} 
              fullWidth
              variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">Пароль</InputLabel>
              <OutlinedInput
                label="Пароль"
                variant="outlined"
                id="standard-size-small"
                onChange={handleChangePass}
                type={isShowPassword ? 'text' : 'password'}
                value={newPass}
                error={passError}
                helperText={passError}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {isShowPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <FormHelperText error>{passError}</FormHelperText>
            </FormControl>
            { typeof newPoint === 'number' &&
              <FormControl 
                fullWidth
                sx={{margin: '8px'}}
              >
                <InputLabel id="demo-simple-select-label">Точка</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={newPoint}
                  label="Точка"
                  onChange={handleChangeNewPoint}
                  disabled = {!!isActive}
                >
                  {context.salePoints.map((item, index)=> 
                    <MenuItem
                      key={item.id}
                      value={item.id}
                    >{item.name}</MenuItem>
                  )}
                </Select>
              </FormControl>
            }
            {props.role === 'cashiers' ?
              <FormControlLabel
                control={
                  <Checkbox
                    checked = {!!(+isActive)}
                    onChange={handleToggleIsFired}/>
                } 
                label = "сотрудник уволен"
                disabled = {!!props.isWaiting} 
                sx={{marginLeft: 1}}
              />
              : null
            }
            <ModalBottomButtonsBlock
              isWaiting={props.isWaiting}
              handleAccept={onPressAccept}
              textClose="Отклонить изменения"
              handleClose={handleClose}
              disabled={nameError || passError}
            />
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}