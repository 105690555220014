/* eslint-disable eqeqeq */
import React, { useContext, useEffect, useState } from 'react';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ruLocale from 'date-fns/locale/ru';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';

import {stylesObj} from '../../stylesObj/stylesObj';

import axi from './../../functions/axiosf';

import { Context } from '../../functions/context';

import { ChecksFilters } from './ChecksFilters';
import { ChecksHeader } from './ChecksHeader';
import { ChecksList } from './ChecksList';


import styled from 'styled-components';
import styles from './index.module.css';

import moment from 'moment';
import axios from 'axios';

import isResultObjectOk from '../../functions/isResultObjectOk';
import dateInMsToSeconds from '../../functions/dateInMsToSeconds';
import { MOBILE_SCREEN_WIDTH } from '../../stylesObj/layoutConsts';


const Checks = () => {
  const labelTextAll = 'Все';

  const context = useContext(Context);

  const minDate = +moment([2015]).startOf('year').startOf('day');
  const maxDate = +moment().endOf('year').endOf('day');

  const [isStartWaiting, setIsStartWaiting] = useState(false);
  const [allChecks, setAllChecks] = useState([]);

  const [date, setDate] = useState(+moment().startOf('day')); // тут по умолчанию поставить не текущее время а начало дня
  const [dateError, setDateError] = useState('');

  const [kkt, setKkt] = useState(0);
  const [point, setPoint] = useState(0);
  const [category, setCategory] = useState(0);

  const [timeFromTo, setTimeFromTo] = useState([0, 24]);

  const [headTotalSum, setHeadTotalSum] = useState(0);
  const [headTotalCount, setHeadTotalCount] = useState(0);

  const sortOrderTypes = {
    No: 0,
    TimeAsc: 1,
    TimeDesc: 2,
    SumAsc: 3,
    SumDesc: 4,
  };
  const [sortOrder, setSortOrder] = useState(0);
  const [sortButtonsEnabled, setSortButtonsEnabled] = useState(true);


  const nomenclatureItemAll = { label: labelTextAll, id: 0 };
  const [nomenclatureOptions, setNomenclatureOptions] = useState([nomenclatureItemAll]);
  const [nomenclature, setNomenclature] = useState(nomenclatureItemAll);
  const [nomenclatureInput, setNomenclatureInput] = useState(labelTextAll);
  
  const [showZeroChecks, setShowZeroChecks] = useState(true);
  
  
  // const [filtersExpanded, setFiltersExpanded] = useState(false);

  const onSetIsScroll = (isScroll) => {};

  

  const makeChecksAndTotal = (data,date) => {
    let checks = {};
    let totalSum = 0;
    let totalCount = 0;

    // because of time zone, filter by day of month
    let today=+moment(date).date();
    // console.log(today);
    let dayOfMonthIsOk;

    data.map((line) => {
      if (checks[line.checkId] === undefined) {
        let checkTime = (+line.time)*1000;
        
        // filter by day of month
        if (+moment(checkTime).date()!==today) {
          dayOfMonthIsOk=false;

        } else {
          dayOfMonthIsOk=true; 

          // new check
          totalCount += 1;

          checks[line.checkId] = {
            kktRegNumber: line.KktRegNumber,
            salePointId: +line.salePointId,
            products: [],
            total: 0,
            time: line.time,
            timeH: +moment(checkTime).hours(),
          };
        }
      }
      if (dayOfMonthIsOk) {
        let check = checks[line.checkId];
        let products = check.products;
        // find category
        let category = context.nomenclature.find(
          (product) => product.id === line.product
        ).type;
        // console.log(line.Name,category);
        let productTotal=+line.Total;
        // add product
        products.push({
          id: +line.product,
          category: +category,
          name: line.Name,
          price: +line.Price,
          quantity: +line.Quantity,
          total: productTotal,
        });
        check.total += productTotal;
        totalSum += productTotal;
      }
    });

    setHeadTotalSum(totalSum);
    setHeadTotalCount(totalCount);

    return Object.keys(checks).map((key) => {
      return {
        id: key,
        ...checks[key],
      };
    });
  };

  

  const axiGetAllChecks = (axiCancelToken) => {
    // console.log(date);
    if (minDate <= date && date <= maxDate) {
      setIsStartWaiting(true);
      axi(
        'admin/getSalesHistory.php',
        '',
        { token: context.token, date: dateInMsToSeconds(date) },
        axiCancelToken
      ).then(
        (result) => {
          // console.log(result);
          if (isResultObjectOk(result,['invoices'],'ошибка в получении списка',setDateError)) {
            let checks = makeChecksAndTotal(result.invoices,date);
            // console.log('checks',checks);
            setAllChecks(checks);
          } else {
            setAllChecks([]);
          }
          setIsStartWaiting(false);
        },
        (e) => {
          console.log(e);
          setIsStartWaiting(false);
        }
      );
    } else {
      setDateError('Неактуальная дата');
    }
  };

  
  useEffect(() => {
    if (
      context.nomenclature.length > 0 
      && context.kkts.length > 0 
      && context.types.length > 0 
    ) {
      const axiCancelToken = axios.CancelToken.source();
      axiGetAllChecks(axiCancelToken);
      return () => {
        axiCancelToken.cancel();
      };
    } else {
      return () => {};
    }
  }, [date, 
    context.nomenclature, 
    context.kkts, 
    context.types]);

  const handleChangeDate = (value) => {
    setDateError('');
    let newDate = +value;
    setDate(newDate);
  };

  const isDateError=()=> dateError !== '';
  
  // const handleFiltersExpanded=(event,expanded)=> {
  //   setFiltersExpanded(expanded);
  // };

  // const showDateIfFiltersExpanded=()=>{
  //   if (filtersExpanded) {
  //     return <>
  //       Фильтры:
  //     </>;
  //   } else {
  //     return <>
  //       Дата:&nbsp;&nbsp;<b>{moment(date).format('DD.MM.YYYY')}</b>
  //     </>;
  //   }
  // };

  const checksFiltersComponent=()=>{
    return <ChecksFilters
      labelTextAll={labelTextAll}

      point={point}
      setPoint={setPoint}
      kkt={kkt}
      setKkt={setKkt}
      category={category}
      setCategory={setCategory}
      timeFromTo={timeFromTo}
      setTimeFromTo={setTimeFromTo}
      nomenclature={nomenclature}
      setNomenclature={setNomenclature}
      nomenclatureInput={nomenclatureInput}
      setNomenclatureInput={setNomenclatureInput}
      nomenclatureOptions={nomenclatureOptions}
      setNomenclatureOptions={setNomenclatureOptions}
      // stopRenderChecksList={stopRenderChecksList}
      // setStopRenderChecksList={setStopRenderChecksList}

      nomenclatureItemAll={nomenclatureItemAll}
    />;
  };

  return (
    <div 
      style={{
        display: 'flex',
        flexDirection: 'column',
        // height: document.body.scrollHeight - 125 + 'px',
        overflowY: 'hidden',
      }}>
      <div
        style={{
          position: 'fixed',
          backgroundColor: '#fffd',
          zIndex: 256 }}
      >
        <div style={stylesObj.simpleRowWrap}>
          <LocalizationProvider
            dateAdapter={AdapterDateFns}
            adapterLocale={ruLocale}
          >
            <DatePicker
              label='Дата'
              value={date}
              onChange={(newValue) => {
                handleChangeDate(newValue);
              } }
              renderInput={(params) => (
                <TextField
                  style={stylesObj.selectFilter}
                  {...params}
                  error={isDateError()}
                  helperText={dateError}
                  onFocus={(event) => {
                    event.target.select();
                  } }
                  autoFocus 
                />
              )} 
            />
          </LocalizationProvider>


          {window.outerWidth > MOBILE_SCREEN_WIDTH 
            ?
            checksFiltersComponent()
         
            :
            <Accordion
              // onChange={handleFiltersExpanded}
              className={styles.accordion}
            >

              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >

                {/* {showDateIfFiltersExpanded()} */}
            Фильтры
              </AccordionSummary>

              <AccordionDetails>

                <div
                  // style={stylesObj.simpleRowWrap}
                  style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    flexDirection: 'column',
                  }}
                >
                  {checksFiltersComponent()}
                </div>
              </AccordionDetails>
            </Accordion>
          }
        </div>
        <ChecksHeader
          allChecks={allChecks}
          sortOrderTypes={sortOrderTypes}
          sortOrder={sortOrder}
          setSortOrder={setSortOrder}
          sortButtonsEnabled={sortButtonsEnabled}
          setSortButtonsEnabled={setSortButtonsEnabled}
          showZeroChecks={showZeroChecks}
          setShowZeroChecks={setShowZeroChecks}
          headTotalSum={headTotalSum}
          headTotalCount={headTotalCount} />
      </div>
      <div 
        style={{
          overflowY: 'auto',
          marginTop: 
            window.outerWidth > MOBILE_SCREEN_WIDTH 
              ? (window.outerWidth < 570 
                ? '400px'
                : (window.outerWidth < 650 
                  ? '370px' 
                  : (window.outerWidth < 794
                    ? '320px' 
                    : (window.outerWidth < 1450
                      ? '270px' 
                      : '200px'
                    )
                  )
                )
              ) 
              : '270px' 
        }}>
        <ChecksList
          allChecks={allChecks}
          isStartWaiting={isStartWaiting}
          setHeadTotalSum={setHeadTotalSum}
          setHeadTotalCount={setHeadTotalCount}
          showZeroChecks={showZeroChecks}
          point={point}
          kkt={kkt}
          category={category}
          timeFromTo={timeFromTo}
          nomenclature={nomenclature}

          onSetIsScroll={onSetIsScroll} />
      </div>
    </div>
  );
};

export { Checks };
