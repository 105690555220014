const validateTextInput = (value, label) => {
  let textError = '';
  let isSetState = true;
  if (value.length < 4){
    textError = `поле "${label}" должно быть БОЛЕЕ трёх букв в длину`;
  }else{
    textError = '';
  }
  if (value.length > 255){
    textError = `поле "${label}" должно быть МЕНЕЕ 256 букв в длину`;
    isSetState = false;
  }
  return [textError, isSetState];
};

export default validateTextInput;