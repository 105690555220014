import * as React from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import { stylesObj } from '../../../stylesObj/stylesObj';
import { Context } from '../../../functions/context';




const Row = (props) => {
  const context = React.useContext(Context);

  const handleChangeQuantity = (e) => {
    if(typeof props.onChangeQuantity === 'function'){
      props.onChangeQuantity( props.item.id, e.target.value);
    };
  };

  return (                    
    <TableRow sx={{backgroundColor: props.backgroundColor}}>
      <TableCell component="th" scope="row">
        {props.item.name}
      </TableCell>
      <TableCell sx={stylesObj.TableCellMinPadding} align="right">
        <TextField
          id="outlined-number"
          value={props.item.quantity}
          type="number"
          onChange={handleChangeQuantity}
          InputLabelProps={{
            shrink: true,
          }}
          size="small"
          sx={{width: '80px', backgroundColor: '#A8E9B155'}}
        />
      </TableCell>
    </TableRow>
  );
};

export default Row;