import React, {useState, useContext} from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Box from '@mui/material/Box';
import generatePaletteColor from '../../functions/generatePaletteColor';
import { IconButton } from '@mui/material';
import StarRateIcon from '@mui/icons-material/StarRate';
import LoopIcon from '@mui/icons-material/Loop';

export default function ColorPicker(props) {
  const [paletteColor, setPaletteColor] = useState(generatePaletteColor(props.quantity ? props.quantity : 4, props.selected));

  const handleSelectColor = (color) => {
    if (typeof props.onSelectColor === 'function'){
      props.onSelectColor(color);
    }
  };

  const handlePressOtherColors = () => {
    setPaletteColor(generatePaletteColor(props.quantity ? props.quantity : 4, props.selected));
  };

  return(
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& > *': {
          m: 1,
        },
      }}
    >
      <ButtonGroup variant="contained " aria-label="contained  button group">
        {paletteColor.map((item, index)=>
          <Button
            key={index}
            sx={{
              backgroundColor: item,
              height: 40
            }}
            onClick={()=>{
              handleSelectColor(item);
            }}
            //variant="contained"
          >{props.selected === item ? <StarRateIcon style={{color: '#fff'}}/> : null}</Button>
        )}
        <Button
          key={'refresh'}
          sx={{
            height: 40
          }}
          variant="contained"
          onClick={handlePressOtherColors}
        >{props.textButton ? 
            props.textButton :
            <LoopIcon color='#fff'/> }</Button>
      </ButtonGroup>
    </Box>
  );
}